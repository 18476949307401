import { useEffect } from "react";

export function useIsPageLoaded() {
  window.reportingPageLoaded = false;
  const setIsPageLoaded = (isLoaded: boolean) => {
    window.reportingPageLoaded = isLoaded;
  };

  useEffect(() => {
    const handlePageLoad = () => {
      setIsPageLoaded(true);
    };

    window.addEventListener("load", handlePageLoad);

    return () => {
      window.removeEventListener("load", handlePageLoad);
    };
  }, []);

  return { isPageLoaded: window.reportingPageLoaded };
}
