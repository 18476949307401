import { Fragment } from "react/jsx-runtime";
import { SectionHeading } from "../../components";
import { ScoreRating } from "~/operations";
import { Grid2, TableRow } from "@mui/material";
import {
  ImpactCell,
  TitleCell,
  RiskFactorsCell,
  PublishedCell,
} from "~/components/Cells";
import { GenericTable } from "~/components/GenericTable/GenericTable";
import { NoItemsSection } from "../components";
import { useAssetReport } from "./hooks/useAssetReport";

export function Vulnerabilities() {
  const { vulnerabilities } = useAssetReport();
  const hasVulnerabilities = Boolean(vulnerabilities?.length);

  return (
    <Fragment>
      <SectionHeading heading="Vulnerabilities" disableUpperPadding />
      <Grid2 size={12} sx={{ my: 3 }}>
        {!hasVulnerabilities ? (
          <NoItemsSection target="vulnerabilities" />
        ) : (
          <GenericTable
            space={undefined}
            items={0}
            tableName="ASSET_REPORT_VULNERABILITIES"
            validSortFields={[]}
            printView
          >
            {vulnerabilities?.map((item) => {
              const { node } = item;

              return (
                <TableRow key={node?.id}>
                  {/* <RankCell id={node.rank} /> CHECKSCORES API DOES NOT HAVE RANK */}
                  <ImpactCell
                    impact={node?.riskScore || 0}
                    rating={node?.rating || ScoreRating.Critical}
                    isActive
                  />
                  <TitleCell
                    title={node?.id || ""}
                    tags={node?.tags}
                    options={{ fontSize: 14 }}
                  />
                  <RiskFactorsCell riskFactors={node?.riskFactors} />
                  <PublishedCell date={node?.updatedAt || "Never"} />
                </TableRow>
              );
            })}
          </GenericTable>
        )}
      </Grid2>
    </Fragment>
  );
}
