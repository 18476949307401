import React from "react";
import { Box, Paper, Typography } from "@mui/material";

type EmptyStateProps = {
  icon: React.ReactNode;
  headline: string;
  textContent?: React.ReactNode;
  actionButtons?: React.ReactNode;
  contained?: boolean;
};

// TODO:: Remove this component after refactoring empty states
// https://github.com/mondoohq/console/issues/4070
export const EmptyState = ({
  icon,
  headline,
  textContent,
  actionButtons,
  contained = true,
}: EmptyStateProps) => {
  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: 5,
        bgcolor: contained ? undefined : "transparent",
        boxShadow: contained ? undefined : "none",
      }}
    >
      {icon}
      <Typography
        variant="h5"
        fontWeight={700}
        sx={{ mt: 2, mb: 1, textAlign: "center", textTransform: "uppercase" }}
      >
        {headline}
      </Typography>
      {textContent && (
        <Typography sx={{ textAlign: "center", color: "text.secondary" }}>
          {textContent}
        </Typography>
      )}
      {actionButtons && (
        <Box textAlign="center" pt={3}>
          {actionButtons}
        </Box>
      )}
    </Paper>
  );
};
