import { ScoreRating } from "~/operations/types";

// This is a workaround until we get the correct data coming from the API
export const getRatingText = (rating: number): ScoreRating => {
  switch (rating) {
    case 1:
      return ScoreRating.None;
    case 2:
      return ScoreRating.Low;
    case 3:
      return ScoreRating.Medium;
    case 4:
      return ScoreRating.High;
    case 5:
      return ScoreRating.Critical;
    default:
      return ScoreRating.Critical;
  }
};
