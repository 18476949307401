import { Box, Button, CircularProgress } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { SpaceScope, WorkspaceScope } from "~/hooks/useScope";
import { EmptyState } from "~/pages/inventory/components/EmptyState";
import buildingWorkspaceDark from "~/images/building-workspace-dark.svg";
import buildingWorkspaceLight from "~/images/building-workspace-light.svg";
import emptyWorkspaceAssets from "~/images/empty-workspace-assets.svg";
import { useColorMode } from "~/providers/color-mode";
import { useGetAggregateScoresRefreshInfoQuery } from "~/operations";
import { useEffect, useState } from "react";
import { Loading } from "~/components/loading";

export type WorkspaceEmptyProps = {
  space: SpaceScope;
  scope: WorkspaceScope;
  onRefreshComplete?: () => void;
  pollingInterval?: number;
};

export function WorkspaceEmpty({
  space,
  scope,
  onRefreshComplete,
  pollingInterval = 10000,
}: WorkspaceEmptyProps) {
  const [isPolling, setIsPolling] = useState(false);

  const { data, loading, startPolling, stopPolling } =
    useGetAggregateScoresRefreshInfoQuery({
      variables: {
        // TODO: Should `Query.aggregateScoresRefreshInfo` accept workspace mrns too?
        // Currently only space mrns are accepted.
        groupMrn: space.mrn,
      },
      fetchPolicy: "network-only",
    });

  const refreshInProgress = Boolean(
    data?.aggregateScoresRefreshInfo.refreshInProgress,
  );

  useEffect(() => {
    if (loading) return;
    if (refreshInProgress) {
      startPolling(pollingInterval);
      setIsPolling(true);
    } else if (isPolling) {
      stopPolling();
      setIsPolling(false);
      onRefreshComplete?.();
    }
  }, [refreshInProgress, loading, isPolling]);

  const buildingSrc =
    useColorMode().mode === "dark"
      ? buildingWorkspaceDark
      : buildingWorkspaceLight;

  const buildingState = (
    <EmptyState
      icon={<Box component="img" src={buildingSrc} />}
      headline="Building workspace"
      textContent="Please wait while Mondoo builds this workspace. This page will refresh when the workspace is complete."
      contained={false}
      actionButtons={<CircularProgress />}
    />
  );

  const emptyState = (
    <EmptyState
      icon={<Box component="img" src={emptyWorkspaceAssets} />}
      headline="This workspace currently has 0 assets"
      textContent="If that's not what you expected, you can change your query in the workspace settings."
      actionButtons={
        <Button
          component={RouterLink}
          to={`/space/settings?${scope.params}`}
          variant="contained"
          color="primary"
        >
          View Workspace Settings
        </Button>
      }
      contained={false}
    />
  );

  const loadingState = <Loading />;

  return loading
    ? loadingState
    : refreshInProgress
      ? buildingState
      : emptyState;
}
