import { SxProps } from "@mui/material";
import { sort } from "d3";

export type GenericTableHeader = {
  /**
   * IDs are used to identify the column in the table
   */
  id: string;
  /**
   * Field is used to identify the column for filtering and sorting
   */
  field: string;
  /**
   * Label is the text that will be displayed in the table header
   */
  label: React.ReactNode;
  /**
   * ColSpan is used to merge columns
   * @default 1
   */
  colSpan?: number;
  /**
   * Sortable is used to determine if the column is sortable
   */
  sortable?: boolean;
  /**
   * Options is used to set the text alignment and width of the column
   */
  // TODO: roll all options into SXProps
  options?: {
    textAlign?: "inherit" | "left" | "center" | "right" | "justify";
    width?: number;
    colSpan?: number;
    sx?: SxProps;
  };
};

export const getGenericTableHeaders = (
  tableName: string,
): GenericTableHeader[] => {
  const GENERIC_COLUMNS = {
    Available: {
      id: "AVAILABLE",
      field: "AVAILABLE",
      label: "Available",
    },
    BlastRadius: {
      id: "BLAST_RADIUS",
      field: "BLAST_RADIUS",
      label: "Blast radius",
    },
    Checks: {
      id: "CHECKS",
      field: "CHECKS",
      label: "Checks",
    },
    Check: { id: "CHECK", field: "TITLE", label: "Check" },
    CreatedAt: {
      id: "CREATED_AT",
      field: "CREATED_AT",
      label: "Created At",
      sortable: true,
    },
    Description: {
      id: "DESCRIPTION",
      field: "DESCRIPTION",
      label: "Description",
    },
    DetectedAsset: {
      id: "DETECTED_ASSET",
      field: "TITLE",
      label: "Asset",
      sortable: true,
    },
    FirstFound: {
      id: "FIRST_FOUND",
      field: "FIRST_FOUND",
      label: "First Found",
    },
    Impact: {
      id: "IMPACT",
      field: "RISK_SCORE", // Risk score is the appropriate field for impact component for sorting purposes
      label: "Risk",
    },
    Installed: {
      id: "INSTALLED",
      field: "INSTALLED",
      label: "Installed",
    },
    LastUpdated: {
      id: "LAST_UPDATED",
      field: "LAST_UPDATED",
      label: "Last Updated",
      sortable: false,
      options: { width: 180 },
    },
    LastScanned: {
      id: "LAST_SCANNED",
      field: "LAST_SCANNED",
      label: "Last Scanned",
      options: { width: 180 },
    },
    Name: {
      id: "NAME",
      field: "NAME",
      label: "Name",
    },
    Package: {
      id: "PACKAGE",
      field: "PACKAGE",
      label: "Package",
    },
    PolicyName: {
      id: "POLICY_NAME",
      field: "POLICY_NAME",
      label: "Policy Name",
    },
    Published: {
      id: "PUBLISHED",
      field: "PUBLISHED",
      label: "Published",
    },
    Rank: {
      id: "RANK",
      field: "RANK",
      label: "Rank",
      sortable: true,
      options: { width: 50 },
    },
    Risk: {
      id: "RISK",
      field: "RISK",
      label: "Risk",
    },
    RiskFactors: {
      id: "RISK_FACTORS",
      field: "RISK_FACTORS",
      label: "Risk factors",
      sortable: false,
      options: { width: 180 },
    },
    SecurityScore: {
      id: "SECURITY_SCORE",
      field: "SECURITY_SCORE",
      label: "% Security Score",
    },
    Vulnerability: {
      id: "VULNERABILITY",
      field: "VULNERABILITY",
      label: "Vulnerability",
    },
  };

  const DefaultHeaders: GenericTableHeader[] = [
    GENERIC_COLUMNS.Name,
    GENERIC_COLUMNS.CreatedAt,
  ];

  const detectedAssetsColumns: GenericTableHeader[] = [
    GENERIC_COLUMNS.DetectedAsset,
    GENERIC_COLUMNS.LastUpdated,
  ];

  const assetReportSecurityVulnerabilitiesColumns: GenericTableHeader[] = [
    GENERIC_COLUMNS.Vulnerability,
    GENERIC_COLUMNS.Risk,
    GENERIC_COLUMNS.Published,
  ];

  const assetReportSecurityColumns: GenericTableHeader[] = [
    GENERIC_COLUMNS.PolicyName,
    GENERIC_COLUMNS.Risk,
    GENERIC_COLUMNS.SecurityScore,
    GENERIC_COLUMNS.Checks,
  ];

  const assetReportChecksColumns: GenericTableHeader[] = [
    // GENERIC_COLUMNS.Rank,
    GENERIC_COLUMNS.Impact,
    // GENERIC_COLUMNS.BlastRadius,
    GENERIC_COLUMNS.Check,
    GENERIC_COLUMNS.RiskFactors,
    GENERIC_COLUMNS.LastScanned,
  ];

  const assetReportVulnerabilitiesColumns: GenericTableHeader[] = [
    // GENERIC_COLUMNS.Rank,
    GENERIC_COLUMNS.Risk,
    // GENERIC_COLUMNS.BlastRadius,
    GENERIC_COLUMNS.Vulnerability,
    GENERIC_COLUMNS.RiskFactors,
    GENERIC_COLUMNS.FirstFound,
  ];

  const assetReportVulnerablePackagesColumns: GenericTableHeader[] = [
    GENERIC_COLUMNS.Risk,
    GENERIC_COLUMNS.Package,
    GENERIC_COLUMNS.Installed,
    GENERIC_COLUMNS.Available,
    GENERIC_COLUMNS.RiskFactors,
    GENERIC_COLUMNS.FirstFound,
  ];

  switch (tableName) {
    case "DETECTED_ASSETS":
      return detectedAssetsColumns;
    case "ASSET_REPORT_SECURITY":
      return assetReportSecurityColumns;
    case "ASSET_REPORT_SECURITY_VULNERABILITIES":
      return assetReportSecurityVulnerabilitiesColumns;
    case "ASSET_REPORT_CHECKS":
      return assetReportChecksColumns;
    case "ASSET_REPORT_VULNERABILITIES":
      return assetReportVulnerabilitiesColumns;
    case "ASSET_REPORT_VULNERABLE_PACKAGES":
      return assetReportVulnerablePackagesColumns;
    default:
      return DefaultHeaders;
  }
};
