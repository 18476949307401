import { ScoreRating } from "~/operations/types";
import { SharedPalette } from "~/types/material-ui";

type ScoreRatingColorKey = keyof SharedPalette;
type ScoreRatingColorMap = Record<ScoreRating, ScoreRatingColorKey>;

export const ScoreRatingColorMap: ScoreRatingColorMap = {
  [ScoreRating.Critical]: "critical",
  [ScoreRating.High]: "high",
  [ScoreRating.Medium]: "medium",
  [ScoreRating.Low]: "low",
  [ScoreRating.None]: "none",
};
