import React, { useState } from "react";
import { Grid, Grid2 } from "@mui/material";
import { SectionHeading } from "~/components/DetailsPage/components";
import { EmptySection } from "~/components/vulnerabilities";
import {
  AggregateScoreOrder,
  AggregateScoreOrderField,
  AggregateScoreType,
  OrderDirection,
  useGetAggregateScoresQuery,
} from "~/operations";
import { Space } from "~/lib/types";
import { FirewatchList } from "~/components/FirewatchList";
import { INITIAL_PAGE_RANGE } from "~/components/pagination";
import { useSort } from "~/pages/inventory/hooks/useSort";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";

type SoftwareVulnerabilitiesProps = {
  space: Space;
  scope: SpaceOrWorkspaceScope;
  findingMrn: string;
};

export const SoftwareVulnerabilities = ({
  space,
  scope,
  findingMrn,
}: SoftwareVulnerabilitiesProps) => {
  const [pageItems, setPageItems] = useState(INITIAL_PAGE_RANGE);
  const { handleSortClick, orderBy } = useSort<AggregateScoreOrder>({
    defaultSort: {
      field: AggregateScoreOrderField.Rank,
      direction: OrderDirection.Asc,
    },
    validFields: ["RISK_SCORE", "RANK", "BLAST_RADIUS", "TITLE"],
    prefix: "vulnerability",
  });

  const { data, loading, fetchMore } = useGetAggregateScoresQuery({
    variables: {
      entityMrn: scope.mrn,
      first: 3,
      after: null,
      orderBy,
      filter: {
        scoreType: AggregateScoreType.Vulnerability,
        softwareFindingMrn: findingMrn,
      },
    },
  });

  const vulnerabilities =
    data?.aggregateScores?.__typename === "AggregateScoresConnection"
      ? data.aggregateScores
      : undefined;

  const edges = vulnerabilities?.edges;

  return (
    <Grid2 id="version-chart" size={{ xs: 12 }}>
      <SectionHeading heading="Vulnerabilities" />
      {(edges?.length || 0) > 0 ? (
        <FirewatchList
          field={orderBy.field}
          direction={orderBy.direction}
          space={space}
          scope={scope}
          pageType={AggregateScoreType.Vulnerability}
          data={edges?.slice(pageItems.from, pageItems.to)}
          loading={loading}
          handleSortClick={handleSortClick}
          paginationProps={{
            fetchMore,
            pageInfo: vulnerabilities?.pageInfo,
            setPageItems,
            totalCount: vulnerabilities?.totalCount || 0,
            customPageSizes: [3, 10, 25, 50],
          }}
        />
      ) : (
        <EmptySection
          id="software-vulnerabilities-empty"
          text="There are currently no known vulnerabilities for this software"
        />
      )}
    </Grid2>
  );
};
