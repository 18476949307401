import { ClientIntegrationType } from "~/operations";

export const mapIntegrationRouteToClientIntegrationType = (
  integrationTypeId: string | undefined,
): ClientIntegrationType[] => {
  if (!integrationTypeId) return [ClientIntegrationType.Unknown];

  switch (integrationTypeId) {
    case "amazons3":
      return [ClientIntegrationType.AwsS3];
    case "amazon-s3-compatible":
      return [ClientIntegrationType.S3];
    case "aws":
      return [ClientIntegrationType.Aws, ClientIntegrationType.AwsHosted];
    case "azure":
      return [ClientIntegrationType.Azure];
    case "azure_blob":
      return [ClientIntegrationType.AzureBlob];
    case "bigquery":
      return [ClientIntegrationType.Bigquery];
    case "crowdstrike":
      return [ClientIntegrationType.CrowdstrikeFalcon];
    case "defender":
      return [ClientIntegrationType.MicrosoftDefender];
    case "gcp":
      return [ClientIntegrationType.Gcp];
    case "gcs_bucket":
      return [ClientIntegrationType.GcsBucket];
    case "github":
      return [ClientIntegrationType.Github];
    case "gitlab":
      return [ClientIntegrationType.Gitlab];
    case "google_workspace":
      return [ClientIntegrationType.GoogleWorkspace];
    case "host":
      return [ClientIntegrationType.Host];
    case "hosted_slack":
      return [ClientIntegrationType.HostedSlack];
    case "kubernetes":
      return [ClientIntegrationType.K8S];
    case "managed":
      return [ClientIntegrationType.ManagedClient];
    case "ms365":
      return [ClientIntegrationType.Ms365];
    case "oci":
      return [ClientIntegrationType.Oci];
    case "okta":
      return [ClientIntegrationType.Okta];
    case "postgres":
      return [ClientIntegrationType.Postgres];
    case "sentinelone":
      return [ClientIntegrationType.SentinelOne];
    case "snowflake":
      return [ClientIntegrationType.Snowflake];
    case "shodan":
      return [ClientIntegrationType.Shodan];
    case "ticket_system_jira":
      return [ClientIntegrationType.TicketSystemJira];
    case "ticket_system_email":
      return [ClientIntegrationType.TicketSystemEmail];
    case "ticket_system_zendesk":
      return [ClientIntegrationType.TicketSystemZendesk];
    case "ticket_system_github":
      return [ClientIntegrationType.TicketSystemGithub];
    case "ticket_system_gitlab":
      return [ClientIntegrationType.TicketSystemGitlab];
    case "ticket_system_azure_devops":
      return [ClientIntegrationType.TicketSystemAzureDevops];
    default:
      return [ClientIntegrationType.Unknown];
  }
};
