import { Fragment, useLayoutEffect, useRef } from "react";
import { Box, Typography, Divider, Grid, GridProps } from "@mui/material";
import { useAppNavDrawer } from "~/providers/app-nav-drawer";
import {
  AvailableIntegration,
  AvailableIntegrationCard,
  RecommendedAvailableIntegrationCard,
} from "~/components/integrations/cards/available-integration-cards";
import { Space } from "~/lib/types";
import {
  DnsOutlinedIcon,
  CloudOutlinedIcon,
  ChatOutlinedIcon,
  SchemaOutlinedIcon,
  ExportsIcon,
  WifiGradientIcon,
  ThirdPartyDataIcon,
  TicketIcon,
} from "../icons";
import { CicdIcon } from "../icons/mondoo/cicd";
import { SaasIcon } from "../icons/mondoo/saas";
// temporary icons
import { getTemporaryIcon } from "~/images/icons/temporary-icons";

export type IntegrationCardsListProps = {
  space: Space;
  integrations: AvailableIntegration[];
  title?: string;
  recommended?: boolean;
};

export const IntegrationCardsList = ({
  space,
  title,
  integrations,
  recommended = false,
}: IntegrationCardsListProps) => {
  return (
    <Fragment>
      {title && (
        <IntegrationDivider
          {...{ title, className: "integration-cards-list-divider" }}
        />
      )}
      <Grid container item spacing={3} className="integration-cards-list">
        {integrations.map((integration, i) => {
          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={i}
              className="integration-cards-list-item"
            >
              {recommended ? (
                <RecommendedAvailableIntegrationCard
                  {...{ space, integration }}
                />
              ) : (
                <AvailableIntegrationCard {...{ space, integration }} />
              )}
            </Grid>
          );
        })}
      </Grid>
    </Fragment>
  );
};

//if the IntegrationCardsList is provided a title, it will automatically
//produce a divider line

export type IntegrationDividerProps = {
  title: string;
} & GridProps;

export const IntegrationDivider = ({
  title,
  ...gridProps
}: IntegrationDividerProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const icon = getDividerIcon(title);

  const { navbarHeight } = useAppNavDrawer();

  const stripTitle = (title: string) => {
    const safe = title.replace(/[^\w]/gi, "").toLowerCase();
    return safe;
  };

  useLayoutEffect(() => {
    if (location.hash) {
      const formattedHash = location.hash.replace(/^#/, "");
      if (formattedHash === stripTitle(title)) {
        if (ref.current) {
          const y = ref.current.offsetTop - 20 - navbarHeight;
          window.scrollTo({ top: y, behavior: "smooth" });
        }
      }
    }
  }, []);

  return (
    <Grid container alignItems="center" mt={5} mb={3} {...gridProps}>
      <Grid item sx={{ display: "flex", alignItems: "center" }}>
        {icon && <Box sx={{ mr: 2 }}>{icon}</Box>}
        <Box ref={ref} id={stripTitle(title)}>
          <Typography
            className="integration-cards-list-title"
            fontWeight="fontWeightBold"
            sx={{
              display: "inline-block",
              pr: 3,
              fontSize: { xs: 20, sm: 24 },
            }}
          >
            {title}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs>
        <Divider />
      </Grid>
    </Grid>
  );
};

const getDividerIcon = (title: string) => {
  switch (title.toLowerCase()) {
    case "security engineering":
      return <Box component="img" src={getTemporaryIcon(title)} />;
    case "server & endpoint security":
      return <DnsOutlinedIcon color="primary" sx={{ fontSize: 56 }} />;
    case "saas":
      return <SaasIcon sx={{ fontSize: 56 }} />;
    case "network security":
      return <WifiGradientIcon sx={{ fontSize: 56 }} />;
    case "cloud security":
      return <CloudOutlinedIcon color="primary" sx={{ fontSize: 56 }} />;
    case "supply chain":
      return <SchemaOutlinedIcon color="primary" sx={{ fontSize: 56 }} />;
    case "ticket systems":
      return <TicketIcon color="primary" sx={{ fontSize: 56 }} />;
    case "third-party data":
      return <ThirdPartyDataIcon color="primary" sx={{ fontSize: 56 }} />;
    case "chat ops":
      return <ChatOutlinedIcon color="primary" sx={{ fontSize: 56 }} />;
    case "ci/cd":
      return <CicdIcon sx={{ fontSize: 56 }} />;
    case "exports":
      return <ExportsIcon sx={{ fontSize: 56 }} />;
    default:
      return null;
  }
};
