import { ReactElement, ReactNode } from "react";
import { Tooltip } from "@mui/material";

export const TooltipWrapper = ({
  children,
  hideTooltip = false,
  title,
}: {
  children: ReactElement;
  hideTooltip?: boolean;
  title: ReactNode;
}) =>
  hideTooltip ? (
    children
  ) : (
    <Tooltip arrow placement="top" title={title}>
      {children}
    </Tooltip>
  );
