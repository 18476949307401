import { Grid, TableCell, TableRow, Typography } from "@mui/material";
import { Flex } from "~/components/Flex";
import { HalfStyleDataTable } from "./half-data-table";
import { HostedClientIntegration } from "../types";
import { useConfigurationsAndMessages } from "../hooks/useConfigurationsAndMessages";
import { HalfDataTableRow } from "./half-data-table-row";
import { FormatDateShort } from "~/lib/date";
import { capitalizeFirstLetter } from "~/lib/helpers";

export type ConfigurationsAndMessagesProps = {
  integration: HostedClientIntegration;
  token?: string;
  actionType: "scan" | "export" | "import";
};

export function ConfigurationsAndMessages({
  integration,
  token,
  actionType,
}: ConfigurationsAndMessagesProps) {
  const { displayedConfigs, configFormat } = useConfigurationsAndMessages({
    integration,
    token,
    actionType,
  });

  return (
    <Grid
      container
      columnSpacing={{ xs: 0, sm: 3 }}
      rowSpacing={{ xs: 5, sm: 0 }}
    >
      <Grid item xs={12} sm={6}>
        <HalfStyleDataTable title="Integration Configuration">
          <HalfDataTableRow
            title="Created"
            value={FormatDateShort(integration.created)}
          />

          <HalfDataTableRow
            title="Last modified"
            value={
              integration?.lastModified
                ? FormatDateShort(integration?.lastModified)
                : "Never"
            }
          />

          {displayedConfigs &&
            Object.entries(displayedConfigs).map(([key, value]) => (
              <HalfDataTableRow
                key={key}
                title={configFormat.configName(key)}
                value={
                  (configFormat.group.includes(key)
                    ? configFormat.value(key, value)
                    : value) || <>&nbsp;</>
                }
              />
            ))}
        </HalfStyleDataTable>
        <HalfStyleDataTable
          title={`${actionType} Details`}
          ContainerSxProps={{ mt: 3 }}
        >
          <HalfDataTableRow
            title={`Last Successful ${capitalizeFirstLetter(actionType)}`}
            value={configFormat.scanStartAndFinish(
              integration?.scanJobStarted,
              integration?.scanJobFinished,
            )}
          />
          <HalfDataTableRow
            title={`Last Attempted ${capitalizeFirstLetter(actionType)}`}
            value={
              integration?.scanJobAttempted
                ? FormatDateShort(integration.scanJobAttempted)
                : "Never"
            }
          />
        </HalfStyleDataTable>
      </Grid>

      <Grid item xs={12} sm={6}>
        <HalfStyleDataTable title="Most Recent Activity">
          {integration.messages && integration.messages.length > 0 ? (
            integration.messages.map((message, i) => (
              <HalfDataTableRow
                key={i}
                title={
                  message?.timestamp
                    ? FormatDateShort(message.timestamp)
                    : "Never"
                }
                value={message.message}
              />
            ))
          ) : (
            <TableRow>
              <TableCell align="center">
                <Flex
                  sx={{
                    height: (theme) => theme.spacing(10),
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography color="text.secondary">
                    You have no recent activity.
                  </Typography>
                </Flex>
              </TableCell>
            </TableRow>
          )}
        </HalfStyleDataTable>
      </Grid>
    </Grid>
  );
}
