import { Fragment, useState } from "react";
import { Typography, Grid, Box } from "@mui/material";
import { resolvePath, useLocation } from "react-router-dom";
import {
  ConfigurationPaper,
  ConfigurationList,
  ConfigurationItemSwitch,
} from "~/components/configuration-items";
import { LoadingFailedPage, LoadingPage } from "~/components/loading";
import { TabPanel } from "~/components/tab-panel";
import { UserNameSection } from "~/components/username-section";
import { AccountManagement } from "~/components/account-management";
import {
  TabListItem,
  TabNavigation,
  useRouteMatch,
} from "~/components/tab-nav";
import {
  EmailPreferenceList,
  useLoadEmailPreferencesQuery,
  useSetEmailPreferenceMutation,
} from "~/operations";

export type UserSettingsProps = {};

type UserSettingsState = {
  loaded: boolean;
  newsletterGeneral: boolean;
  newsletterProduct: boolean;
  newsletterEvents: boolean;
  notificationWeeklyReports: boolean;
};
type ChangedUserSettingsState = Partial<UserSettingsState>;

export function UserSettings({}: UserSettingsProps) {
  const location = useLocation();
  const [state, setState] = useState<UserSettingsState>({
    loaded: false,
    newsletterGeneral: false,
    newsletterProduct: false,
    newsletterEvents: false,
    notificationWeeklyReports: false,
  });
  const { loading, data, refetch, error } = useLoadEmailPreferencesQuery({
    notifyOnNetworkStatusChange: false,
  });

  const [setEmailPreference] = useSetEmailPreferenceMutation();

  if (loading) {
    return <LoadingPage what="user settings" />;
  }

  if (error || !data) {
    return <LoadingFailedPage what="user settings" />;
  }

  if (!state.loaded) {
    const preferences = data.emailPreferences;
    let newState: UserSettingsState = {
      loaded: true,
      newsletterGeneral: false,
      newsletterProduct: false,
      newsletterEvents: false,
      notificationWeeklyReports: false,
    };

    for (var preference of preferences) {
      switch (preference?.listID) {
        case EmailPreferenceList.NotificationWeeklyReports:
          newState.notificationWeeklyReports = preference.subscribed;
          break;
        case EmailPreferenceList.NewsletterGeneral:
          newState.newsletterGeneral = preference.subscribed;
          break;
        case EmailPreferenceList.NewsletterProduct:
          newState.newsletterProduct = preference.subscribed;
          break;
        case EmailPreferenceList.NewsletterEvents:
          newState.newsletterEvents = preference.subscribed;
          break;
      }
    }
    setState((prev) => ({ ...prev, ...newState }));
  }

  const href = (tab: string) => {
    return `/settings/${tab}`;
  };

  const updateEmailSubscription = (
    listID: EmailPreferenceList,
    subscribe: boolean,
  ) => {
    setEmailPreference({ variables: { input: [{ listID, subscribe }] } }).then(
      () => {
        let newState: ChangedUserSettingsState = {};
        switch (listID) {
          case EmailPreferenceList.NotificationWeeklyReports:
            newState.notificationWeeklyReports = subscribe;
            break;
          case EmailPreferenceList.NewsletterGeneral:
            newState.newsletterGeneral = subscribe;
            break;
          case EmailPreferenceList.NewsletterEvents:
            newState.newsletterEvents = subscribe;
            break;
          case EmailPreferenceList.NewsletterProduct:
            newState.newsletterProduct = subscribe;
            break;
        }
        setState((prev) => ({ ...prev, ...newState }));
        refetch();
      },
    );
  };

  const actionText = (isEnabled: boolean) => (isEnabled ? "On" : "Off");

  const tabList: TabListItem[] = [
    { label: "General settings", to: href("general"), route: "/general" },
    { label: "Security", to: href("security"), route: "/security" },
    { label: "Email preferences", to: href("email"), route: "/email" },
  ];

  const isSelected = (item: TabListItem) =>
    location.pathname.startsWith(resolvePath(item.to).pathname);
  const selectedTab = tabList.find((item) => isSelected(item)) || tabList.at(0);
  const selectedIndex = selectedTab ? tabList.indexOf(selectedTab) : 0;
  const currentTab = useRouteMatch(
    tabList.map((x) => x.route),
    "general",
  );

  document.title = `Settings · Mondoo`;

  const secondaryNewsletterMessage = (isOn: boolean, nType: string) => {
    return (
      <Fragment>
        {nType === "monthly" && (
          <Typography
            component="span"
            variant="body2"
            color="text.primary"
            sx={{ display: "block" }}
          >
            Regular update about what's new in Mondoo
          </Typography>
        )}
        {nType === "product" && (
          <Typography
            component="span"
            variant="body2"
            color="text.primary"
            sx={{ display: "block" }}
          >
            Everything you need to know about Mondoo features and improvements
          </Typography>
        )}
        {nType === "events" && (
          <Typography
            component="span"
            variant="body2"
            color="text.primary"
            sx={{ display: "block" }}
          >
            Webinars, conferences, and more
          </Typography>
        )}
        {isOn && (
          <Typography
            component="span"
            variant="caption"
            sx={{
              display: "inline-block",
              color: (theme) => theme.palette.warning.main,
            }}
          >
            Note: If you unsubscribe, you must contact us if you want to
            re-subscribe.{" "}
          </Typography>
        )}
      </Fragment>
    );
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {/* Heading */}
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 4 }}>
          <Typography variant="h4" component="h2" fontWeight={700}>
            User Settings
          </Typography>
        </Box>
        <TabNavigation {...{ id: "org-settings-tabs", currentTab, tabList }} />
        <TabPanel value={selectedIndex} index={0}>
          <UserNameSection />
        </TabPanel>
        <TabPanel value={selectedIndex} index={1}>
          <AccountManagement />
        </TabPanel>
        <TabPanel value={selectedIndex} index={2}>
          <ConfigurationPaper>
            <ConfigurationList title="Newsletter Subscriptions">
              <ConfigurationItemSwitch
                primary="Mondoo Newsletter"
                secondary={secondaryNewsletterMessage(
                  state.newsletterGeneral,
                  "monthly",
                )}
                action={actionText(state.newsletterGeneral)}
                checked={state.newsletterGeneral}
                onChange={() => {
                  updateEmailSubscription(
                    EmailPreferenceList.NewsletterGeneral,
                    !state.newsletterGeneral,
                  );
                }}
              ></ConfigurationItemSwitch>
              <ConfigurationItemSwitch
                primary="Product &amp; Feature Updates"
                secondary={secondaryNewsletterMessage(
                  state.newsletterProduct,
                  "product",
                )}
                action={actionText(state.newsletterProduct)}
                checked={state.newsletterProduct}
                onChange={() => {
                  updateEmailSubscription(
                    EmailPreferenceList.NewsletterProduct,
                    !state.newsletterProduct,
                  );
                }}
              ></ConfigurationItemSwitch>
              <ConfigurationItemSwitch
                primary="Events"
                secondary={secondaryNewsletterMessage(
                  state.newsletterEvents,
                  "events",
                )}
                action={actionText(state.newsletterEvents)}
                checked={state.newsletterEvents}
                onChange={() => {
                  updateEmailSubscription(
                    EmailPreferenceList.NewsletterEvents,
                    !state.newsletterEvents,
                  );
                }}
              ></ConfigurationItemSwitch>
            </ConfigurationList>
          </ConfigurationPaper>

          <ConfigurationPaper>
            <ConfigurationList title="Email Notifications">
              <ConfigurationItemSwitch
                primary="Send me weekly reports"
                secondary="Each week we email you a risk assessment report on all the organizations for which you have owner privileges."
                action={actionText(state.notificationWeeklyReports)}
                checked={state.notificationWeeklyReports}
                onChange={() => {
                  updateEmailSubscription(
                    EmailPreferenceList.NotificationWeeklyReports,
                    !state.notificationWeeklyReports,
                  );
                }}
              ></ConfigurationItemSwitch>
            </ConfigurationList>
          </ConfigurationPaper>
        </TabPanel>
      </Grid>
    </Grid>
  );
}
