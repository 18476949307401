import { gql } from "@apollo/client";

export const UPDATE_CLIENT_INTEGRATION_CONFIGURATION = gql`
  mutation UpdateClientIntegrationConfiguration(
    $input: UpdateClientIntegrationConfigurationInput!
  ) {
    updateClientIntegrationConfiguration(input: $input) {
      configurationOptions {
        ... on K8sConfigurationOptions {
          scanNodes
          scanNodesStyle
          scanWorkloads
          scanDeploys
          certificateManager
          scanPublicImages
          namespaceAllowList
          namespaceDenyList
        }
        ... on AWSConfigurationOptions {
          region
          isOrganization
          snsEndpoint
          scanConfiguration {
            accountScan
            ec2Scan
            ecrScan
            ecsScan
            cronScaninHours
            eventScanTriggers {
              eventDetailType
              eventSource
              scanType
            }
            ec2ScanOptions {
              ssm
              instanceConnect
              instanceIdsFilter
              excludedInstanceIdsFilter
              regionsFilter
              excludedRegionsFilter
              tagsFilter
              excludedTagsFilter
              ebsVolumeScan
            }
            vpcConfiguration {
              CIDR
              useDefaultVPC
              useMondooVPC
            }
          }
        }
        ... on HostedAwsConfigurationOptions {
          accessKeyId
          role
        }
        ... on AzureConfigurationOptions {
          tenantId
          clientId
          subscriptionsWhitelist
          subscriptionsBlacklist
          scanVms
        }
        ... on Ms365ConfigurationOptions {
          tenantId
          clientId
        }
        ... on MicrosoftDefenderConfigurationOptions {
          tenantId
          clientId
          subscriptionsAllowlist
          subscriptionsDenylist
          secretType
        }
        ... on GcpConfigurationOptions {
          projectId
          organizationId
          discoverAll
        }
        ... on GcsBucketConfigurationOptions {
          output
          bucket
        }
        ... on GitlabConfigurationOptions {
          group
          type
          discoverGroups
          discoverProjects
          discoverTerraform
          discoverK8sManifests
          baseUrl
        }
        ... on GithubConfigurationOptions {
          owner
          repository
          githubOrg: organization
          githubType: type
          reposAllowList
          reposDenyList
          githubDiscoverK8sManifests: discoverK8sManifests
          githubDiscoverTerraform: discoverTerraform
          enterpriseUrl
        }
        ... on AzureDevopsConfigurationOptions {
          defaultProjectName
          organizationUrl
          servicePrincipalId
          tenantId
          autoCloseTickets
          autoCreateTickets
        }
        ... on HostConfigurationOptions {
          host
          https
          http
        }
        ... on AgentOptions {
          id
          version
          notes
          platform {
            name
            release
            arch
            kind
            runtime
          }
          hostname
          ipaddress
        }
        ... on MicrosoftDefenderConfigurationOptions {
          tenantId
          clientId
          subscriptionsAllowlist
          subscriptionsDenylist
          secretType
        }
        ... on OktaConfigurationOptions {
          organization
        }
        ... on GoogleWorkspaceConfigurationOptions {
          customerId
          impersonatedUserEmail
        }
        ... on OciConfigurationOptions {
          tenancyOcid
          userOcid
          region
          fingerprint
        }
        ... on ShodanConfigurationOptions {
          token
          targets
        }
        ... on SentinelOneConfigurationOptions {
          host
          account
          secretType
          createAssets
        }
        ... on CrowdstrikeFalconConfigurationOptions {
          clientId
          cloud
          memberCID
          createAssets
        }
      }
    }
  }
`;
