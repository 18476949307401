import * as d3 from "d3";
import { Box, Tooltip, alpha, useTheme } from "@mui/material";
import { useEpssRisk } from "./useEpssRisk";
import { CvssScoreType } from "~/lib/score";

export function EpssChart() {
  const risk = useEpssRisk();
  const theme = useTheme();

  const plotWidth = 280;
  const plotHeight = 184;

  const paddingTop = 0;
  const paddingBottom = 24;
  const paddingLeft = 80;
  const paddingRight = 16;

  const viewboxWidth = plotWidth + paddingLeft + paddingRight;
  const viewboxHeight = plotHeight + paddingTop + paddingBottom;
  const viewBox = `0 0 ${viewboxWidth} ${viewboxHeight}`;

  const yTicks = [0.25, 0.5, 0.75];

  const xScale = d3.scaleLinear([0, plotWidth]);
  const yScale = d3.scaleLinear([plotHeight, 0]);

  const percentileX = 0;
  const percentileY = yScale(risk.metrics.percentile.value) || 0;
  const cvssScoreX = xScale(risk.metrics.cvssScore.value / 100) || 0;
  const cvssScoreY = plotHeight;
  const probabilityX = cvssScoreX;
  const probabilityY = percentileY;

  const cvssColorLabel = CvssScoreType(risk.metrics.cvssScore.value);
  const cvssColor = theme.palette[cvssColorLabel].main;

  const isInteracting = Boolean(risk.focusedMetric);

  const arrowTip = (
    <polygon
      points="0, 6, 3, 0, 6, 6"
      fill={theme.palette.text.secondary}
      fillOpacity={0.8}
    />
  );

  return (
    <Box
      component="svg"
      viewBox={viewBox}
      sx={{
        maxWidth: viewboxWidth,
        margin: "0 auto",
        display: "block",
        overflow: "visible",
      }}
    >
      {/* x-axis status gradient background */}
      <g className="x-axis-background">
        <foreignObject
          x={paddingLeft}
          y={paddingTop}
          width={plotWidth}
          height={plotHeight}
        >
          <Box
            sx={{
              backgroundColor: "background.default",
              backgroundImage: `radial-gradient(${[
                "170.1% 128% at 1.09% 113.04%",
                "rgba(44, 191, 201, 0.16) 5.75%",
                "rgba(3, 156, 216, 0.16) 25.31%",
                "rgba(207, 37, 132, 0.16) 68.84%",
                "rgba(252, 55, 121, 0.16) 100%",
              ].join(", ")})`,
              height: "100%",
              width: "100%",
              borderRadius: "4px 4px 4px 0px",
            }}
          />
        </foreignObject>
      </g>
      {/* y-axis background ticks */}
      <g className="y-axis-background">
        {yTicks.map((t, i) => {
          const width = plotWidth;
          const height = 1;
          const x = 0 + paddingLeft;
          const y = (yScale(t) || 0) + paddingTop;
          const fill = alpha(theme.palette.common.white, (i + 1) * 0.02);
          return <rect width={width} height={height} x={x} y={y} fill={fill} />;
        })}
      </g>
      {/* x-axis label and arrow line */}
      <g
        className="x-axis"
        transform={`translate(${paddingLeft} ${paddingTop})`}
      >
        <rect
          width={plotWidth - 6}
          height={1}
          x={0}
          y={plotHeight - 1}
          fill={"url(#xGradient)"}
        />
        <svg x={plotWidth - 6} y={plotHeight - 3.5} width={6} height={6}>
          <g transform="rotate(90, 3, 3)">{arrowTip}</g>
        </svg>
        <text
          fontSize={8}
          fill={alpha(theme.palette.text.secondary, 0.33)}
          x={plotWidth / 2}
          y={plotHeight - 9}
          textAnchor="middle"
          dominantBaseline="text-top"
        >
          CVSS3 score
        </text>
      </g>
      {/* y-axis label and arrow line */}
      <g
        className="y-axis"
        transform={`translate(${paddingLeft} ${paddingTop})`}
      >
        <rect
          width={1}
          height={plotHeight - 6}
          x={0}
          y={6}
          fill={"url(#yGradient)"}
        />
        <svg x={-2.5} width={6} height={6}>
          <g transform="rotate(0, 3, 3)">{arrowTip}</g>
        </svg>
        <text
          fontSize={8}
          fill={alpha(theme.palette.text.secondary, 0.33)}
          transform="rotate(-90)"
          x={(plotHeight / 2) * -1}
          y={9}
          textAnchor="middle"
          dominantBaseline="hanging"
        >
          EPSS Percentile
        </text>
      </g>
      {/* data plot */}
      <g
        className="data-plot"
        transform={`translate(${paddingLeft} ${paddingTop})`}
      >
        {/* x-axis plot line and label */}
        <g
          onMouseEnter={() => risk.onMetricMouseEnter(risk.metrics.cvssScore)}
          onMouseLeave={() => risk.onMetricMouseLeave(risk.metrics.cvssScore)}
        >
          <line
            className="x-axis-plot-line"
            x1={cvssScoreX}
            x2={cvssScoreX}
            y1={plotHeight - 0.5}
            y2={probabilityY + 2}
            stroke={
              isInteracting
                ? risk.isFocused(risk.metrics.cvssScore) ||
                  risk.isFocused(risk.metrics.probability)
                  ? cvssColor
                  : theme.palette.text.disabled
                : cvssColor
            }
            strokeDasharray="5 3"
          />
          <line
            className="x-axis-plot-line-hover"
            x1={cvssScoreX}
            x2={cvssScoreX}
            y1={plotHeight + paddingBottom}
            y2={probabilityY + 2}
            stroke="transparent"
            strokeWidth={15}
          />
          <Tooltip
            className="x-axis-point-label-tooltip"
            title={risk.metrics.cvssScore.label}
            placement="bottom"
            arrow
            open={risk.isFocused(risk.metrics.cvssScore)}
          >
            <text
              className="x-axis-point-label"
              x={cvssScoreX}
              y={cvssScoreY + 4}
              fill={
                isInteracting
                  ? risk.isFocused(risk.metrics.cvssScore)
                    ? cvssColor
                    : theme.palette.text.disabled
                  : cvssColor
              }
              fontSize={12}
              fontWeight={700}
              dominantBaseline="hanging"
              textAnchor="middle"
            >
              {risk.metrics.cvssScore.formattedValue}
            </text>
          </Tooltip>
        </g>
        {/* y-axis plot line and label */}
        <g
          onMouseEnter={() => risk.onMetricMouseEnter(risk.metrics.percentile)}
          onMouseLeave={() => risk.onMetricMouseLeave(risk.metrics.percentile)}
        >
          <line
            className="y-axis-plot-line"
            x1={percentileX + 0.5}
            x2={cvssScoreX - 2}
            y1={percentileY}
            y2={percentileY}
            stroke={
              isInteracting
                ? risk.isFocused(risk.metrics.percentile) ||
                  risk.isFocused(risk.metrics.probability)
                  ? cvssColor
                  : theme.palette.text.disabled
                : cvssColor
            }
            strokeDasharray="5 3"
          />
          <line
            className="y-axis-plot-line-hover"
            x1={percentileX - paddingLeft}
            x2={cvssScoreX - 2}
            y1={percentileY}
            y2={percentileY}
            stroke="transparent"
            strokeWidth={15}
          />
          <Tooltip
            className="y-axis-point-label-tooltip"
            title={risk.metrics.percentile.label}
            placement="left"
            arrow
            open={risk.isFocused(risk.metrics.percentile)}
          >
            <text
              className="y-axis-point-label"
              x={percentileX - 4}
              y={percentileY}
              fill={
                isInteracting
                  ? risk.isFocused(risk.metrics.percentile)
                    ? cvssColor
                    : theme.palette.text.disabled
                  : cvssColor
              }
              fontSize={12}
              fontWeight={700}
              dominantBaseline="central"
              textAnchor="end"
            >
              {risk.metrics.probability.formattedValue} (
              {risk.metrics.percentile.formattedValue})
            </text>
          </Tooltip>
        </g>
        {/* xy point */}
        <g
          className="xy-point"
          onMouseEnter={() => risk.onMetricMouseEnter(risk.metrics.probability)}
          onMouseLeave={() => risk.onMetricMouseLeave(risk.metrics.probability)}
        >
          <circle
            className="xy-point-dot"
            r={3}
            cx={probabilityX}
            cy={probabilityY}
            stroke={
              isInteracting
                ? risk.isFocused(risk.metrics.probability)
                  ? cvssColor
                  : theme.palette.text.disabled
                : cvssColor
            }
            strokeWidth={2}
            fill="transparent"
          />
          <Tooltip
            className="xy-point-label-tooltip"
            title={risk.metrics.probability.label}
            placement="top"
            arrow
            open={risk.isFocused(risk.metrics.probability)}
          >
            <circle
              className="xy-point-dot-hover"
              r={15}
              cx={probabilityX}
              cy={probabilityY}
              fill="transparent"
            />
          </Tooltip>
        </g>
      </g>
      {/* gradient defs */}
      <defs>
        <linearGradient id="xGradient">
          <stop
            offset="0"
            stopColor={theme.palette.text.secondary}
            stopOpacity="0.05"
          />
          <stop
            offset="1"
            stopColor={theme.palette.text.secondary}
            stopOpacity="0.8"
          />
        </linearGradient>
        <linearGradient id="yGradient" gradientTransform="rotate(90)">
          <stop
            offset="0"
            stopColor={theme.palette.text.secondary}
            stopOpacity="0.8"
          />
          <stop
            offset="1"
            stopColor={theme.palette.text.secondary}
            stopOpacity="0.05"
          />
        </linearGradient>
      </defs>
    </Box>
  );
}
