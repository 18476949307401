import { Grid, Typography } from "@mui/material";
import { AggregateScoreType, OrderDirection } from "~/operations";
import {
  NoSearchResults,
  SearchFilterSection,
  SearchResultsTable,
  useFullTextSearch,
} from ".";
import { Org } from "~/lib/types";
import { LoadingFailedPage, LoadingPage } from "~/components/loading";
import { pluralize } from "~/lib/pluralize";
import { NetworkStatus } from "@apollo/client";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";

type FullTextSpacePage = {
  scope: SpaceOrWorkspaceScope;
  org?: never;
};

type FullTextOrgPage = {
  scope?: never;
  org: Org;
};

export type FullTextSearchResultsProps = FullTextSpacePage | FullTextOrgPage;

export function FullTextSearchResults({
  org,
  scope,
}: FullTextSearchResultsProps) {
  const { activeFilter, counts, handle, page, query } = useFullTextSearch({
    scope: scope ? scope : org,
  });

  if (query.error) {
    return <LoadingFailedPage what="search results" />;
  }

  if (query.networkStatus === NetworkStatus.loading) {
    return <LoadingPage what="search results" />;
  }

  const { edges, pageInfo, scoreTypeDistribution } = query.data?.search ?? {};

  if (edges?.length === 0 && query.networkStatus === NetworkStatus.ready) {
    return (
      <NoSearchResults
        searchQuery={query.searchQuery}
        name={scope ? scope.name : org.name}
      />
    );
  }

  return (
    <Grid container spacing={5}>
      <Grid
        item
        xs={12}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Typography variant="h5" component="h2" fontWeight={700}>
          {counts.totalCount} {pluralize("result", counts.totalCount)} in{" "}
          {org?.name || scope?.name}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 2,
        }}
      >
        <SearchFilterSection
          {...{
            activeFilter,
            toggleFilter: handle.toggleFilter,
            scoreTypeDistribution,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <SearchResultsTable
          {...{
            data: edges?.slice(page.items.from, page.items.to),
            loading: query.loading,
            pageType: activeFilter as AggregateScoreType,
            field: "name",
            direction: OrderDirection.Asc,
            isOrg: Boolean(org),
            handleSortClick: () => {}, // sorting not enabled yet
            paginationProps: {
              fetchMore: query.fetchMore,
              pageInfo,
              setPageItems: page.setItems,
              totalCount: counts.filteredTotalCount,
            },
          }}
        />
      </Grid>
    </Grid>
  );
}
