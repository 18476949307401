import { TableCell } from "@mui/material";
import { AssetResultsChart } from "./AssetResultsChart";

type AssetResultsCellProps = {
  blastRadius: AssetResultsChart["blastRadius"];
  inPreviewMode?: AssetResultsChart["inPreviewMode"];
};

export const AssetResultsCell = ({
  blastRadius,
  inPreviewMode,
}: AssetResultsCellProps) => {
  return (
    <TableCell className="asset-results-cell">
      <AssetResultsChart
        blastRadius={blastRadius}
        inPreviewMode={inPreviewMode}
      />
    </TableCell>
  );
};
