import React from "react";
import { Flex } from "~/components/Flex";
import { alpha, Box, Typography } from "@mui/material";
import {
  RiskFactorStats,
  ScoreRating,
  VersionDistribution,
} from "~/operations";
import { AggregateScoresEdges } from "~/components/FirewatchPage";
import { SoftwareVersionUnit } from "~/pages/space/software/Software/components/SoftwareVersionDistribution/SoftwareVersionUnit";
import { SoftwareVersionRest } from "~/pages/space/software/Software/components/SoftwareVersionDistribution/SoftwareVersionRest";
import { useSoftwareVersionDistribution } from "~/pages/space/software/Software/components/SoftwareVersionDistribution/hooks/useSoftwareVersionDistribution";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";

export const SoftwareVersionDistribution = ({
  versionDistribution,
  findingMrn,
  edges,
  scope,
  riskFactors,
}: {
  versionDistribution: VersionDistribution | null | undefined;
  findingMrn: string;
  scope: SpaceOrWorkspaceScope;
  edges: AggregateScoresEdges;
  riskFactors: (RiskFactorStats | null)[] | null | undefined;
}) => {
  const {
    sortedDistributionByColor,
    longestVersion,
    total,
    getSortedDistributionByColorWithoutRest,
    getRestItems,
    getItemsToHide,
    impactFractions,
  } = useSoftwareVersionDistribution({
    versionDistribution,
  });

  return (
    <Flex
      sx={{
        width: "100%",
        marginTop: `${longestVersion * 5}px`,
      }}
      mb={2}
      flexDirection="column"
      gap={0.5}
    >
      <Flex gap={0.5} display="grid" gridTemplateColumns={impactFractions}>
        {sortedDistributionByColor.map(([color, items]) => {
          const totalGroupCount = items.reduce((agg, curr) => {
            return agg + curr.count;
          }, 0);

          const sortedDistributionByColorWithoutRest =
            getSortedDistributionByColorWithoutRest(items);

          const itemsToHide = getItemsToHide(items);

          const hasItemsToHide = itemsToHide.length > 0;
          const restVersion = hasItemsToHide ? getRestItems(itemsToHide) : null;

          const fractions = [
            ...sortedDistributionByColorWithoutRest,
            restVersion || null,
          ]
            .filter(Boolean)
            .reduce((acc, r) => {
              return acc + `${r?.count}fr `;
            }, "")
            .trim();

          return (
            <Flex flexDirection="column" gap={0.5}>
              <Flex gap={0.5} display="grid" gridTemplateColumns={fractions}>
                {sortedDistributionByColorWithoutRest
                  .flatMap((x) => x ?? [])
                  .map((version) => {
                    const edge = edges?.find((e) =>
                      e?.node?.findingMrn.split("/").includes(version.version),
                    );
                    const cvss = edge?.node?.cvss?.value || 0;
                    const epss = edge?.node?.epss;

                    return (
                      <SoftwareVersionUnit
                        color={color}
                        version={version.version}
                        riskFactors={riskFactors}
                        blastRadius={edge?.node?.blastRadius}
                        epss={epss}
                        cvss={cvss}
                        riskScore={edge?.node?.riskScore || 0}
                        rating={edge?.node?.rating || ScoreRating.None}
                        title={String(edge?.node?.title)}
                        findingMrn={findingMrn}
                        scope={scope}
                      />
                    );
                  }, 0)}
                {hasItemsToHide && restVersion && (
                  <SoftwareVersionRest
                    width={(restVersion.count / totalGroupCount) * 100}
                    color={color}
                    version={`+ ${itemsToHide.length}`}
                    riskFactors={riskFactors}
                    versions={itemsToHide}
                    edges={edges}
                    scope={scope}
                  />
                )}
              </Flex>
              <Flex flexDirection="column">
                <Box
                  sx={{
                    borderRadius: "2px",
                    backgroundColor: alpha(color, 0.5),
                    height: "4px",
                  }}
                />
                <Typography fontSize={12} color={alpha(color, 0.5)}>
                  {Math.round((totalGroupCount / total) * 100)}%
                </Typography>
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
};
