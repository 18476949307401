import { Typography } from "@mui/material";
import Box from "@mui/material/Box";

type NoItemsSectionProps = { target: string };

export function NoItemsSection({ target }: NoItemsSectionProps) {
  return (
    <Box sx={{ p: 4, textAlign: "center" }}>
      <Typography variant="h6">No {target} found</Typography>
    </Box>
  );
}
