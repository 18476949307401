import { Grid2, Typography } from "@mui/material";
import { GenericTable } from "~/components/GenericTable/GenericTable";
import { RiskBoxSmall } from "../../components";
import { Fragment } from "react/jsx-runtime";
import { NoItemsSection } from "../components";
import { useAssetReport } from "./hooks/useAssetReport";

type ScoreDetailSectionProps<
  T extends keyof ReturnType<typeof useAssetReport>["scoreDetailsData"],
> = {
  data: ReturnType<typeof useAssetReport>["scoreDetailsData"][T];
  children: React.ReactNode;
};

export function ScoreDetailSection<
  T extends keyof ReturnType<typeof useAssetReport>["scoreDetailsData"],
>({ data, children }: ScoreDetailSectionProps<T>) {
  return (
    <Grid2 container sx={{ alignItems: "flex-start", columnGap: 2, mb: 3 }}>
      <Grid2
        size="grow"
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid2 size={12} sx={{ display: "flex", columnGap: 2 }}>
          <Typography sx={{ fontWeight: 700 }}>{data.title}</Typography>
          {data.sectionHighlights.map((highlight) => {
            const { options } = highlight ?? {};
            return (
              <Fragment key={highlight.key}>
                <Typography
                  sx={{
                    display: "flex",
                    gap: 0.5,
                    alignItems: "center",
                    fontWeight: options?.weight ?? 700,
                    color: options?.color ?? "text.secondary",
                  }}
                >
                  {highlight.startIcon && highlight.startIcon}
                  {highlight.value}
                </Typography>
              </Fragment>
            );
          })}
        </Grid2>
        <Grid2>
          <Typography variant="body2" color="text.secondary">
            {data.description}
          </Typography>
        </Grid2>
      </Grid2>
      <Grid2 size="auto">
        {/* <RiskBoxSmall riskLevel={"critical"} riskScore={96} /> */}
      </Grid2>
      <Grid2 size={12} sx={{ mt: 3 }}>
        {data.listItems.length > 0 ? (
          <GenericTable
            items={0}
            tableName={data.tableName}
            validSortFields={[]}
            printView
          >
            {children}
          </GenericTable>
        ) : (
          <NoItemsSection
            target={
              data.title === "Vulnerabilities" ? "Vulnerabilities" : "Policies"
            }
          />
        )}
      </Grid2>
    </Grid2>
  );
}
