import { TableCell } from "@mui/material";
import { CommonCellProps } from "~/pages/inventory/components/Advisories/types";
import { ImpactUpdated } from "~/components/impact/Updated/impact-updated";

type ImpactCellProps = {
  hideTooltip?: boolean;
};

export const ImpactCell = ({
  advisory,
  hideTooltip = false,
  ...props
}: CommonCellProps & ImpactCellProps) => {
  return (
    <TableCell data-testid={props["data-testid"]}>
      <ImpactUpdated
        impact={{
          value: advisory.riskScore ?? 0,
          rating: advisory.rating,
        }}
        isRiskScore
        hideTooltip={hideTooltip}
      />
    </TableCell>
  );
};
