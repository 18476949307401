import { ListItemText, ListSubheader } from "@mui/material";
import {
  BaseMenuItem,
  BaseMenuItemProps,
} from "~/pages/space/Workspaces/components/QueryBuilder/AutocompleteDropdown/BaseMenuItem";
import { KeyboardEvent } from "react";

export type MenuItemsGroupProps = {
  groupTitle: string;
  items: Array<BaseMenuItemProps>;
  onClick?: (value: string) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLLIElement>, label: string) => void;
};

export const MenuItemsGroup = ({
  items,
  groupTitle,
  onClick,
  onKeyDown,
}: MenuItemsGroupProps) => {
  return (
    <>
      <ListSubheader
        sx={{
          backgroundColor: "background.default",
          mt: 3,
          mb: 1,
          px: 3,
          lineHeight: "16px",
          fontSize: "12px",
          pointerEvents: "none",
        }}
      >
        {groupTitle}
      </ListSubheader>
      {items.map((item) => {
        return (
          <BaseMenuItem
            label={item.label}
            value={item.value}
            onClick={onClick}
            key={item.label}
            onKeyDown={(event) => onKeyDown?.(event, item.value)}
          >
            <ListItemText primary={item.label} />
          </BaseMenuItem>
        );
      })}
    </>
  );
};
