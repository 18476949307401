import { Fragment, useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Link,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { ChevronRightIcon, OpenInNewIcon } from "~/components/icons";
import { Command } from "~/components/guides/components";
import { Space } from "~/lib/types";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  ActionType,
  ClientIntegration,
  ClientIntegrationType,
  GetClientIntegrationDocument,
  GetIntegrationsSummaryDocument,
  IntegrationType,
  useCreateClientIntegrationMutation,
  useTriggerActionLazyQuery,
  useUpdateClientIntegrationConfigurationMutation,
} from "~/operations";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { getError } from "~/lib/handle-error";
import {
  helperTextStyles,
  ID_PLACEHOLDER,
  ValidationMessage,
} from "../../validations/helpers";
import { IntegrationAddHeader } from "../../headers/integration-add-header";
import useGenerateIntegrationName from "../../utils/useGenerateIntegrationName";
import { UpdateFlowData } from "../../types";
import { ConfigurePreferences } from "./ConfigurePreferences";
import { ToggleOption } from "../../components/ToggleOption/ToggleOption";
import { isFeatureEnabled } from "~/login/features";
import { motion } from "framer-motion";
import { Flex } from "~/components/Flex";
import { SubField } from "~/components/Form/components";

type CloudFormInput = {
  integrationName: string;
  clientId: string;
  clientSecret: string;
  cloud: string;
  createAssets: boolean;
  useMemberCID: boolean;
  memberCID: string;
};

const defaultValues: CloudFormInput = {
  integrationName: "",
  clientId: "",
  clientSecret: "",
  cloud: "",
  createAssets: false,
  useMemberCID: false,
  memberCID: "",
};

const CLIENT_SECRET_BLOCK = "**********************";
const clientSecretBlockRegex = /^\*+$/;

export function CrowdStrikeIntegrationForm({
  space,
  updateFlow,
}: {
  space: Space;
  updateFlow?: UpdateFlowData;
}) {
  let navigate = useNavigate();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [createdIntegrationMrn, setCreatedIntegrationMrn] = useState<
    ClientIntegration["mrn"] | undefined
  >(undefined);
  const [view, setView] = useState<"setup" | "configure">("setup");
  const [isFinalized, setIsFinalized] = useState(false);
  const defaultIntegrationName = useGenerateIntegrationName({ space });

  const {
    control,
    handleSubmit,
    watch,
    reset,
    getFieldState,
    formState: { errors, isValid, isSubmitSuccessful },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
      integrationName: defaultIntegrationName,
    },
  });

  const [
    integrationName,
    clientId,
    clientSecret,
    cloud,
    createAssets,
    useMemberCID,
    memberCID,
  ] = watch([
    "integrationName",
    "clientId",
    "clientSecret",
    "cloud",
    "createAssets",
    "useMemberCID",
    "memberCID",
  ]);

  const [createIntegration] = useCreateClientIntegrationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      if (data.createClientIntegration.integration) {
        setCreatedIntegrationMrn(data.createClientIntegration.integration.mrn);
      }
    },
    refetchQueries: [
      {
        query: GetIntegrationsSummaryDocument,
        variables: { input: { spaceMrn: space.mrn } },
      },
    ],
  });

  const [updateIntegration] = useUpdateClientIntegrationConfigurationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    refetchQueries: [
      {
        query: GetClientIntegrationDocument,
        variables: {
          mrn: `//integration.api.mondoo.app/spaces/${
            space.id
          }/integrations/${updateFlow?.integration.mrn.split("/").pop()}`,
        },
      },
    ],
  });

  const [TriggerClientIntegrationScan] = useTriggerActionLazyQuery({
    variables: {
      input: { mrn: createdIntegrationMrn!, type: ActionType.RunImport },
    },
  });

  const handleTriggerScan = async () => {
    try {
      await TriggerClientIntegrationScan();
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (updateFlow) {
      if (
        updateFlow?.integration.configurationOptions?.__typename !==
        "CrowdstrikeFalconConfigurationOptions"
      )
        return;

      reset({
        integrationName: updateFlow.integration.name,
        clientId: updateFlow.integration.configurationOptions.clientId,
        cloud: updateFlow.integration.configurationOptions?.cloud || "",
        clientSecret: CLIENT_SECRET_BLOCK,
        createAssets: Boolean(
          updateFlow.integration.configurationOptions?.createAssets,
        ),
        memberCID: updateFlow.integration.configurationOptions?.memberCID ?? "",
      });
    }
  }, []);

  useEffect(() => {
    if (isSubmitSuccessful && isFinalized && !updateFlow) {
      reset(defaultValues);
    }
  }, [isSubmitSuccessful]);

  const onSubmit: SubmitHandler<CloudFormInput> = async (data) => {
    const {
      clientId,
      clientSecret,
      cloud,
      createAssets,
      useMemberCID,
      memberCID,
    } = data;
    if (!updateFlow) {
      if (!clientSecret) {
        throw new Error("You must supply a valid client secret");
      }
    }

    const crowdstrikeFalconConfigurationOptions = {
      clientId,
      ...(clientSecret && !clientSecretBlockRegex.test(clientSecret)
        ? { clientSecret }
        : { clientSecret: "" }),
      cloud,
      createAssets,
      ...(useMemberCID && { memberCID }),
    };

    try {
      if (updateFlow) {
        const integrationId = updateFlow.integration.mrn.split("/").pop();
        const mrn = `//integration.api.mondoo.app/spaces/${space.id}/integrations/${integrationId}`;
        await updateIntegration({
          variables: {
            input: {
              mrn,
              name: data.integrationName.trim(),
              type: ClientIntegrationType.CrowdstrikeFalcon,
              configurationOptions: {
                crowdstrikeFalconConfigurationOptions,
              },
            },
          },
        });
        enqueueSnackbar("Successfully updated configuration", {
          variant: "success",
        });
        navigate(
          `/space/integrations/crowdstrike/${integrationId}/?spaceId=${space.id}`,
        );
      } else {
        const newIntegration = await createIntegration({
          variables: {
            input: {
              spaceMrn: space.mrn,
              name: data.integrationName.trim(),
              type: ClientIntegrationType.CrowdstrikeFalcon,
              longLivedToken: false,
              configurationOptions: {
                crowdstrikeFalconConfigurationOptions,
              },
            },
          },
        });

        if (isFeatureEnabled("Create Assets Configuration")) {
          // if the feature is enabled, we want to show the preferences form after the setup
          setView("configure");
        } else {
          handleTriggerScan();

          enqueueSnackbar("Successfully updated configuration", {
            variant: "success",
          });

          const newIntegrationMrn =
            newIntegration.data?.createClientIntegration.integration.mrn;

          if (newIntegrationMrn) {
            navigate(
              `/space/integrations/crowdstrike/${newIntegrationMrn?.split("/").pop()}/?spaceId=${space.id}`,
            );
          } else {
            navigate(`/space/integrations/crowdstrike?spaceId=${space.id}`);
          }
        }
      }
    } catch (e) {
      const msg = getError(e);
      enqueueSnackbar(msg, { variant: "error" });
    }
  };

  const onFinalizeSetup = async () => {
    if (!createdIntegrationMrn) {
      return;
    }

    try {
      await updateIntegration({
        variables: {
          input: {
            mrn: createdIntegrationMrn,
            name: integrationName,
            type: ClientIntegrationType.CrowdstrikeFalcon,
            configurationOptions: {
              crowdstrikeFalconConfigurationOptions: {
                clientId,
                clientSecret,
                cloud,
                createAssets,
                ...(memberCID ? { memberCID } : {}),
              },
            },
          },
        },
      });

      setIsFinalized(true);
      // kick off the import
      handleTriggerScan();

      enqueueSnackbar("Successfully updated configuration", {
        variant: "success",
      });
      navigate(
        `/space/integrations/crowdstrike/${createdIntegrationMrn.split("/").pop()}/?spaceId=${space.id}`,
      );
    } catch (error) {
      console.error("Error updating integration", error);
    }
  };

  const preferencesForm = (
    // Step 7
    <Box pb={4}>
      <Command
        number={7}
        options={{
          fontSize: { xs: 16 },
          dotColor: theme.palette.background.lightest,
        }}
      >
        Configure preferences
      </Command>
      <Box sx={{ mt: 3 }}>
        <ToggleOption
          title="Create new assets for unique detections"
          description="When a third-party data integration discovers an asset that doesn't match any existing assets in this space, add the new asset."
          formOptions={{ control, name: "createAssets" }}
        />
      </Box>
    </Box>
  );

  return (
    <Fragment>
      {view === "setup" ? (
        <Fragment>
          <IntegrationAddHeader
            {...{ type: IntegrationType.CrowdstrikeFalcon }}
          />
          <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* Step 1 */}
              <Box pb={4}>
                <Command
                  number={1}
                  options={{
                    fontSize: { xs: 16 },
                    dotColor: theme.palette.background.lightest,
                  }}
                >
                  Create a new API client in CrowdStrike Falcon
                </Command>
                <Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2, mt: 2 }}
                  >
                    To integrate Mondoo and CrowdStrike, go to the CrowdStrike
                    Falcon console and create a new API client for Mondoo.
                    Provide the API client's client ID, secret, and base URL
                    below. To learn more, read the{" "}
                    <Link
                      href="https://mondoo.com/docs/platform/infra/imports/cloudstrike/"
                      target="_blank"
                      rel="noopener"
                    >
                      Mondoo documentation
                    </Link>
                    .
                  </Typography>

                  <Button
                    href="https://falcon.crowdstrike.com/login/"
                    target="_blank"
                    variant="outlined"
                    color="secondary"
                    endIcon={<OpenInNewIcon />}
                  >
                    Go to CrowdStrike console
                  </Button>
                </Box>
              </Box>
              {/* Step 2 */}
              <Box pb={4}>
                <Command
                  number={2}
                  options={{
                    fontSize: { xs: 16 },
                    dotColor: theme.palette.background.lightest,
                  }}
                >
                  Choose an integration name
                </Command>
                <Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2, mt: 2 }}
                  >
                    Use a descriptive name that lets you easily identify the
                    integration later.
                  </Typography>
                  <Controller
                    name="integrationName"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        sx={{
                          background: theme.palette.code.background,
                          borderRadius: 1,
                          color: "text.primary",
                          ...helperTextStyles,
                        }}
                        placeholder="Your integration name..."
                        error={Boolean(errors.integrationName)}
                        helperText={
                          Boolean(errors.integrationName) && (
                            <ValidationMessage error={errors.integrationName} />
                          )
                        }
                      />
                    )}
                  />
                </Box>
              </Box>

              {/* step 3 */}
              <Box pb={4}>
                <Command
                  number={3}
                  options={{
                    fontSize: { xs: 16 },
                    dotColor: theme.palette.background.lightest,
                  }}
                >
                  Provide the client ID
                </Command>
                <Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2, mt: 2 }}
                  >
                    Paste the client ID of the CrowdStrike API client you
                    created for Mondoo.
                  </Typography>
                  <Controller
                    name="clientId"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        sx={{
                          background: theme.palette.code.background,
                          borderRadius: 1,
                          color: "text.primary",
                          ...helperTextStyles,
                        }}
                        placeholder="e.g. A12B34567C"
                        error={
                          getFieldState("clientId").isTouched &&
                          Boolean(errors.clientId)
                        }
                        helperText={
                          Boolean(errors.clientId) &&
                          getFieldState("clientId").isTouched && (
                            <ValidationMessage error={errors.clientId} />
                          )
                        }
                      />
                    )}
                  />
                </Box>
              </Box>

              {/* Step 4 */}
              <Box pb={4}>
                <Command
                  number={4}
                  options={{
                    fontSize: { xs: 16 },
                    dotColor: theme.palette.background.lightest,
                  }}
                >
                  Provide the secret
                </Command>
                <Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2, mt: 2 }}
                  >
                    Paste the secret of the CrowdStrike API client you created
                    for Mondoo.
                  </Typography>
                  <Controller
                    name="clientSecret"
                    control={control}
                    rules={{
                      required: updateFlow ? false : true,
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        sx={{
                          background: theme.palette.code.background,
                          borderRadius: 1,
                          color: "text.primary",
                          ...helperTextStyles,
                        }}
                        placeholder="e.g. 1234abcd5678efgh9012ijkl3456mnop"
                        error={
                          getFieldState("clientSecret").isTouched &&
                          Boolean(errors.clientSecret)
                        }
                        helperText={
                          Boolean(errors.clientSecret) &&
                          getFieldState("clientSecret").isTouched && (
                            <ValidationMessage error={errors.clientSecret} />
                          )
                        }
                      />
                    )}
                  />
                </Box>
              </Box>

              {/* step 5 */}
              <Box pb={4}>
                <Command
                  number={5}
                  options={{
                    fontSize: { xs: 16 },
                    dotColor: theme.palette.background.lightest,
                  }}
                >
                  Provide the base URL
                </Command>
                <Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2, mt: 2 }}
                  >
                    Paste the base URL of the CrowdStrike API client you created
                    for Mondoo.
                  </Typography>
                  <Controller
                    name="cloud"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        sx={{
                          background: theme.palette.code.background,
                          borderRadius: 1,
                          color: "text.primary",
                          ...helperTextStyles,
                        }}
                        placeholder="https://api.your-region.crowdstrike.com/"
                        error={
                          getFieldState("cloud").isTouched &&
                          Boolean(errors.cloud)
                        }
                        helperText={
                          Boolean(errors.cloud) &&
                          getFieldState("cloud").isTouched && (
                            <ValidationMessage error={errors.cloud} />
                          )
                        }
                      />
                    )}
                  />
                </Box>
              </Box>

              {/* Step 6 */}
              <Box pb={4}>
                <Box>
                  <Command
                    number={6}
                    options={{
                      fontSize: { xs: 16 },
                      dotColor: theme.palette.background.lightest,
                    }}
                  >
                    Configure member CID usage
                  </Command>
                  <Box sx={{ mt: 2 }}>
                    <ToggleOption
                      title="Export data from a specific member CID"
                      description="If the API client you created for Mondoo has access to multiple CIDs, you can choose a single member CID from which to export data."
                      formOptions={{
                        name: "useMemberCID",
                        control,
                      }}
                    />

                    <Box
                      component={motion.div}
                      initial={false}
                      transition={{
                        duration: 0.35,
                        staggerChildren: 0.25,
                      }}
                      sx={{ overflow: "hidden", pt: 1 }}
                    >
                      <SubField
                        configuration="Provide the member CID"
                        caption={
                          <Fragment>
                            To learn how to find the member CID, read the{" "}
                            <Link
                              href="https://mondoo.com/docs/platform/infra/imports/cloudstrike/"
                              target="_blank"
                              rel="noopener"
                            >
                              Mondoo documentation
                            </Link>
                          </Fragment>
                        }
                        disabled={!useMemberCID}
                        field={
                          <Flex flex="1 0 auto" alignItems="flex-end">
                            <Controller
                              name="memberCID"
                              control={control}
                              rules={{
                                required: false,
                              }}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  fullWidth
                                  disabled={!useMemberCID}
                                  sx={{
                                    background: theme.palette.code.background,
                                    borderRadius: 1,
                                    color: "text.primary",
                                    ...helperTextStyles,
                                  }}
                                  label="Member CID"
                                  placeholder="e.g., 1234abcd5678efgh9012ijkl3456mnop"
                                  slotProps={{
                                    inputLabel: { shrink: true },
                                  }}
                                  error={
                                    getFieldState("memberCID").isTouched &&
                                    Boolean(errors.memberCID)
                                  }
                                  helperText={
                                    Boolean(errors.memberCID) &&
                                    getFieldState("memberCID").isTouched && (
                                      <ValidationMessage
                                        error={errors.memberCID}
                                      />
                                    )
                                  }
                                />
                              )}
                            />
                          </Flex>
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  {...(isFeatureEnabled("Create Assets Configuration") && {
                    endIcon: <ChevronRightIcon />,
                  })}
                  // setValue doesn't trigger the required validation so we're doing it manually here
                  disabled={updateFlow ? !isValid : !isValid || !clientSecret}
                >
                  {updateFlow ? "update configuration" : "create integration"}
                </Button>
              </Box>
            </form>
          </Box>
        </Fragment>
      ) : (
        <ConfigurePreferences
          {...{
            integrationType: IntegrationType.CrowdstrikeFalcon,
            preferencesForm,
            onFinalizeSetup,
          }}
        />
      )}
    </Fragment>
  );
}
