import { Box, Button, Grid, Grid2, Typography } from "@mui/material";
import {
  AggregateScoreType,
  useGetWorkspaceSelectionsQuery,
} from "~/operations";
import { FirewatchDashboardCard } from "~/components/FirewatchDashboardCard";
import { FormProvider, useForm } from "react-hook-form";
import { CreateWorkspaceFormInput } from "~/pages/space/Workspaces/components/CreateWorkspace/CreateWorkspacePage";
import { useEffect } from "react";
import { mapQueryBuilderAPIToFormValues } from "~/pages/space/Workspaces/utils";
import { Flex } from "~/components/Flex";
import {
  QueryBuilder,
  SelectionAssetsCount,
} from "~/pages/space/Workspaces/components";
import { DashboardHeader } from "~/pages/space/Dashboards/components/DashboardHeader";
import { InventoryOverviewCardSunburst } from "./InventoryOverviewCard";
import { IntegrationsOverview } from "../IntegrationsOverview";
import { Space } from "~/lib/types";
import { ScopeType, SpaceOrWorkspaceScope } from "~/hooks/useScope";
import { useMetrics } from "~/hooks";
import { Metrics } from "~/utils/arrow";
import { Link } from "react-router-dom";
import { ArrowDownwardIcon } from "~/components/icons";
import { WorkspaceEmpty } from "~/pages/space/Workspaces/components";
import { LoadingPage } from "~/components/loading";

export type HomeDashboardProps = {
  space: Space;
  scope: SpaceOrWorkspaceScope;
};

export function HomeDashboard({ space, scope }: HomeDashboardProps) {
  const { data: workspaceSelectionsData } = useGetWorkspaceSelectionsQuery({
    variables: {
      mrn: scope.mrn,
    },
    skip: scope.type !== ScopeType.Workspace,
  });

  const { assetsGrades: spaceAssetGrades, isLoading: spaceCountLoading } =
    useMetrics({
      entityMrn: scope.type === ScopeType.Space ? scope.mrn : "",
      metricMrns: [Metrics.SecurityAssetsStats],
    });

  const {
    assetsGrades: workspaceAssetGrades,
    isLoading: workspaceCountLoading,
    refetch,
  } = useMetrics({
    entityMrn: scope.type === ScopeType.Workspace ? scope.mrn : "",
    metricMrns: [Metrics.SecurityAssetsStats],
  });

  const totalAssetsCount = spaceAssetGrades.reduce(
    (prev, current) => prev + current.value,
    0,
  );

  const workspaceAssetsCount = workspaceAssetGrades.reduce(
    (prev, current) => prev + current.value,
    0,
  );

  const assetsCount =
    scope.type === ScopeType.Space ? totalAssetsCount : workspaceAssetsCount;
  const assetsCountLoading =
    scope.type === ScopeType.Space ? spaceCountLoading : workspaceCountLoading;

  const workspaceSelections =
    workspaceSelectionsData?.workspace?.__typename === "Workspace"
      ? workspaceSelectionsData?.workspace?.selections?.selections
      : [];

  const formMethods = useForm<CreateWorkspaceFormInput>({
    mode: "onBlur",
    defaultValues: {
      name: "",
      description: "",
      selections: [],
    },
  });

  useEffect(() => {
    if (scope.type !== ScopeType.Workspace) return;

    formMethods.reset({
      selections: mapQueryBuilderAPIToFormValues(workspaceSelections),
    });
  }, [workspaceSelections]);

  return (
    <Box>
      {scope.type === "workspace" ? (
        <DashboardHeader
          title={scope.name}
          description={scope.description}
          additionalContent={
            <FormProvider {...formMethods}>
              <Grid2 size={12}>
                <Flex
                  sx={{ color: "text.primary", my: 3 }}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h5" fontWeight={700}>
                    Workspace Asset Selections
                  </Typography>
                  <Flex alignItems="center" gap={2}>
                    <SelectionAssetsCount
                      selectionCount={workspaceAssetsCount}
                      totalCount={totalAssetsCount}
                    />
                    <Button
                      component={Link}
                      color="primary"
                      endIcon={<ArrowDownwardIcon sx={{ rotate: "-90deg" }} />}
                      to={`/space/settings?${scope.params}`}
                    >
                      Edit
                    </Button>
                  </Flex>
                </Flex>
                <QueryBuilder isReadonly />
              </Grid2>
            </FormProvider>
          }
        />
      ) : (
        <DashboardHeader title={scope.name} description={scope.description} />
      )}
      {assetsCountLoading && <LoadingPage what={`${scope.type} data`} />}
      {!assetsCountLoading && (
        <Box>
          {assetsCount === 0 && scope.type === ScopeType.Workspace && (
            <WorkspaceEmpty
              space={space}
              scope={scope}
              onRefreshComplete={() => {
                refetch();
              }}
            />
          )}
          {assetsCount > 0 && (
            <Grid container spacing={3}>
              <Grid container item spacing={3} xs={12}>
                <Grid item xs={12} lg={6}>
                  <InventoryOverviewCardSunburst
                    scope={scope}
                    spaceScope={space}
                  />
                </Grid>
                <Grid container item spacing={3} xs={12} lg={6}>
                  <Grid item xs={12}>
                    <FirewatchDashboardCard
                      pageType={AggregateScoreType.Vulnerability}
                      scope={scope}
                      space={space}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FirewatchDashboardCard
                      pageType={AggregateScoreType.Check}
                      scope={scope}
                      space={space}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {scope?.type === "space" && (
                <IntegrationsOverview {...{ spaceScope: space }} />
              )}
            </Grid>
          )}
        </Box>
      )}
    </Box>
  );
}
