import { Theme, useTheme } from "@mui/material";
import {
  useGetVulnerabilityScoresQuery,
  VulnerabilityScoreOrderField,
  OrderDirection,
  VulnerabilityScoreType,
  useLoadAssetQuery,
  AssetPolicyListEdge,
  PackageScoresOrderField,
  useGetAssetPackagesQuery,
  ScoreRating,
} from "~/operations";
import { ReactNode } from "react";
import { ApolloError } from "@apollo/client";
import {
  extractItemKey,
  extractItemValue,
} from "~/components/asset-configuration-section";
import { AdvisoriesOrVulnerabilities, Asset, PackageScores } from "../types";
import { getRating } from "~/pages/inventory/components/PolicyScoreCard/asset/assetOverviewHeader/assetOverviewHeader";
import { getRatingText } from "../../lib/GetRatingText";

// Define the return type for the useAssetReport hook
type UseAssetReportReturnObject = {
  loading: boolean;
  error: ApolloError | undefined;
  asset: Asset | undefined | null;
  assetRating: { rating: ScoreRating; value: number };
  theme: Theme;
  functions: {
    generateText: {
      header: () => string;
    };
  };
  scoreDetailsData: {
    [key: string]: {
      title: string;
      sectionHighlights: {
        key: string;
        value: string;
        options?: { color?: string; weight?: number };
        startIcon?: ReactNode;
      }[];
      tableName: string;
      listItems: any[];
      description: string;
      totalChecks?: number;
    };
  };
  platformOverviewData: { id: string; key: string; value: string }[];
  assetTags: Asset["labels"] | null | undefined;
  policies: AssetPolicyListEdge[];
  advisories: AdvisoriesOrVulnerabilities;
  vulnerabilities: AdvisoriesOrVulnerabilities;
  packageScores: PackageScores;
  securityScore: {
    [key: string]: {
      score: number | undefined;
      rating: string;
    };
  };
};

// Hook to fetch and process asset report data
export function useAssetReport(): UseAssetReportReturnObject {
  const { searchParams } = new URL(window.location.href);
  const assetMrn = searchParams.get("assetMrn") || "";
  let theme = useTheme();

  // Fetch asset data
  const {
    data: assetData,
    error: assetError,
    loading: assetLoading,
  } = useLoadAssetQuery({
    variables: {
      mrn: assetMrn,
    },
  });

  // Fetch vulnerability scores
  const {
    data: securityVulnerabilityData,
    error,
    loading,
  } = useGetVulnerabilityScoresQuery({
    variables: {
      entityMrn: assetMrn,
      orderBy: {
        field: VulnerabilityScoreOrderField.RiskScore,
        direction: OrderDirection.Asc,
      },
      filter: {
        scoreType: VulnerabilityScoreType.Cve,
      },
    },
  });

  // Fetch advisory scores
  const { data: advisoryData } = useGetVulnerabilityScoresQuery({
    variables: {
      entityMrn: assetMrn,
      orderBy: {
        field: VulnerabilityScoreOrderField.RiskScore,
        direction: OrderDirection.Asc,
      },
      filter: {
        scoreType: VulnerabilityScoreType.Advisory,
      },
    },
  });

  // Fetch vulnerability data
  const { data: vulnerabilityData } = useGetVulnerabilityScoresQuery({
    variables: {
      entityMrn: assetMrn,
      orderBy: {
        field: VulnerabilityScoreOrderField.RiskScore,
        direction: OrderDirection.Asc,
      },
      filter: {
        scoreType: VulnerabilityScoreType.Cve,
      },
    },
  });

  // Fetch package scores
  const { data: packageScoresData } = useGetAssetPackagesQuery({
    variables: {
      input: {
        entityMrn: assetMrn,
        filter: { isVulnerable: true },
      },
      orderBy: {
        field: PackageScoresOrderField.RiskScore,
        direction: OrderDirection.Asc,
      },
    },
    errorPolicy: "all",
  });

  const { asset } = assetData || {};

  // Process vulnerability scores in Security section
  const securityVulnerabilitiesEdges =
    securityVulnerabilityData?.vulnerabilityScores?.__typename ===
    "VulnerabilityScoresConnection"
      ? securityVulnerabilityData.vulnerabilityScores.edges
      : [];

  const securityVulnerabilities = securityVulnerabilitiesEdges || [];

  const totalVulnerabilities =
    securityVulnerabilityData?.vulnerabilityScores?.__typename ===
    "VulnerabilityScoresConnection"
      ? securityVulnerabilityData.vulnerabilityScores.totalCount
      : 0;

  // Process advisory scores
  const advisoryEdges =
    advisoryData?.vulnerabilityScores?.__typename ===
    "VulnerabilityScoresConnection"
      ? advisoryData.vulnerabilityScores.edges
      : [];

  const advisories = advisoryEdges || [];

  // Process vulnerability data
  const vulnerabiltiesEdges =
    vulnerabilityData?.vulnerabilityScores?.__typename ===
    "VulnerabilityScoresConnection"
      ? vulnerabilityData.vulnerabilityScores.edges
      : [];
  const vulnerabilities = vulnerabiltiesEdges || [];

  // Process policies
  const policies = asset?.listPolicies?.edges || [];

  // Process package scores
  const packageScoresEdges =
    packageScoresData?.packageScores?.__typename === "PackageScores"
      ? packageScoresData?.packageScores.edges
      : [];
  const packageScores = packageScoresEdges || [];

  // Calculate total checks
  const totalChecks = policies.reduce((acc, policy) => {
    return acc + (policy.node?.scoreStats?.total ?? 0);
  }, 0);

  const totalPassingChecks = policies.reduce((acc, policy) => {
    return acc + (policy.node?.scoreStats?.passed ?? 0);
  }, 0);

  const totalFailingChecks = policies.reduce((acc, policy) => {
    return acc + (policy.node?.scoreStats?.failed ?? 0);
  }, 0);

  // Calculate total critical vulnerabilities
  const totalCriticalVulnerabilities = securityVulnerabilities.filter(
    (vuln) => vuln.node?.rating === ScoreRating.Critical,
  ).length;

  // Calculate total high vulnerabilities
  const totalHighVulnerabilities = securityVulnerabilities.filter(
    (vuln) => vuln.node?.rating === ScoreRating.High,
  ).length;

  // Calculate total vulnerable packages
  const totalVunerablePackages = packageScores.length;

  // Gather security scores
  const mapRating = (weight: number | undefined): ScoreRating => {
    const ratingNumber = getRating(weight || 0);
    const rating = getRatingText(ratingNumber);
    return rating;
  };

  const securityScore = {
    vulnerability: {
      score: asset?.insights?.vulnerability?.score.value,
      rating: mapRating(asset?.insights?.vulnerability?.score.weight),
    },
    security: {
      score: asset?.insights?.security.score.value,
      rating: mapRating(asset?.insights?.security.score.weight),
    },
  };

  const assetScoreRating = mapRating(asset?.score.weight);

  // extract the overviewGroup with the title "Platform overview"
  const platformOverview = asset?.overviewGroups?.find(
    (group) => group.title === "Platform overview",
  );

  // loop through each item in the platformOverview and create key value pairs
  const platformOverviewData =
    platformOverview?.items.map((item) => {
      const id = item.key;
      const key = extractItemKey(item);
      const value = extractItemValue(item);

      return { id, key, value };
    }) || [];

  // Extract the asset tags
  const assetTags = asset?.labels;

  // Generate text for the header.
  const generateText = {
    header: () => {
      return `Mondoo ran ${totalChecks} security checks on the ${asset?.name} ${asset?.platform?.name} asset. ${totalPassingChecks} passed and ${totalFailingChecks} failed. Mondoo found ${totalCriticalVulnerabilities} critical and ${totalHighVulnerabilities} high vulnerabilities across ${totalVunerablePackages} vulnerable packages. The most severe CVSS score is 10.0. Considering all data and contextual risk factors, Mondoo gives this asset a vulnerability score of ${securityScore.vulnerability.score} (${securityScore.vulnerability.rating.toLowerCase()}) and a security score of ${securityScore.security.score} (${securityScore.security.rating.toLowerCase()}).`;
    },
  };

  // Return the processed data
  return {
    loading: assetLoading || loading,
    error: assetError || error,
    asset,
    assetRating: { rating: assetScoreRating, value: asset?.score.value || 0 },
    theme,
    functions: {
      generateText: {
        header: generateText.header,
      },
    },
    scoreDetailsData: {
      vulnerabilities: {
        title: "Vulnerabilities",
        sectionHighlights: [
          { key: "vuln-found", value: `${totalVulnerabilities} Found` },
          // {
          //   key: "exceptions",
          //   value: "2 Exceptions", // TODO: GET EXCEPTIONS COUNT
          //   options: { color: theme.palette.text.disabled, weight: 400 },
          //   startIcon: <DoNotDisturbOnOutlined sx={{ fontSize: 15 }} />,
          // },
        ],
        tableName: "ASSET_REPORT_SECURITY_VULNERABILITIES",
        listItems: securityVulnerabilities,
        description:
          "The asset vulnerability score is based on the highest risk vulnerability on this asset.",
      },
      security: {
        title: "Security",
        sectionHighlights: [
          { key: "checks", value: `${totalChecks} Checks` },
          // {
          //   key: "exceptions",
          //   value: "3 Exceptions", // TODO: GET EXCEPTIONS COUNT
          //   options: { color: theme.palette.text.disabled, weight: 400 },
          //   startIcon: <DoNotDisturbOnOutlined sx={{ fontSize: 15 }} />,
          // },
        ],
        tableName: "ASSET_REPORT_SECURITY",
        listItems: policies,
        totalChecks,
        description:
          "The asset security score is calculated by averaging each of the scores of your enabled policies, which each make up a certain percentage of the security score. The percentage of the overall security score is based on the number of checks in each policy. Each policy has its own scoring method which can be set on the policy page.",
      },
    },
    assetTags,
    platformOverviewData,
    policies,
    advisories,
    vulnerabilities,
    packageScores,
    securityScore,
  };
}
