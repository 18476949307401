import { alpha, Box, Divider, Grid2, Typography } from "@mui/material";
import { ImpactUpdated } from "~/components/impact/Updated/impact-updated";
import { useAssetReport } from "../AssetReport/hooks/useAssetReport";
import { ScoreRating } from "~/operations";
import { ScoreRatingColorMap } from "../lib/ScoreRatingColorMap";

type PrintScoreBlockProps = {
  mappings: { group: string; chart?: JSX.Element; value: number }[];
  ratingValues: { value: number; rating: ScoreRating };
};

export function PrintScoreBlock({
  mappings,
  ratingValues,
}: PrintScoreBlockProps) {
  const color = ScoreRatingColorMap[ratingValues.rating];

  return (
    <Box>
      <Grid2
        container
        sx={{
          p: 5,
          backgroundColor: (theme) => alpha(theme.palette[color].main, 0.1),
          maxWidth: 320,
          width: 320,
          borderRadius: 1,
          border: "none",
        }}
      >
        <Grid2 size={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <ImpactUpdated
              showText={false}
              impact={{
                rating: ratingValues.rating,
                value: ratingValues.value,
              }}
              isRiskScore
              options={{ size: 16, border: 2 }}
            />
            <Typography
              sx={{
                fontSize: 24,
                fontWeight: 700,
                lineHeight: "24px",
                letterSpacing: ".96px",
                pt: 2,
              }}
            >
              {ratingValues.rating}
            </Typography>
          </Box>
          <Divider
            sx={{
              my: 3,
              borderBottomWidth: 2,
              borderColor: (theme) => alpha(theme.palette[color].main, 0.1),
            }}
          />
          {mappings.map((mapping) => (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mb: 2,
                "&:last-child": { mb: 0 },
              }}
            >
              <Typography
                sx={{
                  flexBasis: "55%",
                  fontWeight: 700,
                  lineHeight: "16px",
                }}
              >
                {mapping.group}
              </Typography>
              {mapping.chart}
              <Typography
                sx={{
                  fontWeight: 700,
                  lineHeight: "24px",
                }}
              >
                {mapping.value}
              </Typography>
            </Box>
          ))}
        </Grid2>
      </Grid2>
    </Box>
  );
}
