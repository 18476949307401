import { gql } from "@apollo/client";
import { PACKAGE_FIELDS } from "../fragments/PackageFields";

export const GET_ASSET_PACKAGES = gql`
  ${PACKAGE_FIELDS}
  query GetAssetPackages(
    $first: Int
    $after: String
    $orderBy: PackageScoresOrder
    $input: PackageScoresInput
  ) {
    packageScores(
      first: $first
      after: $after
      orderBy: $orderBy
      input: $input
    ) {
      ... on PackageScores {
        totalCount
        totalApps
        totalOsPackages
        stats {
          vulnerablePackages
          totalPackages
        }
        edges {
          cursor
          node {
            id
            riskScore
            rating
            assetName
            lastUpdated
            assetMrn
            packageName
            installedVersion
            availableVersion
            firstFound
            packageType
            iconIDEnum
            cvssScore {
              value
              id
              source
              type
              vector
            }
            epssScore {
              probability
              percentile
            }
            riskFactors {
              mrn
              indicator
              title
              total
              affected
            }
            platform
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
      ... on NotFoundError {
        message
        code
      }
    }
  }
`;
