import { ReactElement } from "react";
import { Box, useTheme } from "@mui/material";
import { ImpactResultIcon } from "../impact-result-icon";
import { Impact as ImpactType } from "~/operations";
import { CheckResult } from "../types";
import { Score } from "~/lib/types";
import { ImpactUpdated } from "./impact-updated";
import { ImpactTooltipUpdated } from "./impact-tooltip-updated";

// ImpactResultUpdated is a copy of the ImpactResult component that has been slightly modified
// to use the impact.rating and impact.value so that we no longer need to do any calculations
// on the front end to display the correct impact.  impact.rating is a string of "none", "low", "medium", "high, or "critical.
// which gives us everything we need to show the correct color, text, and "dots" for the impact.

type CheckImpactResultType = {
  impact: ImpactType;
  score: Score;
  isActive?: boolean;
  isRiskScore?: boolean;
  isCVSS?: boolean;
  hideTooltip?: boolean;
};

export const ImpactResultUpdated = ({
  impact,
  score,
  isActive = true,
  isRiskScore = false,
  isCVSS = false,
  hideTooltip = false,
}: CheckImpactResultType) => {
  const { result, color } = useCheckImpactResultUpdated({
    score,
    isActive,
    impact,
  });

  const TooltipWrapper = ({ children }: { children: ReactElement }) =>
    hideTooltip ? (
      children
    ) : (
      <ImpactTooltipUpdated
        color={color}
        impact={impact}
        result={result}
        isCVSS={isCVSS}
        isRiskScore={isRiskScore}
      >
        {children}
      </ImpactTooltipUpdated>
    );

  return (
    <TooltipWrapper>
      <Box display="inline-flex" gap={1}>
        <ImpactResultIcon result={result} color={color} />
        <ImpactUpdated
          isActive={isActive}
          impact={impact}
          adjustedColor={color}
          hideTooltip
        />
      </Box>
    </TooltipWrapper>
  );
};

type UseImpactResult = {
  isActive: boolean;
  impact: ImpactType;
  score: Score;
};

export function useCheckImpactResultUpdated({
  isActive,
  impact,
  score,
}: UseImpactResult) {
  const color = useGetImpactColorUpdated(impact);

  function getResultFromScore(): CheckResult {
    // SKIP
    if (score.type == 8 || score.type == 16) {
      return "skip";
    }
    // FAIL
    if (score.type == 2 && score.value != 100) {
      return "fail";
      // PASS
    } else if (score.type == 2 && score.value == 100) {
      return "pass";
      // ERROR
    } else if (score.type == 4) {
      return "error";
    }

    return "skip";
  }

  // The check was skipped in the process
  const isCheckResultSkipped = getResultFromScore() === "skip";

  const isInactive = isCheckResultSkipped || !isActive;

  return {
    result: getResultFromScore(),
    color: adjustImpactColor({ color, isInactive, score }),
  };
}

export function adjustImpactColor({
  color,
  score,
  isInactive,
}: {
  color: string;
  isInactive: boolean;
  score?: Score;
}) {
  const theme = useTheme();

  // If the check is ignored or disabled
  if (isInactive) {
    return theme.palette.unknown.main;
  }

  if (score) {
    // Check Error
    if (score.type === 4) {
      return theme.palette.error.main;
    }

    // Check Passed
    if (score.value === 100) {
      return theme.palette.none.main;
    }
  }

  return color;
}

export function useGetImpactColorUpdated(
  impact: Omit<ImpactType, "__typename">,
) {
  const theme = useTheme();
  const { rating } = impact;

  let normalizedRating = rating?.toLowerCase();

  if (normalizedRating === "none") {
    return theme.palette.none.main;
  } else if (normalizedRating === "low") {
    return theme.palette.low.main;
  } else if (normalizedRating === "medium") {
    return theme.palette.medium.main;
  } else if (normalizedRating === "high") {
    return theme.palette.high.main;
  } else {
    return theme.palette.critical.main;
  }
}
