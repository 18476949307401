import { ScoreType, useAssetForwardPaginationQuery } from "~/operations";

type UseAssetsParams = {
  scopeMrn: string;
  searchFilters: string[];
};

type UseAssetsReturnType = {
  loading: boolean;
  count: number;
  refetch: ReturnType<typeof useAssetForwardPaginationQuery>["refetch"];
};

export function useGetAssetsCount({
  scopeMrn,
  searchFilters,
}: UseAssetsParams): UseAssetsReturnType {
  const { data, loading, refetch } = useAssetForwardPaginationQuery({
    variables: {
      scopeMrn,
      scoreRange: null,
      scoreType: ScoreType.Unknown,
      platformName: [],
      platformKind: [],
      platformRuntime: [],
      labels: [],
      eol: null,
      reboot: null,
      exploitable: null,
      updated: null,
      orderBy: null,
      queryTerms: searchFilters,
      first: 50,
      groups: [],
    },
    fetchPolicy: "cache-and-network",
  });

  return {
    count: data?.assets?.totalCount || 0,
    loading,
    refetch,
  };
}
