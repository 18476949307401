import { alpha } from "@mui/material";
import { ImpactData, ImpactRatingOptions } from "./types";

type UseImpactCirclesParams = {
  color: string;
  impactData: ImpactData;
  options?: ImpactRatingOptions;
};

export function useImpactRating({
  impactData,
  color,
  options,
}: UseImpactCirclesParams) {
  const SIZE = 5;

  // Creating rating with 5 unpainted units
  const initialImpactRating = new Array(SIZE).fill({});

  // Customize the horizontal space between each of the units
  const gap = options?.size ? options.size / 4 : 2;

  // Painting the correct count of units
  const paintedImpactRating = initialImpactRating.map((impact, index) => {
    return {
      ...impact,
      backgroundColor: index < impactData.rating ? alpha(color, 0.5) : null,
      borderColor: index < impactData.rating ? color : null,
    };
  });

  return {
    gap,
    paintedImpactRating,
  };
}
