import { Typography, Box, Divider } from "@mui/material";
import { Flex } from "~/components/Flex";
import { spacing } from "../reports/lib/SharedVariables";

type SectionHeadingProps = {
  heading: string;
  disableUpperPadding?: boolean;
};

export const SectionHeading = ({
  heading,
  disableUpperPadding,
}: SectionHeadingProps) => {
  return (
    <Flex
      center
      gap={3}
      className="section-header"
      sx={{ pt: disableUpperPadding ? 0 : spacing[25], pb: spacing[25] }}
    >
      <Typography sx={{ whiteSpace: "nowrap", flexShrink: 0, fontWeight: 700 }}>
        {heading}
      </Typography>
      <Box width={1}>
        <Divider />
      </Box>
    </Flex>
  );
};
