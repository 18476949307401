import { createSvgIcon } from "@mui/material";

export const CrowdStrikeFalconIcon = createSvgIcon(
  <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M45.4986 45.9999C44.0007 42.5715 40.9919 38.173 29.2076 31.8916C23.7724 28.8698 14.488 24.2175 6.14062 15.3699C6.89751 18.5618 10.7756 25.5755 27.4487 34.328C32.0678 36.8553 39.8789 39.2254 45.4986 45.9883"
      fill="#FC0000"
    />
    <path
      d="M44.7413 40.1325C43.3198 36.0814 40.7536 30.8942 28.58 23.1927C22.6533 19.3088 13.947 14.4316 2.5 2C3.31888 5.35191 6.9375 14.0683 25.1777 25.3841C31.1693 29.4439 38.9025 31.9481 44.7413 40.1325Z"
      fill="#FC0000"
    />
  </svg>,
  "CrowdStrike Falcon",
);
