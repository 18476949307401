import { OpenDropdownButton } from "../OpenDropdownButton";
import {
  alpha,
  Box,
  InputAdornment,
  TextFieldProps,
  Typography,
  useTheme,
} from "@mui/material";
import { BaseAutocomplete } from "~/pages/space/Workspaces/components/QueryBuilder/AutocompleteDropdown/BaseAutocomplete";
import { CheckboxMenuItem } from "~/pages/space/Workspaces/components/QueryBuilder/AutocompleteDropdown/CheckboxMenuItem";
import * as React from "react";
import { KeyboardEvent, RefObject, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { CreateWorkspaceFormInput } from "~/pages/space/Workspaces/components/CreateWorkspace/CreateWorkspacePage";
import { BaseMenuList } from "~/pages/space/Workspaces/components/QueryBuilder/AutocompleteDropdown/BaseMenuList";
import { MenuItemsGroup } from "~/pages/space/Workspaces/components/QueryBuilder/AutocompleteDropdown/MenuItemsGroup";
import { KeyOutlined } from "@mui/icons-material";
import { ApostrophesIcon } from "~/components/icons";
import { ConditionValuesNameType } from "~/pages/space/Workspaces/components/QueryBuilder/types";
import { last } from "lodash";

type ValuesDropdownAutocompleteProps = {
  values: string[];
  name: ConditionValuesNameType;
  conditionFormDictionaryKey: string;
  options: Array<{ label: string; value: string }>;
  anchorRef: RefObject<HTMLButtonElement>;
  onDropdownSearchFieldKeyUp: React.KeyboardEventHandler<HTMLInputElement>;
  onDropdownSearchFieldChange: TextFieldProps["onChange"];
  isValuesDropdownOpen: boolean;
  isDictionaryKeySelected: boolean;
  onValuesDropdownOpen: () => void;
  onValuesDropdownClose: () => void;
  onItemClick: (value: unknown) => void;
  handleKeyValueKeyClick: (value: string) => void;
  handleKeyValueValueClick: (value: string) => void;
};

export const ValuesDropdownAutocomplete = ({
  values,
  name,
  conditionFormDictionaryKey,
  options,
  anchorRef,
  onDropdownSearchFieldKeyUp,
  onDropdownSearchFieldChange,
  onItemClick,
  isValuesDropdownOpen,
  isDictionaryKeySelected,
  onValuesDropdownOpen,
  onValuesDropdownClose,
  handleKeyValueKeyClick,
  handleKeyValueValueClick,
}: ValuesDropdownAutocompleteProps) => {
  const { register, trigger } = useFormContext<CreateWorkspaceFormInput>();
  const theme = useTheme();

  const lastItemLabel = last(options)?.label;

  const handleKeyDown = (
    event: KeyboardEvent<HTMLLIElement>,
    label: string,
  ): void => {
    // Check if `Tab` is pressed on the last item
    if (event.key === "Tab") {
      if (label === lastItemLabel && !event.shiftKey) {
        // Close dropdown if Tab is pressed on the last item
        onValuesDropdownClose();
      }
    }
  };

  useEffect(() => {
    register(name, {
      required: "This condition is incomplete. Please add a selection.",
    });
    trigger(name);
  }, [register]);

  return (
    <Box>
      <OpenDropdownButton
        isOpen={isValuesDropdownOpen}
        onClick={
          isValuesDropdownOpen ? onValuesDropdownClose : onValuesDropdownOpen
        }
        data-testid={`${isValuesDropdownOpen ? "close" : "open"}-values-dropdown`}
      />
      <Box sx={{ maxWidth: "400px" }}>
        {isDictionaryKeySelected ? (
          <BaseAutocomplete
            showKeyboardHint
            open={isValuesDropdownOpen}
            onClose={onValuesDropdownClose}
            onDropdownSearchFieldKeyUp={onDropdownSearchFieldKeyUp}
            anchorRef={anchorRef}
            onSearchInputChange={onDropdownSearchFieldChange}
            startAdornment={
              <>
                <InputAdornment
                  position="start"
                  onClick={() => {
                    if (!conditionFormDictionaryKey) return;
                    handleKeyValueKeyClick("");
                  }}
                  sx={[
                    conditionFormDictionaryKey
                      ? {
                          height: "40px",
                          maxHeight: "unset",
                          marginLeft: "-8px",
                          cursor: "pointer",
                          borderBottomLeftRadius: "4px",
                          borderTopLeftRadius: "4px",
                          padding: "0 8px",
                          backgroundColor: alpha(
                            theme.palette.primary.main,
                            0.5,
                          ),
                          zIndex: 2,
                          border: "1px solid",
                          borderColor: theme.palette.primary.main,
                        }
                      : {},
                  ]}
                >
                  {conditionFormDictionaryKey ? (
                    <Typography fontWeight={700}>
                      {conditionFormDictionaryKey}
                    </Typography>
                  ) : (
                    <KeyOutlined sx={{ fontSize: "20px" }} />
                  )}
                </InputAdornment>
                {conditionFormDictionaryKey && (
                  <ApostrophesIcon sx={{ fontSize: "16px", mr: 1 }} />
                )}
              </>
            }
            renderContent={() => (
              <BaseMenuList sx={{ mt: 2 }}>
                <MenuItemsGroup
                  groupTitle={
                    conditionFormDictionaryKey
                      ? "SEARCH RESULTS FOR VALUES MATCHING THIS KEY"
                      : "SEARCH RESULTS FOR KEYS"
                  }
                  onClick={
                    conditionFormDictionaryKey
                      ? handleKeyValueValueClick
                      : handleKeyValueKeyClick
                  }
                  onKeyDown={handleKeyDown}
                  items={options}
                />
              </BaseMenuList>
            )}
          />
        ) : (
          <BaseAutocomplete
            showKeyboardHint
            open={isValuesDropdownOpen}
            onClose={onValuesDropdownClose}
            onDropdownSearchFieldKeyUp={onDropdownSearchFieldKeyUp}
            anchorRef={anchorRef}
            onSearchInputChange={onDropdownSearchFieldChange}
            renderContent={() => (
              <BaseMenuList sx={{ mt: 2 }}>
                {options.map((option) => (
                  <CheckboxMenuItem
                    label={option.label}
                    value={option.value}
                    isChecked={values.includes(option.value)}
                    onClick={onItemClick}
                    key={option.value}
                    onKeyDown={handleKeyDown}
                  />
                ))}
              </BaseMenuList>
            )}
          />
        )}
      </Box>
    </Box>
  );
};
