import { TableRow } from "@mui/material";
import {
  RiskRatingCell,
  PublishedCell,
  TitleCell,
  SecurityScoreCell,
  NumberOfNumberCell,
} from "~/components/Cells";
import { Fragment } from "react/jsx-runtime";
import { SectionHeading } from "../../components";
import { policyIcon } from "~/pages/inventory/utils/policyIcon";
import { ScoreDetailSection } from "./ScoreDetailsSection";
import { useAssetReport } from "./hooks/useAssetReport";
import { getRating } from "~/pages/inventory/components/PolicyScoreCard/asset/assetOverviewHeader/assetOverviewHeader";
import { ScoreRating } from "~/operations";
import { getRatingText } from "../lib/GetRatingText";

export function ScoreDetails() {
  const { theme, scoreDetailsData } = useAssetReport();

  return (
    <Fragment>
      <SectionHeading heading="Asset score details" disableUpperPadding />
      <ScoreDetailSection data={scoreDetailsData.vulnerabilities}>
        {scoreDetailsData.vulnerabilities.listItems.map((item) => {
          const { node } = item;
          return (
            <TableRow key={node?.id}>
              <TitleCell
                title={node.id}
                detailText={node.title}
                tags={node.tags}
              />
              <RiskRatingCell
                rating={{ rating: node?.rating, value: node.riskScore }}
              />
              <PublishedCell date={node?.publishedAt ?? ""} />
            </TableRow>
          );
        })}
      </ScoreDetailSection>
      <ScoreDetailSection data={scoreDetailsData.security}>
        {scoreDetailsData.security.listItems.map((policy) => {
          const policyChecks = policy.node?.scoreStats?.total ?? 0;
          let totalChecks = scoreDetailsData.security.totalChecks ?? 0;
          const icon = policyIcon(policy.node.name);

          // This is a workaround until we get the correct data coming from the API
          const ratingNumber = getRating(policy.node.score.weight || 0);
          const rating = getRatingText(ratingNumber);

          return (
            <TableRow key={policy.node?.mrn}>
              <TitleCell title={policy.node?.name ?? ""} icon={icon} />
              <RiskRatingCell
                showValue
                rating={{
                  rating,
                  value: policy.node.score.value,
                }}
              />
              <SecurityScoreCell
                value={policyChecks}
                totalChecks={totalChecks}
              />
              <NumberOfNumberCell value={policyChecks} total={totalChecks} />
            </TableRow>
          );
        })}
      </ScoreDetailSection>
    </Fragment>
  );
}
