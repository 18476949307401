import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { TableRow } from "@mui/material";

export const ClickableTableRow = styled(TableRow)<{
  component?: typeof RouterLink;
  to?: string;
}>`
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.palette.background.light};
  }

  & .hideUntilHover {
    visibility: hidden;
  }

  &:hover .hideUntilHover {
    visibility: initial;
  }

  & .fadeUntilHover {
    opacity: 0;
    transition: opacity 325ms;
  }

  &:hover .fadeUntilHover {
    opacity: 1;
  }
`;
