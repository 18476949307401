import { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  Box,
  Checkbox,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { useControlContext } from "../control";
import { ComplianceTable, Header } from "../../compliance-table";
import { Loading, LoadingFailed } from "~/components/loading";
import { MoonIcon } from "~/components/ui-library";
import {
  ChecksOrderField,
  CheckState,
  ExceptionType,
  GetComplianceControlChecksDocument,
  GetComplianceControlChecksQuery,
  Maybe,
  OrderDirection,
  PolicyOrPackRef,
  ReviewStatus,
  TestIamActionsQuery,
  useGetComplianceControlChecksQuery,
} from "~/operations";
import { useSearch } from "~/components/search/useSearch";
import { EmptyState } from "../../../../components/empty-state/empty-state";
import { INITIAL_PAGE_RANGE, Pagination } from "~/components/pagination";
import { CompletionCell } from "../../components/completion-cell";
import { IamActions } from "~/lib/iam";
import { ExceptionsToolbar } from "~/components/exceptions/exceptions-toolbar";
import { ExceptionsModals } from "~/components/exceptions/exceptions-modals";
import { useExceptions } from "~/components/exceptions/use-exceptions";
import { useFetchExceptions } from "~/components/exceptions/use-fetch-exceptions";
import { Chip } from "~/components/chip";
import { BlockIcon } from "~/components/icons";
import { NavigateToExceptionButton } from "~/components/exceptions/navigate-to-exception-button";
import { useExceptionsSelection } from "~/components/exceptions/use-exceptions-selection";
import { mapSelectedEntitiesToString } from "~/components/exceptions/utils";
import { ImpactUpdated } from "~/components/impact/Updated/impact-updated";

type ChecksConnection = NonNullable<
  GetComplianceControlChecksQuery["complianceControl"]["checks"]
>;
type ChecksEdge = NonNullable<ChecksConnection["edges"]>[0];
type ChecksNode = NonNullable<ChecksEdge["node"]>;
type QueryCheck = Extract<ChecksNode, { __typename: "QueryCheck" }>;

type ChecksProps = {
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function Checks({ availablePermissions }: ChecksProps) {
  const { space, controlMrn, frameworkMrn, control } = useControlContext();
  const [searchParams] = useSearchParams();
  const { handleFilterQuery, searchFilters } = useSearch();
  const [pageItems, setPageItems] = useState(INITIAL_PAGE_RANGE);
  const { frameworkId, controlId } = useParams();

  const theme = useTheme();
  const navigate = useNavigate();

  const { data, error, loading, fetchMore } =
    useGetComplianceControlChecksQuery({
      variables: {
        first: 50,
        input: {
          controlMrn,
          frameworkMrn,
          scopeMrn: space.mrn,
        },
        checksInput: {
          frameworkMrn,
          scopeMrn: space.mrn,
          orderBy: {
            field:
              (searchParams.get("field") as ChecksOrderField) ||
              ChecksOrderField.AssetsCount,
            direction:
              (searchParams.get("direction") as OrderDirection) ||
              OrderDirection.Desc,
          },
          query: searchFilters.join(","),
        },
      },
      initialFetchPolicy: "cache-and-network",
    });

  const { exceptionGroups } = useFetchExceptions({
    mrn: controlMrn,
    scopeMrn: space.mrn,
    types: [ExceptionType.Security],
  });

  const {
    isGroupChecked,
    isGroupIndeterminate,
    onGroupCheckChange,
    selectedEntities: selectedChecks,
    setSelectedEntities: setSelectedChecks,
    handleNodeClick,
    handleNodeChange,
    handleCancelClick,
  } = useExceptionsSelection();

  const {
    isRemovingException,
    isSettingException,
    handleSetExceptionModalOpen,
    handleSetExceptionModalClose,
    handleRemoveExceptionModalOpen,
    handleRemoveExceptionModalClose,
    handleRemoveException,
    handleSetException,
    loading: exceptionsLoading,
  } = useExceptions({
    onSetException: () => {
      setSelectedChecks([]);
    },
    onRemoveException: () => {
      setSelectedChecks([]);
    },
    scopeMrns: [space.mrn],
    queryMrns: mapSelectedEntitiesToString(selectedChecks),
    refetchQueries: [GetComplianceControlChecksDocument],
  });

  const { complianceControl } = data || {};
  const { checks } = complianceControl || {};
  const checksEdges = checks?.edges ?? [];
  const checksArray = checksEdges.flatMap((edge) =>
    edge.node?.__typename === "QueryCheck" ? edge.node : [],
  );

  if (loading && !checks) {
    return <Loading what="checks" />;
  }

  if (error) {
    return <LoadingFailed what="checks" />;
  }

  if (!checksArray.length) {
    return (
      <EmptyState
        contentType="control-checks"
        space={space}
        frameworkMrn={frameworkMrn}
      />
    );
  }

  const targetPaginatedGroup = checksArray
    .slice(pageItems.from, pageItems.to)
    .map((check) => ({
      mrn: check.mquery.mrn as string,
      exception: check.exception,
      groupId: check?.exception?.id,
    }));

  const hasApplyExceptionPermission = availablePermissions.includes(
    IamActions.ACTION_MONDOO_POLICY_EXTENDEDHUB_APPLYEXCEPTIONMUTATION,
  );

  const handlePendingReviewClick = (exception?: Maybe<{ id: string }>) => {
    if (!exception) return;
    navigate(
      `/space/compliance/${frameworkId}/controls/${controlId}/exceptions?spaceId=${space.id}&frameworkMrn=${frameworkMrn}&controlMrn=${controlMrn}&exceptionId=${exception?.id}`,
    );
  };

  const handleCheckClick = (check: QueryCheck) => {
    navigate(
      `/space/compliance/${frameworkId}/controls/${controlId}/check?spaceId=${space.id}&findingMrn=${check.mquery.mrn}`,
      { state: { control, frameworkMrn } },
    );
  };

  const tableHeaders: Header[] = [
    hasApplyExceptionPermission
      ? {
          id: "SELECT",
          label: (
            <Checkbox
              checked={isGroupChecked(targetPaginatedGroup)}
              indeterminate={isGroupIndeterminate(targetPaginatedGroup)}
              onChange={(event) =>
                onGroupCheckChange(event, targetPaginatedGroup)
              }
            />
          ),
          sortable: false,
        }
      : undefined,
    { id: "IMPACT", label: "Impact", sortable: false },
    { id: "NAME", label: "Check / Policy" },
    { id: "ASSETS_COUNT", label: "Assets" },
    { id: "COMPLETION", label: "Completion", sortable: true },
  ].flatMap((h) => h ?? []);

  return (
    <Box>
      {/* Checks does not currently have search enabled */}
      {/* <FilterBar
        searchId="compliance-checks"
        placeholder="compliance_checks"
        searchFilters={searchFilters}
        handleFilterQuery={handleFilterQuery}
      /> */}
      <ComplianceTable
        tableHeaders={tableHeaders}
        defaultActiveColumn={ChecksOrderField.Name}
        selection={selectedChecks}
        selectable={hasApplyExceptionPermission}
      >
        {checksArray.slice(pageItems.from, pageItems.to).map((check) => {
          const { state } = check;

          const isModified = state !== CheckState.Active;
          const isSnoozed = state === CheckState.Snoozed;
          const isDisabled = state === CheckState.Disabled;
          const isPendingException =
            check.exception?.reviewStatus === ReviewStatus.NotReviewed;

          return (
            <TableRow
              key={check?.mquery?.mrn}
              onClick={() => handleCheckClick(check)}
              // to={buildUrl(check)}
              sx={{
                "& .MuiTableCell-root": { fontWeight: 700 },
                ...(!check.completion && {
                  "& .MuiTableCell-root": {
                    color: "text.secondary",
                    fontWeight: 700,
                  },
                  "&:hover .MuiTableCell-root:before": {
                    background: "transparent",
                  },
                }),
              }}
            >
              {hasApplyExceptionPermission && (
                <TableCell>
                  <Checkbox
                    onClick={(e) => handleNodeClick(e)}
                    onChange={(e, changed) =>
                      handleNodeChange(e, changed, {
                        groupId: check.exception?.id,
                        mrn: check.mquery.mrn,
                        exception: !check.exception
                          ? null
                          : {
                              justification: check.exception?.justification,
                              action: check.exception?.action,
                            },
                      })
                    }
                    checked={Boolean(
                      selectedChecks.find(
                        (selectedCheck) =>
                          check.mquery.mrn === selectedCheck.mrn,
                      ),
                    )}
                  />
                </TableCell>
              )}
              <TableCell sx={{ opacity: isModified ? 0.5 : 1 }}>
                {check.mquery?.impact?.value !== undefined && (
                  <ImpactUpdated
                    isActive
                    impact={{
                      value: check.mquery?.impact?.value,
                      rating: check.mquery.impact.rating,
                    }}
                  />
                )}
              </TableCell>
              <TableCell sx={{ opacity: isModified ? 0.5 : 1 }}>
                <Box display="flex" alignItems="center" gap={1}>
                  {isSnoozed && (
                    <Chip label="Snoozed" icon={<MoonIcon />} size="small" />
                  )}
                  {isDisabled && (
                    <Chip label="Disabled" icon={<BlockIcon />} size="small" />
                  )}
                  {isPendingException && (
                    <NavigateToExceptionButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handlePendingReviewClick(check?.exception);
                      }}
                    />
                  )}
                  <Typography className="mquery-title" fontSize={14}>
                    {check?.mquery?.title}
                  </Typography>
                </Box>
                {DedupRefsNames(check.refs).map((ref) => (
                  <Typography
                    key={ref.mrn}
                    variant="caption"
                    color="text.secondary"
                    fontSize={10}
                  >
                    {ref.name}
                  </Typography>
                ))}
              </TableCell>
              <TableCell sx={{ opacity: isModified ? 0.5 : 1 }}>
                {check?.assets || check?.assets === 0 ? check.assets : "—"}
              </TableCell>
              <TableCell
                sx={{ position: "relative", opacity: isModified ? 0.5 : 1 }}
              >
                <CompletionCell
                  assets={check?.assets}
                  completionData={{
                    percent: check?.completion || 0,
                    height: theme.spacing(1),
                    tooltip: `Completion: ${check?.completion}%`,
                  }}
                />
              </TableCell>
            </TableRow>
          );
        })}
      </ComplianceTable>
      {selectedChecks.length > 0 && (
        <ExceptionsToolbar
          onCancel={handleCancelClick}
          onRemoveExceptionClick={handleRemoveExceptionModalOpen}
          onSetExceptionClick={handleSetExceptionModalOpen}
          selectedEntities={selectedChecks}
          totalCount={checksArray.length}
          target="check"
        />
      )}
      <ExceptionsModals
        isSetExceptionModalOpen={isSettingException}
        isRemoveExceptionModalOpen={isRemovingException}
        onRemoveExceptionModalClose={handleRemoveExceptionModalClose}
        onSetExceptionModalClose={handleSetExceptionModalClose}
        onSetExceptionModalSave={handleSetException}
        onRemoveExceptionModalSave={handleRemoveException}
        loading={exceptionsLoading}
        target="check"
        role="security"
        exceptionGroups={exceptionGroups}
        selectedEntities={selectedChecks}
      />
      <Pagination
        fetchMore={fetchMore}
        pageInfo={complianceControl?.checks?.pageInfo}
        totalCount={complianceControl?.checks?.totalCount || 0}
        setPageItems={setPageItems}
      />
    </Box>
  );
}

// we currently do not show the ref group of the check, so we ensure we only show unique ref names instead
export function DedupRefsNames(array: PolicyOrPackRef[]): PolicyOrPackRef[] {
  let unique: {
    [key: PolicyOrPackRef["mrn"]]: PolicyOrPackRef;
  } = {};
  array.forEach((item) => {
    unique[item.mrn] = item;
  });
  return Object.values(unique);
}
