import { Navigate, useSearchParams } from "react-router-dom";
import { LoadingFailedPage, LoadingPage } from "~/components/loading";
import { SpaceScope } from "~/hooks/useScope";
import { integrationDetailHref } from "~/lib/integrations";
import { useGetClientIntegrationQuery } from "~/operations";

export type IntegrationDetailMrnProps = {
  space: SpaceScope;
};

export function IntegrationDetailMrn({ space }: IntegrationDetailMrnProps) {
  const [searchParams] = useSearchParams();
  const integrationMrn = searchParams.get("integrationMrn");

  const { data, loading, error } = useGetClientIntegrationQuery({
    variables: { mrn: integrationMrn || "skip" },
    skip: !integrationMrn,
  });

  if (loading) {
    return <LoadingPage what="Integration" />;
  }

  const integration = data?.clientIntegration.integration;

  if (error || !integration) {
    return <LoadingFailedPage what="Integration" />;
  }

  return (
    <Navigate
      to={integrationDetailHref(integration.type, integration.mrn, space)}
      replace
    />
  );
}
