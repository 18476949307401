import { AddIcon } from "~/components/icons";
import { Button, ButtonProps } from "@mui/material";
import { Flex } from "~/components/Flex";
import { useFormContext } from "react-hook-form";
import { CreateWorkspaceFormInput } from "~/pages/space/Workspaces/components/CreateWorkspace/CreateWorkspacePage";

type AddSelectionRowProps = {
  onClick: ButtonProps["onClick"];
  disabled: boolean;
  showFormActions?: boolean;
};

export const AddSelectionRow = ({
  onClick,
  disabled,
  showFormActions,
}: AddSelectionRowProps) => {
  const { reset, formState } = useFormContext<CreateWorkspaceFormInput>();

  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Button
        variant="text"
        disabled={disabled}
        sx={{ color: (theme) => theme.palette.primary.light }}
        onClick={onClick}
        startIcon={<AddIcon />}
        data-testid="add-asset-selection-button"
      >
        ADD ASSET SELECTION
      </Button>
      {showFormActions && (
        <Flex gap={2}>
          <Button
            variant="outlined"
            color="primary"
            disabled={!formState.isDirty}
            onClick={() => {
              reset();
            }}
          >
            Discard changes
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={disabled}
          >
            Save Changes
          </Button>
        </Flex>
      )}
    </Flex>
  );
};
