import { Box, Grid2, Typography } from "@mui/material";
import { useColorMode } from "~/providers/color-mode";
import EmptyReporting from "~/images/empty-reporting.png";
import EmptyReportingLight from "~/images/empty-reporting-light.png";

export function NoReports() {
  const { mode } = useColorMode();

  return (
    <Grid2 container className="empty-reporting">
      <Grid2
        size={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 8,
        }}
      >
        <Box
          component="img"
          src={mode === "light" ? EmptyReportingLight : EmptyReporting}
          sx={{ width: "100%", maxWidth: 380, height: "auto" }}
        />
      </Grid2>
      <Grid2 size={12} sx={{ textAlign: "center" }}>
        <Typography
          variant="h5"
          component="h2"
          sx={{
            mb: 2,
            fontWeight: 700,
          }}
        >
          Time to create your first report!
        </Typography>
        <Typography color="text.secondary">
          Easily run a report from any Mondoo page: Just click the report icon!
          <br />
          All the reports you run appear on this page for you to download.
        </Typography>
      </Grid2>
    </Grid2>
  );
}
