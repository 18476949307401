import {
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AssetCheckException } from "~/operations";
import { formatPolicyName } from "~/pages/inventory/asset/asset-checks-tab";
import { Clamp } from "../clamp/clamp";
import { Space } from "~/lib/types";
import { ImpactUpdated } from "~/components/impact/Updated/impact-updated";

export const AssetCheckExceptionTable = ({
  assetId,
  exceptions,
  space,
}: {
  assetId: string | null;
  exceptions: AssetCheckException[];
  space: Space;
}) => {
  let navigate = useNavigate();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Result</TableCell>
          <TableCell>Check</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {exceptions.map((exception) => {
          const checkUrl = `/space/inventory/${assetId}/checks?spaceId=${space.id}&checkMrn=${exception.mrn}`;

          const handleClick = () => {
            navigate(checkUrl);
          };

          return (
            <TableRow sx={{ cursor: "pointer" }} onClick={handleClick}>
              <TableCell>
                <ImpactUpdated
                  impact={{
                    value: exception.riskScore ?? 0,
                    rating: exception.rating,
                  }}
                  isRiskScore
                />
              </TableCell>
              <TableCell>
                <Clamp>
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: 14,
                      lineHeight: "16px",
                      gap: 1,
                      fontWeight: 700,
                    }}
                    data-testid="check-id"
                  >
                    {exception?.title}
                  </Typography>
                  <List sx={{ p: 0 }}>
                    <ListItem
                      sx={{ p: 0 }}
                      key={formatPolicyName(exception.policyMrn, space.id)}
                    >
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        fontSize={10}
                      >
                        {formatPolicyName(exception.policyMrn, space.id)}
                      </Typography>
                    </ListItem>
                  </List>
                </Clamp>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
