import { Fragment } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Link,
  Button,
  Tooltip,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { Asset } from "~/pages/inventory/asset/types";
import {
  configurationTableSx,
  extractItemKey,
  extractItemValue,
} from "./asset-configuration-section";
import { OpenInNewIcon } from "./icons";
import { CopyText } from "./CopyText";
import { AssetOverviewReferenceTypeEnum } from "~/operations";
import { SpaceScope } from "~/hooks/useScope";
import { AssetIntegration } from "~/pages/inventory/asset";
import { IamActions } from "~/lib/iam";
import { isExternalLink } from "~/lib/isExternalLink";

type AssetOverviewGroup = Asset["overviewGroups"][0];

type OverviewGroups = {
  labelsGroup: AssetOverviewGroup[];
  otherGroups: AssetOverviewGroup[];
  externalLink?: AssetOverviewUrValue;
};

type AssetOverviewItem = AssetOverviewGroup["items"][0];

type AssetOverviewUrValue = Extract<
  AssetOverviewItem["values"][0],
  { __typename: "AssetOverviewURLValue" }
>;

const createAssetOverviewTimeValue = (
  key: string,
  name: string,
  timeValue: string,
) => {
  return {
    __typename: "AssetOverviewItem" as const,
    key,
    name,
    values: [{ __typename: "AssetOverviewTimeValue" as const, timeValue }],
  };
};

const createAssetOverviewUrlValue = (
  key: string,
  name: string,
  url: string,
  displayValue: string,
) => {
  return {
    __typename: "AssetOverviewItem" as const,
    key,
    name,
    values: [
      { __typename: "AssetOverviewURLValue" as const, url, displayValue },
    ],
  };
};

export type ConfigurationAssetOverviewProps = {
  asset: Asset;
  integration?: AssetIntegration;
  space: SpaceScope;
};

export function ConfigurationAssetOverview({
  asset,
  integration,
  space,
}: ConfigurationAssetOverviewProps) {
  const overviewGroups = asset.overviewGroups.filter(
    (g) => g.title !== "Platform overview",
  );

  const extractLink = (item: AssetOverviewItem) => {
    const value = item.values[0];
    switch (value.__typename) {
      case "AssetOverviewURLValue":
        return value.url;
      case "AssetOverviewReferenceValue":
        if (
          value.referenceType === AssetOverviewReferenceTypeEnum.Integration
        ) {
          return `/space/integrations/mrn?${space.params}&integrationMrn=${value.mrn}`;
        }
      default:
        return undefined;
    }
  };

  const { labelsGroup, externalLink, otherGroups } =
    asset.overviewGroups.reduce<OverviewGroups>(
      (acc, overviewGroup) => {
        const items = [...overviewGroup.items];
        const group = { ...overviewGroup, items };
        if (
          group.title.toLowerCase().includes("labels") ||
          group.title.toLowerCase().includes("tags")
        ) {
          acc.labelsGroup.push(group);
        } else if (group.key === "external-link") {
          if (group.items.length === 1 && group.items[0].values.length === 1) {
            const value = group.items[0].values[0];
            if (value.__typename === "AssetOverviewURLValue") {
              acc.externalLink = value;
            }
          }
        } else {
          if (group.key === "asset-overview-scan-overview-group") {
            // Ideally scan overview group would already include scan time values.
            // We're inserting them ourselves for now.
            // https://github.com/mondoohq/planning/issues/208
            const firstScanItem = asset.createdAt
              ? createAssetOverviewTimeValue(
                  "first-scanned-at",
                  "First scanned at",
                  asset.createdAt,
                )
              : undefined;
            const lastScanItem = asset.updatedAt
              ? createAssetOverviewTimeValue(
                  "last-scanned-at",
                  "Last scanned at",
                  asset.updatedAt,
                )
              : undefined;

            // Integration overview item doesn't have all necessary info for required features.
            // We're augmenting it ourselves for now.
            // https://github.com/mondoohq/planning/issues/235
            const integrationRefItem = items.find(
              (item) => item.key === "integration",
            );
            const integrationUrlItem =
              integrationRefItem && integration
                ? createAssetOverviewUrlValue(
                    "integration",
                    "Integration",
                    integration.href,
                    integration.name,
                  )
                : undefined;
            if (integrationRefItem && integrationUrlItem) {
              // replace the ref item with url item
              items.splice(
                items.indexOf(integrationRefItem),
                1,
                integrationUrlItem,
              );
            }

            group.items = [firstScanItem, lastScanItem, ...items].flatMap(
              (i) => i ?? [],
            );
            acc.otherGroups.push(group);
          }
        }
        return acc;
      },
      { labelsGroup: [], externalLink: undefined, otherGroups: [] },
    );

  if (labelsGroup.length === 0 && asset.labels.length > 0) {
    const items = asset.labels.map((label) => {
      return {
        key: label.key,
        value: label.value,
      };
    });

    labelsGroup.push({
      title: "labels",
      key: "labels",
      items: [
        {
          key: "labels",
          name: "labels",
          sources: null,
          values: [
            {
              dictValue: items,
              __typename: "AssetOverviewDictValue",
            },
          ],
        },
      ] as AssetOverviewItem[],
      __typename: "AssetOverviewGroup",
    });
  }

  const overviewPolicyResults = (
    <TableContainer>
      <Table
        sx={{ tableLayout: "fixed", "& tr:hover": { background: "inherit" } }}
      >
        {otherGroups.map((group) => {
          return (
            <Fragment key={group.key}>
              <TableHead sx={{ backgroundColor: "inherit", boxShadow: "none" }}>
                <TableRow
                  sx={{
                    "&:hover": {
                      pointerEvents: "none",
                      background: "inherit",
                    },
                  }}
                >
                  <TableCell
                    colSpan={2}
                    sx={{
                      pl: 0,
                      pb: 1,
                      borderBottom: (theme) =>
                        `1px solid ${theme.palette.background.lightest}`,
                      textTransform: "uppercase",
                    }}
                    className="table-title"
                  >
                    {group.title}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={configurationTableSx}>
                {group?.items.map((item) => {
                  const key = extractItemKey(item);
                  const value = extractItemValue(item);
                  // We don't display integration links to Viewer roles
                  const link =
                    key !== "Integration" ||
                    space.iamActions.includes(
                      IamActions.INTEGRATIONS_INTEGRATIONSMANAGER_UPDATE,
                    )
                      ? extractLink(item)
                      : undefined;
                  return (
                    <Fragment key={item.key}>
                      <TableRow>
                        <TableCell className={"key width-50"} sx={{ pl: 0 }}>
                          {key}
                        </TableCell>
                        {link && (
                          <TableCell className={"width-50"} sx={{ pl: 0 }}>
                            <CopyText value={value}>
                              <Link
                                target={
                                  isExternalLink(link) ? "_blank" : "_self"
                                }
                                rel="noreferrer"
                                to={link}
                                component={RouterLink}
                              >
                                {value}
                              </Link>
                            </CopyText>
                          </TableCell>
                        )}
                        {!link && (
                          <TableCell className={"width-50"} sx={{ pl: 0 }}>
                            <CopyText>{value}</CopyText>
                          </TableCell>
                        )}
                      </TableRow>
                    </Fragment>
                  );
                })}
              </TableBody>
            </Fragment>
          );
        })}
        {labelsGroup.map((group) => {
          const value = group.items[0].values[0];
          const dictValue =
            value.__typename === "AssetOverviewDictValue"
              ? value.dictValue
              : undefined;

          return (
            <Fragment key={group.key}>
              <TableHead sx={{ backgroundColor: "inherit", boxShadow: "none" }}>
                <TableRow
                  sx={{
                    "&:hover": {
                      pointerEvents: "none",
                      background: "inherit",
                    },
                  }}
                >
                  <TableCell
                    colSpan={2}
                    sx={{
                      pl: 0,
                      pb: 1,
                      borderBottom: (theme) =>
                        `1px solid ${theme.palette.background.lightest}`,
                      textTransform: "uppercase",
                    }}
                  >
                    {group.title}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={configurationTableSx}>
                {dictValue?.map((value) => {
                  return (
                    <Fragment key={value.key}>
                      <TableRow>
                        <TableCell className={"key width-50"} sx={{ pl: 0 }}>
                          {value.key}
                        </TableCell>
                        <Tooltip title={value.value} placement="top" arrow>
                          <TableCell className={"width-50"} sx={{ pl: 0 }}>
                            <CopyText>{value.value}</CopyText>
                          </TableCell>
                        </Tooltip>
                      </TableRow>
                    </Fragment>
                  );
                })}
              </TableBody>
            </Fragment>
          );
        })}
        {externalLink && (
          <TableBody sx={configurationTableSx}>
            <TableRow>
              <TableCell className={"width-100"} sx={{ pl: 0 }}>
                <Button
                  href={externalLink.url}
                  target="_blank"
                  variant="contained"
                  color="primary"
                  endIcon={<OpenInNewIcon />}
                >
                  {externalLink.displayValue}
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );

  const overviewPolicyNoResults = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        px: 2,
        pt: 2,
      }}
    >
      <Typography variant="body2" color="text.secondary">
        No Data
      </Typography>
    </Box>
  );

  return (
    <Box>
      {overviewGroups.length > 0 || labelsGroup.length > 0
        ? overviewPolicyResults
        : overviewPolicyNoResults}
    </Box>
  );
}
