import { Fragment, useState } from "react";
import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";
import {
  ConfigurationItemBase,
  ConfigurationList,
  ConfigurationPaper,
} from "~/components/configuration-items";
import { useSpaceSettingsOutletContext } from "../space-settings";
import { IamActions } from "~/lib/iam";
import { useViewer } from "~/providers/viewer";
import { DangerZone, DangerZoneItem } from "~/components/danger-zone";
import {
  SpaceSettingsInput,
  useDeleteAssetsMutation,
  useDeleteWorkspacesMutation,
  useLoadSpaceQuery,
} from "~/operations";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { SpaceNameSettings } from "~/pages/space-settings/name-and-description-settings";
import { AssetSelectionSettings } from "~/pages/space-settings/asset-selection-settings";
import { LoadingFailedPage, LoadingPage } from "~/components/loading";
import { useScope } from "~/hooks/useScope";

type SpaceSettingsState = {
  nameErrMsg: string;
  terminatedErrMsg: string;
  inactiveErrMsg: string;
  endOfLifeWarningError: string;
  platformVulnError: string;
  isDeleting: boolean;
  isDeletingAssets: boolean;
};

export type GeneralSpaceSettingsProps = {};

export function GeneralSpaceSettings({}: GeneralSpaceSettingsProps) {
  let navigate = useNavigate();
  const { workspaceScope } = useScope();
  const { enqueueSnackbar } = useSnackbar();
  const { space, availablePermissions } = useSpaceSettingsOutletContext();
  const { deleteSpace, updateSpace } = useViewer();
  const [deleteAssets] = useDeleteAssetsMutation();
  const [deleteWorkspaces] = useDeleteWorkspacesMutation();

  const { data, loading, error } = useLoadSpaceQuery({
    variables: { spaceMrn: space.mrn },
  });

  const settings = data?.space?.settings;

  const garbageCollectAssetsConfiguration = {
    enable: settings?.garbageCollectAssetsConfiguration.enable,
    afterDays: settings?.garbageCollectAssetsConfiguration.afterDays,
  };
  const terminatedAssetsConfiguration = {
    cleanup: settings?.terminatedAssetsConfiguration.cleanup,
  };
  const unusedServiceAccountsConfiguration = {
    cleanup: settings?.unusedServiceAccountsConfiguration.cleanup,
  };
  const platformVulnerabilityConfiguration = {
    enable: settings?.platformVulnerabilityConfiguration.enable,
  };

  const eolAssetsConfiguration = {
    enable: settings?.eolAssetsConfiguration.enable,
    monthsInAdvance: settings?.eolAssetsConfiguration.monthsInAdvance,
  };

  const settingsInput: SpaceSettingsInput = {
    garbageCollectAssetsConfiguration,
    terminatedAssetsConfiguration,
    unusedServiceAccountsConfiguration,
    platformVulnerabilityConfiguration,
    eolAssetsConfiguration,
  };

  const termAssetsSetting = terminatedAssetsConfiguration?.cleanup
    ? 1
    : "Never";
  const inactiveAssetsSetting = garbageCollectAssetsConfiguration?.enable
    ? garbageCollectAssetsConfiguration.afterDays
    : "Never";

  const platformVulnerabilitySetting =
    platformVulnerabilityConfiguration?.enable ? "enabled" : "disabled";

  const [state, setState] = useState<SpaceSettingsState>({
    nameErrMsg: "",
    terminatedErrMsg: "",
    inactiveErrMsg: "",
    endOfLifeWarningError: "",
    platformVulnError: "",
    isDeleting: false,
    isDeletingAssets: false,
  });
  const [vulnConfigUpdating, setVulnConfigUpdating] = useState<boolean>(false);

  const mergeState = (params: Partial<SpaceSettingsState>) => {
    return setState((prevState) => ({
      ...prevState,
      ...params,
    }));
  };

  const handleChangeTermAssets: SelectProps<
    string | number
  >["onChange"] = async (e) => {
    const cleanup = e.target.value !== "Never";
    const settings = {
      ...settingsInput,
      terminatedAssetsConfiguration: { cleanup },
    };
    try {
      await updateSpace({
        variables: {
          input: {
            name: space.name,
            mrn: space.mrn,
            settings,
          },
        },
      });
      mergeState({ terminatedErrMsg: "" });
    } catch (error) {
      mergeState({ terminatedErrMsg: "Cannot store setting" });
    }
  };

  const handleChangeInactiveAssets: SelectProps<
    string | number
  >["onChange"] = async (e) => {
    const enable = e.target.value !== "Never";
    const afterDays =
      enable && typeof e.target.value === "number" ? e.target.value : undefined;
    const settings = {
      ...settingsInput,
      garbageCollectAssetsConfiguration: { enable, afterDays },
    };

    try {
      await updateSpace({
        variables: {
          input: {
            name: space.name,
            mrn: space.mrn,
            settings,
          },
        },
      });
      mergeState({ inactiveErrMsg: "" });
    } catch (error) {
      mergeState({ inactiveErrMsg: "Cannot store setting" });
    }
  };

  const handleChangeEndOfLifeWarning: SelectProps<
    string | number
  >["onChange"] = async (e) => {
    const enable = e.target.value !== "disable";
    const monthsInAdvance =
      enable && typeof e.target.value === "number" ? e.target.value : undefined;
    const settings = {
      ...settingsInput,
      eolAssetsConfiguration: { enable, monthsInAdvance },
    };

    try {
      await updateSpace({
        variables: {
          input: {
            name: space.name,
            mrn: space.mrn,
            settings,
          },
        },
      });
      mergeState({ endOfLifeWarningError: "" });
    } catch (error) {
      mergeState({
        endOfLifeWarningError: "Cannot store end-of-life warnings setting",
      });
    }
  };

  const handleChangeVulnerabilityConfiguration: SelectProps<string>["onChange"] =
    async (e) => {
      const enable = e.target.value === "enabled";
      const settings = {
        ...settingsInput,
        platformVulnerabilityConfiguration: { enable },
      };

      setVulnConfigUpdating(true);

      try {
        await updateSpace({
          variables: {
            input: {
              name: space.name,
              mrn: space.mrn,
              settings,
            },
          },
          awaitRefetchQueries: true,
          onCompleted: () => {
            setVulnConfigUpdating(false);
          },
        });
        mergeState({ platformVulnError: "" });
      } catch (error) {
        mergeState({ platformVulnError: "Cannot store setting" });
        setVulnConfigUpdating(false);
      }
    };

  const handleDeleteAllAssets = async (): Promise<void> => {
    mergeState({ isDeletingAssets: true });
    try {
      await deleteAssets({
        variables: { input: { spaceMrn: space.mrn, assetMrns: [] } },
      });
      enqueueSnackbar(`Successfully deleted all assets within space`, {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(`Failed to delete all assets within space`, {
        variant: "error",
      });
    } finally {
      mergeState({ isDeletingAssets: false });
    }
  };

  const handleDeleteSpace = async (): Promise<void> => {
    mergeState({ isDeleting: true });
    try {
      await deleteSpace({ variables: { spaceMrn: space.mrn } });
      enqueueSnackbar(`Successfully deleted space`, { variant: "success" });
      navigate(
        `/organization/spaces?organizationId=${data?.space?.organization?.id}`,
      );
    } catch (error) {
      mergeState({ isDeleting: false });
      enqueueSnackbar(`Failed to delete space: ${space.name}`, {
        variant: "error",
      });
    }
  };

  const handleDeleteWorkpace = async (): Promise<void> => {
    mergeState({ isDeleting: true });
    try {
      const mrns = workspaceScope ? [workspaceScope.mrn] : [];
      await deleteWorkspaces({ variables: { input: { mrns } } });
      enqueueSnackbar(`Successfully deleted workspace`, { variant: "success" });
      navigate(`/space/workspaces?spaceId=${space.id}`);
    } catch (error) {
      mergeState({ isDeleting: false });
      enqueueSnackbar(`Failed to delete workspace: ${workspaceScope?.name}`, {
        variant: "error",
      });
    }
  };

  const deleteSpacePermission = availablePermissions?.includes(
    IamActions.CAPTAIN_DELETESPACE,
  );
  const updateSpacePermission = availablePermissions?.includes(
    IamActions.CAPTAIN_UPDATESPACE,
  );
  const deleteAssetsPermission = availablePermissions?.includes(
    IamActions.ASSETS_DELETEASSETS,
  );

  if (loading) {
    return <LoadingPage what="Space" />;
  }

  if (error || !data?.space) {
    return <LoadingFailedPage what="Space" />;
  }

  return (
    <Fragment>
      {workspaceScope && <AssetSelectionSettings />}
      <SpaceNameSettings />
      {!workspaceScope && (
        <ConfigurationPaper>
          <ConfigurationList title="General">
            <ConfigurationItemBase
              primary="Remove terminated assets"
              secondary="Automatically remove terminated assets."
            >
              <FormControl fullWidth>
                <Select
                  value={termAssetsSetting}
                  onChange={handleChangeTermAssets}
                  disabled={!updateSpacePermission}
                  error={Boolean(state.terminatedErrMsg)}
                >
                  <MenuItem value={"Never"}>Never</MenuItem>
                  <MenuItem value={1}>24 Hours</MenuItem>
                </Select>
                {state.terminatedErrMsg && (
                  <FormHelperText error>
                    {state.terminatedErrMsg}
                  </FormHelperText>
                )}
              </FormControl>
            </ConfigurationItemBase>
            <ConfigurationItemBase
              primary="Remove inactive assets"
              secondary="Automatically remove assets that haven't updated in the time specified."
            >
              <FormControl fullWidth>
                <Select
                  value={inactiveAssetsSetting}
                  onChange={handleChangeInactiveAssets}
                  disabled={!updateSpacePermission}
                  error={Boolean(state.inactiveErrMsg)}
                >
                  <MenuItem value={"Never"}>Never</MenuItem>
                  <MenuItem value={3}>3 days</MenuItem>
                  <MenuItem value={7}>7 days</MenuItem>
                  <MenuItem value={14}>14 days</MenuItem>
                  <MenuItem value={30}>30 days</MenuItem>
                  <MenuItem value={60}>60 days</MenuItem>
                  <MenuItem value={90}>90 days</MenuItem>
                </Select>
                {state.inactiveErrMsg && (
                  <FormHelperText error>{state.inactiveErrMsg}</FormHelperText>
                )}
              </FormControl>
            </ConfigurationItemBase>
            <ConfigurationItemBase
              primary="End-of-life warnings"
              secondary="Define whether (and how long in advance) Mondoo displays warnings about assets reaching the end of their supported lifecycle."
            >
              <FormControl fullWidth>
                <Select
                  value={
                    eolAssetsConfiguration.enable
                      ? eolAssetsConfiguration.monthsInAdvance
                      : "disable"
                  }
                  onChange={handleChangeEndOfLifeWarning}
                  disabled={!updateSpacePermission}
                  error={Boolean(state.endOfLifeWarningError)}
                  defaultValue={6}
                >
                  <MenuItem value={"disable"}>Disable</MenuItem>
                  <MenuItem value={12}>1 year</MenuItem>
                  <MenuItem value={6}>6 months (default)</MenuItem>
                  <MenuItem value={3}>3 months</MenuItem>
                  <MenuItem value={1}>1 month</MenuItem>
                </Select>
                {state.inactiveErrMsg && (
                  <FormHelperText error>{state.inactiveErrMsg}</FormHelperText>
                )}
              </FormControl>
            </ConfigurationItemBase>
            <ConfigurationItemBase
              primary="Vulnerability scanning"
              secondary="Enable or disable vulnerability scanning. Disabling this setting is not recommended and is for advanced users only."
            >
              <FormControl fullWidth>
                {vulnConfigUpdating ? (
                  <SelectSpinner />
                ) : (
                  <>
                    <Select
                      id="platformVulnerabilityConfiguration"
                      value={platformVulnerabilitySetting}
                      onChange={handleChangeVulnerabilityConfiguration}
                      disabled={!updateSpacePermission}
                      error={Boolean(state.platformVulnError)}
                    >
                      <MenuItem value={"enabled"}>Enabled</MenuItem>
                      <MenuItem value={"disabled"}>Disabled</MenuItem>
                    </Select>
                    {state.platformVulnError && (
                      <FormHelperText error>
                        {state.platformVulnError}
                      </FormHelperText>
                    )}
                  </>
                )}
              </FormControl>
            </ConfigurationItemBase>
          </ConfigurationList>
        </ConfigurationPaper>
      )}
      {((deleteAssetsPermission && !workspaceScope) ||
        deleteSpacePermission) && (
        <DangerZone>
          {!workspaceScope && deleteAssetsPermission && (
            <DangerZoneItem
              primary="Delete all assets"
              secondary="Deleting assets permanently erases all the associated data, including reports."
              confirm="I confirm that I want to delete all the assets in this space and all their related information."
              action="Delete"
              onClick={handleDeleteAllAssets}
              isDeleting={state.isDeletingAssets}
            />
          )}
          {!workspaceScope && deleteSpacePermission && (
            <DangerZoneItem
              primary="Delete space"
              secondary="Deleting a space permanently erases all the associated data, including assets and reports."
              confirm="I confirm that I want to delete this space, including all its related content."
              action="Delete"
              onClick={handleDeleteSpace}
              isDeleting={state.isDeleting}
            />
          )}
          {workspaceScope && deleteSpacePermission && (
            <DangerZoneItem
              primary="Delete workspace"
              secondary="Deleting this workspace will erase it from this space, for everyone. Anything in your space, such as your assets or policies, will not be affected. This action cannot be undone."
              confirm="I confirm that I want to delete this workspace."
              action="Delete"
              onClick={handleDeleteWorkpace}
              isDeleting={state.isDeleting}
            />
          )}
        </DangerZone>
      )}
    </Fragment>
  );
}

const SelectSpinner = () => {
  return (
    <Box
      sx={{
        border: "1px solid",
        borderColor: "text.disabled",
        padding: 1.5,
        borderRadius: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress size={24} />
    </Box>
  );
};
