import { Fragment, useState } from "react";
import {
  Alert,
  AlertTitle,
  alpha,
  Box,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { ContentFlag } from "~/components/content-flag";
import { FormatDate } from "~/lib/date";
import {
  AccessTimeIcon,
  DeleteIcon,
  EditIcon,
  PersonIcon,
} from "~/components/icons";
import { ActionMenu } from "~/components/action-menu";
import { IamActions } from "~/lib/iam";
import {
  ActionType,
  GetClientIntegrationQuery,
  TestIamActionsQuery,
  useTriggerActionLazyQuery,
} from "~/operations";
import { useSnackbar } from "notistack";
import { Space } from "~/lib/types";
import { capitalizeFirstLetter } from "~/lib/helpers";
import { LoadingButton } from "~/components/loading-button";
import { ticketingIntegrationTypes } from "~/components/integrations/integration-counts";

type Props = {
  space: Space;
  integration: GetClientIntegrationQuery["clientIntegration"]["integration"];
  availablePermissions: TestIamActionsQuery["testIamActions"];
  integrationTypeId: string | undefined;
  scanAction?: ActionType;
  actions?: {
    deleteIntegration: () => void;
    editIntegration: () => void;
  };
};

export const IntegrationHeader = ({
  integration,
  actions,
  availablePermissions,
  integrationTypeId,
  scanAction = ActionType.RunScan,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [triggeringScan, setTriggeringScan] = useState(false);
  const [triggerClientIntegrationScan] = useTriggerActionLazyQuery({
    variables: { input: { mrn: integration.mrn, type: scanAction } },
  });
  const [triggerClientIntegrationPause] = useTriggerActionLazyQuery({
    variables: { input: { mrn: integration.mrn, type: ActionType.Pause } },
  });
  const [triggerClientIntegrationEnable] = useTriggerActionLazyQuery({
    variables: { input: { mrn: integration.mrn, type: ActionType.Unpause } },
  });
  // expose the error buried in the integration messages if it exists
  const hasError =
    integration.status !== "ACTIVE" &&
    integration.messages?.find((x) => x.status.toLowerCase() === "error");

  const actionType =
    scanAction === ActionType.RunExport
      ? "export"
      : ActionType.RunImport
        ? "import"
        : "scan";

  const triggerScan = async () => {
    if (triggeringScan) {
      return;
    }
    setTriggeringScan(true);
    try {
      const { error } = await triggerClientIntegrationScan();
      if (error) {
        throw new Error(error.message);
      }

      enqueueSnackbar(`Successfully scheduled integration ${actionType}`, {
        variant: "success",
      });
    } catch (error) {
      const action = actionType === "scan" ? "scan of integration" : actionType;
      let msg = `Failed to schedule ${action}`;
      if (error instanceof Error) {
        msg = `${msg}: ${error.message}`;
      }
      enqueueSnackbar(msg, {
        variant: "error",
      });
    } finally {
      setTriggeringScan(false);
    }
  };

  const hasIntegrationTriggerActionPermission = availablePermissions?.includes(
    IamActions.INTEGRATIONS_INTEGRATIONSMANAGER_TRIGGERACTION,
  );

  const hasIntegrationUpdatePermission = availablePermissions?.includes(
    IamActions.INTEGRATIONS_INTEGRATIONSMANAGER_UPDATE,
  );

  const hasIntegrationDeletePermission = availablePermissions?.includes(
    IamActions.INTEGRATIONS_INTEGRATIONSMANAGER_DELETE,
  );

  const isTriggerSupported = !ticketingIntegrationTypes.includes(
    integration.type,
  );

  const SHOW_TRIGGER_STATES = ["ACTIVE", "NOT_READY", "ERROR"];

  const showTrigger =
    SHOW_TRIGGER_STATES.includes(integration.status) &&
    hasIntegrationTriggerActionPermission &&
    isTriggerSupported;

  // TODO: this should really be come from the server-side
  const showAction = isTriggerSupported;

  const formatStatus = (status: string) => {
    if (!status) return null;
    switch (status.toLowerCase()) {
      case "not_ready":
        return "pending";
      default:
        return status;
    }
  };

  const unpauseScanning = async () => {
    try {
      const { error } = await triggerClientIntegrationEnable();
      if (error) {
        throw new Error(error.message);
      }
      const action = actionType === "scan" ? "scanning" : actionType;
      const msg = `Successfully enabled integration ${action}`;
      enqueueSnackbar(msg, {
        variant: "success",
      });
    } catch (error) {
      const action =
        actionType === "scan" ? "scanning on integration" : actionType;
      let msg = `Failed to enable ${action}`;
      if (error instanceof Error) {
        msg = `${msg}: ${error.message}`;
      }
      enqueueSnackbar(msg, {
        variant: "error",
      });
    }
  };

  const pauseScanning = async () => {
    if (integration.status === "PAUSED") {
      return unpauseScanning();
    }
    try {
      const { error } = await triggerClientIntegrationPause();
      if (error) {
        throw new Error(error.message);
      }
      const action = actionType === "scan" ? "scanning" : actionType;
      enqueueSnackbar(`Successfully paused integration ${action}`, {
        variant: "success",
      });
    } catch (error) {
      const action =
        actionType === "scan" ? "scanning on integration" : actionType;
      let msg = `Failed to pause ${action}`;
      if (error instanceof Error) {
        msg = `${msg}: ${error.message}`;
      }
      enqueueSnackbar(msg, {
        variant: "error",
      });
    }
  };
  let actionMenu = [
    {
      label: "Copy MRN",
      action: () => navigator.clipboard.writeText(`${integration.mrn}`),
      dataName: `${integrationTypeId}-copyMrn`,
    },
  ];

  if (hasIntegrationTriggerActionPermission) {
    actionMenu = [
      {
        label: "Copy MRN",
        action: () => navigator.clipboard.writeText(`${integration.mrn}`),
        dataName: `${integrationTypeId}-copyMrn`,
      },
    ];

    if (!ticketingIntegrationTypes.includes(integration.type)) {
      const action =
        actionType === "scan"
          ? "Scanning"
          : `${capitalizeFirstLetter(actionType)}s`;
      actionMenu.push({
        label:
          integration.status != "PAUSED"
            ? `Pause ${action}`
            : `Enable ${action}`,
        action: () => pauseScanning(),
        dataName: `${integrationTypeId}-pause`,
      });
    }
  }

  return (
    <Box>
      <Grid container>
        {hasError && (
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Alert severity="error" variant="outlined">
              <AlertTitle>Error</AlertTitle>
              {capitalizeFirstLetter(hasError.message)}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12} md>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              id={`${integrationTypeId}-integration-name`}
              variant="h4"
              fontWeight={700}
              sx={{ display: "inline", pr: 2, overflowWrap: "anywhere" }}
            >
              {integration.name}
            </Typography>
            <ContentFlag
              flag={formatStatus(integration.status)}
              color={integration.status}
            />
          </Box>

          <Grid container columnGap={2} sx={{ mb: { sm: 2, md: 0 } }}>
            {!ticketingIntegrationTypes.includes(integration.type) && (
              <Grid item xs={12} sm="auto">
                <AccessTimeIcon
                  sx={{
                    fontSize: 16,
                    height: 28,
                    color: "text.secondary",
                    verticalAlign: "middle",
                  }}
                />
                <Typography
                  display="inline"
                  variant="caption"
                  color="text.secondary"
                  sx={{ ml: 0.75 }}
                >
                  {/* Last scanned: April 05, 10:10am */}
                  Last {`${actionType === "scan" ? "scann" : actionType}ed`}:{" "}
                  {FormatDate(integration.lastCheckin)}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} sm="auto">
              {integration.createdBy?.name !== "" && (
                <Fragment>
                  <PersonIcon
                    sx={{
                      fontSize: 16,
                      height: 28,
                      color: "text.secondary",
                      verticalAlign: "middle",
                    }}
                  />
                  <Typography
                    display="inline"
                    variant="caption"
                    color="text.secondary"
                    sx={{ ml: 0.75 }}
                  >
                    Created by: {integration.createdBy?.name}
                  </Typography>
                </Fragment>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          alignItems="center"
          justifyContent="space-between"
          sx={{
            color: "text.secondary",
            mb: 1.625,
          }}
          xs={12}
          md={5}
        >
          <Grid item xs display="flex" alignItems="center" justifyContent="end">
            {hasIntegrationUpdatePermission && (
              <IconButton
                size="large"
                onClick={actions?.editIntegration}
                sx={{ mx: 0.5 }}
              >
                <EditIcon />
              </IconButton>
            )}
            {hasIntegrationDeletePermission && (
              <IconButton
                size="large"
                sx={{
                  mx: 0.5,
                  color: (theme) => theme.palette.error.main,
                  "&:hover": {
                    backgroundColor: (theme) =>
                      alpha(theme.palette.error.main, 0.15),
                  },
                }}
                onClick={actions?.deleteIntegration}
              >
                <DeleteIcon />
              </IconButton>
            )}

            {showAction && (
              <ActionMenu
                id={`${integrationTypeId}-action-menu`}
                actions={actionMenu}
              />
            )}

            {/* We only show the divider if anything exists to the right of it */}
            {/* Scan Trigger only visible when user has permission and status is active or warning */}
            {showTrigger && (
              <Fragment>
                <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
                <LoadingButton
                  buttonText="Schedule Now"
                  loading={triggeringScan}
                  onClick={() => triggerScan()}
                  data-name={`${integrationTypeId}-triggerscan`}
                  sx={{
                    position: "relative",
                    color: "common.white",
                    background:
                      "linear-gradient(133.55deg, #9147FF 0%, #4C35E8 100%)", //<--- palette breaking
                    ml: 1,
                  }}
                />
              </Fragment>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
