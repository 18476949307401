import {
  useState,
  useEffect,
  useCallback,
  useMemo,
  ReactNode,
  SyntheticEvent,
} from "react";
import { ApolloError } from "@apollo/client";
import { omit, map, get } from "lodash";
import { useSnackbar } from "notistack";
import {
  Obj,
  PAGE,
  TabOption,
  FieldOption,
  FilterOption,
  getPageIndexes,
  PaginationOptions,
  rowsPerPageOptions,
  SecurityGradientIcon,
  ComplianceGradientIcon,
  BestPracticesGradientIcon,
} from "~/components/ui-library";
import {
  Access,
  Policy,
  Category,
  CatalogType,
  OrderDirection,
  ContentPlatform,
  ContentSearchInput,
  useAddPolicyMutation,
  useAddQueryPackMutation,
  useDeletePolicyMutation,
  useLoadPlatformsLazyQuery,
  useLoadCategoriesLazyQuery,
  useSearchPolicyLazyQuery,
  useSearchQueryPackLazyQuery,
  ContentSearchResultItemOrder,
  ContentSearchResultItemOrderField,
  SearchPolicyDocument,
  QueryPackAddInput,
} from "~/operations";
import { useParams } from "~/hooks";
import { parseBoolean, updateQueryString } from "~/utils";
import { Space } from "~/lib/types";

// Facets
interface Facets extends ContentSearchInput {
  query: string;
  namespace: string;
  catalogType: CatalogType;
  // accessLevel: Access
  // trustLevel: TrustLevel
  categories: string[];
  platforms: string[];
  orderBy: ContentSearchResultItemOrder;
  limit: number;
  after: string;
  assignedOnly?: boolean;
}

const defaultFacets: Facets = {
  query: "",
  namespace: "",
  catalogType: CatalogType.Policy,
  // accessLevel: Access.All,
  // trustLevel: TrustLevel.All,
  categories: [],
  platforms: [],
  orderBy: {
    direction: OrderDirection.Asc,
    field: ContentSearchResultItemOrderField["Name"],
  },
  // first: PAGE_SIZE,
  // ToDo: limit is first ?
  limit: 500,
  after: "",
  scopeMrn: "",
  contentMrns: [],
  includePublic: true,
  includePrivate: true,
  // @ts-ignore
  assignedOnly: "",
};

// State
const registriesTabOptions: TabOption[] = [
  {
    label: "Policies",
    value: CatalogType.Policy,
  },
  {
    label: "Query Packs",
    value: CatalogType.Querypack,
  },
];
const sortByOptions = [
  {
    name: "Name (asc)",
    value: OrderDirection.Asc,
  },
  {
    name: "Name (des)",
    value: OrderDirection.Desc,
  },
];

enum Filters {
  Namespace = "namespace",
  AccessLevel = "accessLevel",
  TrustLevel = "trustLevel",
  Categories = "categories",
  Platforms = "platforms",
  AssignedOnly = "assignedOnly",
}
const defaultFilterOptions: FilterOption[] = [
  {
    name: Filters.AssignedOnly,
    label: "Only Show Enabled Policies",
    options: ["none", "true", "false"],
    value: "",
    type: "select",
  },
  // {
  //   name: Filters.AccessLevel,
  //   label: "Access Level",
  //   options: Object.values(Access),
  //   value: defaultFacets.accessLevel,
  //   multiple: false,
  //   type: "select",
  // },
  // {
  //   name: Filters.TrustLevel,
  //   label: "Trust Level",
  //   options: Object.values(TrustLevel),
  //   value: defaultFacets.trustLevel,
  //   multiple: false,
  //   type: "select",
  // },
  {
    name: Filters.Categories,
    label: "Categories",
    // options will be populated from useCategoriesLazyQuery
    options: [],
    value: defaultFacets.categories,
    multiple: true,
    type: "select",
  },
  {
    name: Filters.Platforms,
    label: "Platforms",
    // options will be populated from usePlatformsLazyQuery
    options: [],
    value: defaultFacets.platforms,
    multiple: true,
    type: "select",
  },
];

type State = {
  isReady: boolean;
  isQuerypack: boolean;
  page: number;
  rowsPerPage: number;
  from: number;
  to: number;
  paginationText: string;
};
const defaultState: State = {
  isReady: false,
  isQuerypack: false,
  page: PAGE,
  rowsPerPage: rowsPerPageOptions[0],
  ...getPageIndexes({
    page: PAGE,
    rowsPerPage: rowsPerPageOptions[0],
  }),
  paginationText: "Showing 1-10 Policies of 0",
};

export type UseRegistriesReturnProps = {
  assignInProgress: string[];
  loading: boolean;
  refreshing: boolean;
  error: ApolloError | undefined;
  filtersLoading: boolean;
  filtersErrors: string[] | undefined;
  filterOptions: FilterOption[];
  sortByOptions: FieldOption[];
  registriesTabOptions: TabOption[];
  platforms: ContentPlatform[];
  categoryOptions: { name: string; text: string; icon: ReactNode }[];
  registries: Policy[];
  paginatedRegistries: Policy[];
  noPolicies: boolean;
  facets: Facets;
  state: State;
  filters: Obj;
  onFacetsChange: (value: Partial<Facets>) => void;
  onStateChange: (value: Partial<State>) => void;
  onChangeBreadcrumb: () => void;
  onPaginationChange: (value: PaginationOptions) => void;
  onAddPolicyMutation: (value: QueryPackAddInput) => Promise<any>;
  onAddQueryPackMutation: (value: QueryPackAddInput) => Promise<any>;
  onChangeAssignHandler: () => Promise<any>;
  onDeletePolicyMutation: (
    e: SyntheticEvent,
    {
      policyMrn,
    }: {
      policyMrn: string;
    },
  ) => void;
};

export const registriesByCategoryOrder = [
  {
    name: "best-practices",
    icon: <BestPracticesGradientIcon fontSize="large" />,
  },
  { name: "security", icon: <SecurityGradientIcon fontSize="large" /> },
  { name: "compliance", icon: <ComplianceGradientIcon fontSize="large" /> },
];

/**
 * API-Calls should happen only when `state.isReady` is `true`,
 *  as NextJS queryString not available on initialMount
 */
const useRegistries = ({
  space,
  isQuerypack = false,
}: {
  space: Space;
  isQuerypack?: boolean;
}): UseRegistriesReturnProps => {
  defaultFacets.scopeMrn = space.mrn;
  defaultFacets.catalogType = isQuerypack
    ? CatalogType.Querypack
    : CatalogType.Policy;
  const { enqueueSnackbar } = useSnackbar();
  const { router, facetsFromQueryString } = useParams(defaultFacets);
  const [facets, setFacets] = useState<Facets>(defaultFacets);
  const [state, setState] = useState<State>(defaultState);

  const onFacetsChange = useCallback((value: Partial<Facets>) => {
    setFacets((v) => ({ ...v, ...value }));
  }, []);

  const onStateChange = useCallback((value: Partial<State>) => {
    setState((v) => ({ ...v, ...value }));
  }, []);

  const onPaginationChange = useCallback(
    ({
      page = defaultState.page,
      rowsPerPage = defaultState.rowsPerPage,
      from = defaultState.from,
      to = defaultState.to,
      count = 0,
    }: PaginationOptions) => {
      onStateChange({
        page,
        rowsPerPage,
        from,
        to,
        paginationText:
          `Showing ${Number(from) + 1}-${to}` +
          (state.isQuerypack ? ` Query Packs` : ` Policies`) +
          ` of ${count}`,
      });
    },
    [state.isQuerypack],
  );

  const [
    useCategoriesQuery,
    {
      loading: categoriesLoading,
      error: categoriesError,
      data: { categories = [] } = {},
    },
  ] = useLoadCategoriesLazyQuery();

  const [
    usePlatformsQuery,
    {
      loading: platformsLoading,
      error: platformsError,
      data: { platforms = [] } = {},
    },
  ] = useLoadPlatformsLazyQuery();

  const [
    searchPoliciesQuery,
    { loading: policiesLoading, error: policiesError, data: policiesData },
  ] = useSearchPolicyLazyQuery({
    fetchPolicy: "cache-and-network",
  });

  const [
    searchQueryPacksQuery,
    {
      loading: queryPacksLoading,
      error: queryPacksError,
      data: queryPacksData,
    },
  ] = useSearchQueryPackLazyQuery({
    fetchPolicy: "cache-and-network",
  });

  const onAddPolicyMutation: UseRegistriesReturnProps["onAddPolicyMutation"] =
    useCallback((input) => {
      return addPolicyMutation({ variables: { input: [input] } });
    }, []);

  const [addPolicyMutation] = useAddPolicyMutation({
    refetchQueries: [
      {
        query: SearchPolicyDocument,
        variables: {
          input: {
            ...omit(facets, ["namespace"]),
            assignedOnly: parseBoolean(facets.assignedOnly),
          },
        },
      },
    ],
  });

  const onAddQueryPackMutation: UseRegistriesReturnProps["onAddQueryPackMutation"] =
    useCallback((input) => {
      return addQueryPackMutation({ variables: { input: [input] } });
    }, []);

  const [addQueryPackMutation] = useAddQueryPackMutation({
    refetchQueries: [
      {
        query: SearchPolicyDocument,
        variables: {
          input: {
            ...omit(facets, ["namespace"]),
            assignedOnly: parseBoolean(facets.assignedOnly),
          },
        },
      },
    ],
  });

  const onDeletePolicyMutation = useCallback(
    (e: SyntheticEvent, input: { policyMrn: string }) => {
      e.stopPropagation();
      deletePolicyMutation({ variables: { input } });
    },
    [],
  );

  const [deletePolicyMutation] = useDeletePolicyMutation({
    onCompleted: () => {
      enqueueSnackbar("Successfully deleted policy", { variant: "success" });
    },
    onError: (err) => {
      enqueueSnackbar("Failed to delete policy: " + err.message, {
        variant: "error",
      });
    },
    refetchQueries: [
      {
        query: SearchPolicyDocument,
        variables: {
          input: {
            ...omit(facets, ["namespace"]),
            assignedOnly: parseBoolean(facets.assignedOnly),
          },
        },
      },
    ],
  });

  useEffect(() => {
    const queryInput = {
      variables: {
        input: {
          scopeMrn: space.mrn,
        },
      },
    };

    useCategoriesQuery(queryInput);
    usePlatformsQuery(queryInput);
  }, []);

  useEffect(() => {
    if (!facetsFromQueryString) {
      return;
    }

    onFacetsChange(facetsFromQueryString);
    onStateChange({
      isReady: true,
      isQuerypack: facetsFromQueryString.catalogType === CatalogType.Querypack,
    });
  }, [facetsFromQueryString]);

  const searchQuery = isQuerypack ? searchQueryPacksQuery : searchPoliciesQuery;

  useEffect(() => {
    if (!state.isReady) {
      return;
    }

    updateQueryString({
      router,
      defaultValues: defaultFacets,
      newValues: facets,
    });

    const isQuerypack = facets.catalogType === CatalogType.Querypack;
    onStateChange({
      isQuerypack,
    });

    // const searchQuery = isQuerypack
    //   ? searchQueryPacksQuery
    //   : searchPoliciesQuery;

    searchQuery({
      variables: {
        input: {
          ...omit(facets, ["namespace"]),
          assignedOnly: parseBoolean(facets.assignedOnly),
        },
      },
    });
  }, [facets, state.isReady, searchQuery]);

  const filterOptions = useMemo(() => {
    if (!categories?.length || !platforms?.length) {
      return defaultFilterOptions;
    }

    return defaultFilterOptions.map((filterOption) => {
      if (filterOption.name === Filters.Categories) {
        return {
          ...filterOption,
          options: map(categories, "name"),
        };
      } else if (filterOption.name === Filters.Platforms) {
        return {
          ...filterOption,
          options: map(platforms, "name"),
        };
      }

      return filterOption;
    });
  }, [categories, platforms]);

  const categoryOptions = useMemo(() => {
    if (!categories?.length) {
      return [];
    }

    return registriesByCategoryOrder.map(({ name, icon }) => {
      const category = categories.find((val) => val?.name === name) as Category;

      return {
        name: category?.name || "",
        text: state.isQuerypack
          ? `${category?.packs || 0} Query Packs`
          : `${category?.policies || 0} Policies`,
        icon,
      };
    });
  }, [categories, state.isQuerypack]);

  const registries = useMemo(() => {
    const edges = state.isQuerypack
      ? queryPacksData?.content?.edges
      : policiesData?.content?.edges;
    const nodes = edges
      ? edges
          .map(({ node }) => node as Policy)
          .filter((node) => node.mrn)
          .map((node) => ({
            ...node,
            access: Access.All,
            namespace:
              node.trustLevel === "PRIVATE"
                ? space.id
                : get(node, "namespace") || "mondoohq",
            githubUrl: node.githubUrl || "",
            uid: (node.uid || node.mrn.split("/").pop()) as string,
          }))
      : [];

    return nodes;
  }, [
    queryPacksData?.content?.edges,
    policiesData?.content?.edges,
    state.isQuerypack,
  ]);

  const paginatedRegistries = useMemo(() => {
    return registries.slice(state.from, state.to);
  }, [state.from, state.to, registries]);

  const filters = useMemo(() => {
    return {
      // namespace: facets.namespace,
      // accessLevel: facets.accessLevel,
      // trustLevel: facets.trustLevel,
      categories: facets.categories,
      platforms: facets.platforms,
      assignedOnly: facets.assignedOnly,
    };
  }, [
    // facets.namespace,
    // facets.accessLevel,
    // facets.trustLevel,
    facets.categories,
    facets.platforms,
    facets.assignedOnly,
  ]);

  const onChangeBreadcrumb = useCallback(() => {
    onFacetsChange(defaultFacets);
    onStateChange(omit(defaultState, "isReady"));
    // Reset pagination
    onPaginationChange({ count: registries.length });
  }, [registries]);

  const onChangeAssignHandler = useCallback(async () => {
    await searchQuery({
      variables: {
        input: {
          ...omit(facets, ["namespace"]),
          assignedOnly: parseBoolean(facets.assignedOnly),
        },
      },
    });
  }, [searchQuery, facets]);

  return {
    loading:
      paginatedRegistries.length > 0
        ? false
        : state.isQuerypack
          ? queryPacksLoading
          : policiesLoading,
    refreshing:
      paginatedRegistries.length > 0
        ? queryPacksLoading || policiesLoading
        : false,
    assignInProgress: [],
    error: state.isQuerypack ? queryPacksError : policiesError,
    noPolicies: Boolean(paginatedRegistries?.length === 0),
    filtersLoading: categoriesLoading || platformsLoading,
    filtersErrors: [categoriesError, platformsError]
      .filter(Boolean)
      .map(String),
    filterOptions,
    registriesTabOptions,
    platforms: platforms as ContentPlatform[],
    categoryOptions,
    registries,
    sortByOptions,
    paginatedRegistries,
    facets,
    state,
    filters,
    onFacetsChange,
    onStateChange,
    onChangeBreadcrumb,
    onPaginationChange,
    onAddPolicyMutation,
    onAddQueryPackMutation,
    onChangeAssignHandler,
    onDeletePolicyMutation,
  };
};

export default useRegistries;
