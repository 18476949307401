import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { SubmitHandler, useForm } from "react-hook-form";
import { getError } from "~/lib/handle-error";
import { Space } from "~/lib/types";
import {
  Asset,
  ComplianceControl,
  ComplianceFramework,
  DocumentFormat,
  DocumentType,
  useGenerateDocumentMutation,
} from "~/operations";

enum FormKeys {
  reportName = "reportName",
  options_outOfScope = "options.outOfScope",
  options_details_details = "options.details.details",
  options_details_platform = "options.details.platform",
  options_details_mrn = "options.details.mrn",
  options_details_cloudIdentifier = "options.details.cloudIdentifier",
  options_output = "options.output",
}

//fields that should be indented under the "Asset details" checkbox, as well as selected when the "Asset details" checkbox is selected
const shouldIndent = [
  FormKeys.options_details_platform,
  FormKeys.options_details_mrn,
  FormKeys.options_details_cloudIdentifier,
];

//the array of checkboxes that are rendered in the form
const formValuesArray: {
  name: FormKeys;
  label: string;
}[] = [
  {
    name: FormKeys.options_outOfScope,
    label: "Assets marked out of scope (includes justification)",
  },
  { name: FormKeys.options_details_details, label: "Asset details" },
  { name: FormKeys.options_details_platform, label: "Platform details" },
  { name: FormKeys.options_details_mrn, label: "MRN values" },
  {
    name: FormKeys.options_details_cloudIdentifier,
    label: "Cloud identifier IDs ",
  },
  { name: FormKeys.options_output, label: "Check output" },
];

//default values for the form
const defaultFormValues = {
  reportName: "",
  options: {
    outOfScope: false,
    details: {
      details: false,
      platform: false,
      mrn: false,
      cloudIdentifier: false,
    },
    output: false,
  },
};

type ExportFormInputs = typeof defaultFormValues;

type CommonProps = {
  title?: string;
  space: Space;
};

type frameworkProps = CommonProps & {
  documentType: DocumentType.FrameworkReport;
  frameworkMrn: ComplianceFramework["mrn"];
  controlMrn?: never;
  assetMrn?: never;
};

type controlProps = CommonProps & {
  documentType: DocumentType.ControlReport;
  frameworkMrn: ComplianceFramework["mrn"];
  controlMrn: ComplianceControl["mrn"];
  assetMrn?: never;
};

type assetProps = CommonProps & {
  documentType: DocumentType.AssetReport;
  frameworkMrn?: never;
  controlMrn?: never;
  assetMrn: Asset["mrn"];
};

export type UseExportButtonProps = frameworkProps | controlProps | assetProps;

export function UseExportButton({
  documentType,
  title,
  space,
  frameworkMrn,
  controlMrn,
  assetMrn,
}: UseExportButtonProps) {
  const [exportDialogOpen, setExportDialogOpen] = useState<boolean>(false);
  const [finishedDialogOpen, setFinishedDialogOpen] = useState<boolean>(false);
  const [allSelected, setAllSelected] = useState<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();

  //if a title is provided in the props, set the default value of the reportName field to that title
  let defaultValues = {
    ...defaultFormValues,
    ...(title && { reportName: title }),
  };

  const { control, handleSubmit, watch, reset, formState, setValue } =
    useForm<ExportFormInputs>({
      mode: "onBlur",
      defaultValues,
    });

  const [options] = watch(["options"]);

  //array of all of the checkboxes in the form
  const allOptions = [
    options.outOfScope,
    options.details.details,
    options.details.platform,
    options.details.mrn,
    options.details.cloudIdentifier,
    options.output,
  ];

  //if any of the checkboxes are unchecked, set allSelected to false
  useEffect(() => {
    const notAllSelected = allOptions.some((values) => values === false);
    setAllSelected(!notAllSelected);
  }, allOptions);

  //if the "Asset details" checkbox is checked, check all of the checkboxes that should be indented
  useEffect(() => {
    if (options.details.details) {
      shouldIndent.forEach((key) => setValue(key, true));
    } else {
      shouldIndent.forEach((key) => setValue(key, false));
    }
  }, [options.details.details]);

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset(defaultValues);
    }
    handleClose();
  }, [formState.isSubmitSuccessful]);

  const [generateDocument] = useGenerateDocumentMutation();

  //open the dialog
  const handleOpen = () => {
    setExportDialogOpen(true);
  };

  //close the dialog
  const handleClose = () => {
    setExportDialogOpen(false);
  };

  const handleFinishedOpen = () => {
    setFinishedDialogOpen(true);
  };

  const handleFinishedClose = () => {
    setFinishedDialogOpen(false);
  };

  //select or deselect all of the checkboxes in the form
  const handleSelectAllOptions = () => {
    if (allSelected) {
      formValuesArray.forEach(({ name }) => setValue(name, false));
    } else {
      formValuesArray.forEach(({ name }) => setValue(name, true));
    }
  };

  //submit the form
  const onSubmit: SubmitHandler<ExportFormInputs> = async (data) => {
    console.log(data);

    try {
      if (documentType === DocumentType.ControlReport && !controlMrn) {
        throw new Error("Control MRN is required for Control Report");
      }

      if (documentType === DocumentType.FrameworkReport && !frameworkMrn) {
        throw new Error("Framework MRN is required for Framework Report");
      }

      if (documentType === DocumentType.AssetReport && !assetMrn) {
        throw new Error("Asset MRN is required for Asset Report");
      }

      const getOptions = () => {
        if (documentType === DocumentType.AssetReport) {
          return {
            assetReportOptions: {
              assetMrn: assetMrn,
            },
          };
        }

        if (documentType === DocumentType.FrameworkReport) {
          return {
            frameworkOptions: {
              frameworkMRN: frameworkMrn,
            },
          };
        }

        if (documentType === DocumentType.ControlReport) {
          return {
            controlOptions: {
              frameworkMRN: frameworkMrn,
              controlMRN: controlMrn,
            },
          };
        }

        return {};
      };

      const inputOptions = getOptions();
      if (!inputOptions) throw new Error("Missing valid mrn.");

      const generateInput = {
        scopeMRN: space.mrn,
        format: DocumentFormat.Pdf,
        type: documentType,
        name: data.reportName.trim(),
        ...inputOptions,
      };

      await generateDocument({ variables: { input: generateInput } });
      handleFinishedOpen();
    } catch (e) {
      const errorMessage = getError(e);
      enqueueSnackbar(
        `Something went wrong while attempting to generate your report: ${errorMessage}`,
        { variant: "error" },
      );
    }
  };

  return {
    exportDialog: {
      exportDialogOpen,
      handleClose,
      handleOpen,
    },
    finishedDialog: {
      finishedDialogOpen,
      handleFinishedOpen,
      handleFinishedClose,
    },
    form: {
      control,
      handleSubmit,
      reset,
      formState,
      shouldIndent,
      onSubmit,
      handleSelectAllOptions,
      allSelected,
      formValuesArray,
    },
  };
}
