import { TableCell, Typography } from "@mui/material";

type NumberOfNumberCellProps = {
  value: number;
  total: number;
  "data-testid"?: string;
};

export function NumberOfNumberCell({
  value,
  total,
  ...props
}: NumberOfNumberCellProps) {
  return (
    <TableCell data-testid={props["data-testid"]}>
      <Typography>
        {value}
        <Typography component="span" color="text.secondary">
          /{total}
        </Typography>
      </Typography>
    </TableCell>
  );
}
