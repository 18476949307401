import { Box, Button, Grid2 } from "@mui/material";
import { IntegrationAddHeader } from "../../headers/integration-add-header";
import { IntegrationType } from "~/operations";

type ConfigurePreferencesProps = {
  integrationType: IntegrationType;
  preferencesForm: React.ReactNode;
  onFinalizeSetup: () => Promise<void>;
};

export function ConfigurePreferences({
  integrationType,
  preferencesForm,
  onFinalizeSetup,
}: ConfigurePreferencesProps) {
  return (
    <Grid2 container>
      <Grid2 size={12}>
        <IntegrationAddHeader {...{ type: integrationType }} />
      </Grid2>
      <Grid2 size={12}>
        <Box pb={10}>{preferencesForm}</Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button onClick={onFinalizeSetup} variant="contained" color="primary">
            Finalize setup
          </Button>
        </Box>
      </Grid2>
    </Grid2>
  );
}
