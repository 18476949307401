import React from "react";
import { Flex } from "~/components/Flex";
import { Box, Divider, Typography } from "@mui/material";
import {
  BlastRadiusFieldsFragment,
  EpssScore,
  RiskFactorStats,
  ScoreRating,
} from "~/operations";
import { RiskFactorsIcons } from "~/pages/space/security/components/RiskFactors/RiskFactorsIcons";
import { formatEPSS } from "~/utils/formatter";
import { Link } from "react-router-dom";
import { ImpactUpdated } from "~/components/impact/Updated/impact-updated";
import { getImpactLabelUpdated } from "~/components/impact/Updated/useImpactUpdated";

export type SoftwareVersionTooltipProps = {
  blastRadius: BlastRadiusFieldsFragment | null | undefined;
  riskScore: number;
  rating: ScoreRating;
  title: string;
  riskFactors: (RiskFactorStats | null)[] | null | undefined;
  cvss: number;
  epssScore: EpssScore | null | undefined;
  href: string;
};

export const SoftwareVersionTooltipData = ({
  riskScore,
  rating,
  blastRadius,
  title,
  riskFactors,
  cvss,
  epssScore,
  href,
}: SoftwareVersionTooltipProps) => {
  return (
    <Flex flexDirection="column" sx={{ minWidth: "124px" }} gap={0.5}>
      <Flex flexDirection="column">
        <Flex gap={1}>
          <Typography to={href} component={href ? Link : "p"} fontWeight={700}>
            {title}
          </Typography>
          <RiskFactorsIcons riskFactors={riskFactors} />
        </Flex>
        <Box></Box>
      </Flex>
      {cvss && (
        <Flex justifyContent="space-between">
          <Box>
            <Typography color="text.secondary" fontSize={12}>
              CVSS
            </Typography>
          </Box>
          <Box>
            <Typography color="text.secondary" fontSize={12} fontWeight={700}>
              {(cvss / 10).toFixed(1)}
            </Typography>
          </Box>
        </Flex>
      )}
      {epssScore && (
        <Flex justifyContent="space-between">
          <Box>
            <Typography color="text.secondary" fontSize={12}>
              EPSS
            </Typography>
          </Box>
          <Box>
            <Typography color="text.secondary" fontSize={12} fontWeight={700}>
              {formatEPSS(epssScore)}
            </Typography>
          </Box>
        </Flex>
      )}
      {blastRadius && (
        <Flex justifyContent="space-between">
          <Box>
            <Typography color="text.secondary" fontSize={12}>
              Blast Radius
            </Typography>
          </Box>
          <Box>
            <Typography color="text.secondary" fontSize={12} fontWeight={700}>
              {blastRadius.assets}
            </Typography>
          </Box>
        </Flex>
      )}
      <Divider />
      <Flex justifyContent="center" mt={1} alignItems="center" gap={1}>
        <Typography fontSize={12} fontWeight={700}>
          {getImpactLabelUpdated({ value: riskScore, rating })}
        </Typography>
        <ImpactUpdated
          impact={{ rating, value: riskScore }}
          showText={false}
          isRiskScore
        />
      </Flex>
    </Flex>
  );
};
