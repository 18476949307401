import { Grid2, Typography, TableRow, useTheme } from "@mui/material";
import { Fragment } from "react/jsx-runtime";
import {
  PublishedCell,
  ImpactCell,
  TitleCell,
  RiskFactorsCell,
} from "~/components/Cells";
import { GenericTable } from "~/components/GenericTable/GenericTable";
import { SectionHeading } from "../../components";
import { spacing } from "../lib/SharedVariables";
import { usePolicies } from "./hooks/usePolicies";
import { AssetPolicyListEdge, ScoreRating } from "~/operations";
import { NoItemsSection } from "../components";
import { useAssetReport } from "./hooks/useAssetReport";
import { Asset } from "./types";

export function Policies() {
  const { asset, policies } = useAssetReport();

  return (
    <Fragment>
      <SectionHeading heading="Policies" disableUpperPadding />
      {asset &&
        policies.map((policy) => {
          return (
            <PolicyChecksGroup key={policy.node.mrn} {...{ asset, policy }} />
          );
        })}
    </Fragment>
  );
}

export function PolicyChecksGroup({
  asset,
  policy,
}: {
  asset: Asset;
  policy: AssetPolicyListEdge;
}) {
  let theme = useTheme();
  const { passingChecks, failingChecks } = usePolicies({
    asset,
    policyMrn: policy.node.mrn,
  });
  const hasPassingChecks = Boolean(passingChecks.length > 0);
  const hasFailingChecks = Boolean(failingChecks.length > 0);

  return (
    <Grid2 container sx={{ alignItems: "flex-start", columnGap: 2, mb: 3 }}>
      <Grid2
        size="grow"
        sx={{
          display: "flex",
          flexDirection: "column",
          pr: 2,
        }}
      >
        <Typography fontWeight={700}>{policy.node.name}</Typography>
        {/* TODO: ADD DESCRIPTION TO API */}
        {/* <Typography variant="body2" color="text.secondary">
          {policy.node.description}
        </Typography> */}
      </Grid2>
      {/* Cannot Yet do or doesn't make sense */}
      {/* <Grid2 sx={{ mr: 3 }}>
        <AssetResultsChart blastRadius={fakeData.blastRadius} />
      </Grid2> */}
      {/* <Grid2>
        <RiskBoxSmall riskLevel={"critical"} riskScore={96} />
      </Grid2> */}

      <Grid2 size={12} sx={{ my: 3 }}>
        <Typography fontWeight={700} sx={{ mb: spacing[25] }}>
          Failing Checks
        </Typography>
        {!hasFailingChecks ? (
          <NoItemsSection target="failing checks" />
        ) : (
          <GenericTable
            items={0}
            tableName="ASSET_REPORT_CHECKS"
            validSortFields={[]}
            printView
          >
            {failingChecks.map((item) => {
              const { node } = item;

              return (
                <TableRow key={node?.mrn}>
                  {/* <RankCell id={node.rank} /> CHECKSCORES API DOES NOT HAVE RANK */}
                  <ImpactCell
                    impact={node?.riskScore || 0}
                    rating={node?.rating || ScoreRating.Critical}
                    isActive
                  />
                  <TitleCell
                    title={node?.title || ""}
                    tags={node?.tags}
                    options={{ fontSize: 14 }}
                  />
                  <RiskFactorsCell riskFactors={node?.riskFactors} />
                  <PublishedCell date={node?.lastUpdated || "Never"} />
                </TableRow>
              );
            })}
          </GenericTable>
        )}
      </Grid2>
      <Grid2 size={12} sx={{ mt: 3 }}>
        <Typography fontWeight={700} sx={{ mb: spacing[25] }}>
          Passing Checks
        </Typography>
        {!hasPassingChecks ? (
          <NoItemsSection target="passing checks" />
        ) : (
          <GenericTable
            space={undefined}
            items={0}
            tableName="ASSET_REPORT_CHECKS"
            validSortFields={[]}
            printView
          >
            {passingChecks.map((item) => {
              const { node } = item;
              return (
                <TableRow key={node?.mrn}>
                  {/* <RankCell id={node.rank} /> CHECKSCORES API DOES NOT HAVE RANK */}
                  <ImpactCell
                    impact={node?.riskScore || 0}
                    rating={node?.rating || ScoreRating.Critical}
                    isActive
                  />
                  <TitleCell
                    title={node?.title || ""}
                    tags={node?.tags}
                    options={{ fontSize: 14 }}
                  />
                  <RiskFactorsCell riskFactors={node?.riskFactors} />
                  <PublishedCell date={node?.lastUpdated || "Never"} />
                </TableRow>
              );
            })}
          </GenericTable>
        )}
      </Grid2>
    </Grid2>
  );
}
