import { alpha, MenuItem, SxProps } from "@mui/material";
import { KeyboardEvent, ReactNode } from "react";

export type BaseMenuItemProps = {
  label: string;
  value: string;
  children?: ReactNode;
  onClick?: (value: string) => void;
  sx?: SxProps;
  onKeyDown?: (event: KeyboardEvent<HTMLLIElement>, label: string) => void;
};

export const BaseMenuItem = ({
  label,
  children,
  onClick,
  value,
  sx = [],
  onKeyDown,
}: BaseMenuItemProps) => {
  return (
    <MenuItem
      key={label}
      value={label}
      sx={[
        {
          "&.MuiMenuItem-root": {
            py: 0.5,
            my: 0.5,
            mx: 3,
            px: 1,
          },
          "&:hover": {
            backgroundColor: (theme) =>
              `${alpha(theme.palette.primary.main, 0.2)}`,
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      onClick={() => onClick?.(value)}
      tabIndex={0}
      onKeyDown={(event) => onKeyDown?.(event, label)}
    >
      {children}
    </MenuItem>
  );
};
