import { Fragment } from "react/jsx-runtime";
import { SectionHeading } from "../../components";
import { ScoreRating } from "~/operations";
import { Grid2, TableRow } from "@mui/material";
import {
  ImpactCell,
  TitleCell,
  RiskFactorsCell,
  PublishedCell,
} from "~/components/Cells";
import { GenericTable } from "~/components/GenericTable/GenericTable";
import { NoItemsSection } from "../components";
import { useAssetReport } from "./hooks/useAssetReport";

export function Advisories() {
  const { advisories } = useAssetReport();
  const hasAdvisories = Boolean(advisories?.length);

  return (
    <Fragment>
      <SectionHeading heading="Advisories" disableUpperPadding />

      <Grid2 size={12} sx={{ my: 3 }}>
        {!hasAdvisories ? (
          <NoItemsSection target="advisories" />
        ) : (
          <AdvisoriesTable advisories={advisories} />
        )}
      </Grid2>
    </Fragment>
  );
}

export function AdvisoriesTable({
  advisories,
}: {
  advisories: ReturnType<typeof useAssetReport>["advisories"];
}) {
  return (
    <GenericTable
      space={undefined}
      items={0}
      tableName="ASSET_REPORT_CHECKS"
      validSortFields={[]}
      printView
    >
      {advisories?.map((item) => {
        const { node } = item;

        return (
          <TableRow key={node?.id}>
            {/* <RankCell id={node.rank} /> CHECKSCORES API DOES NOT HAVE RANK */}
            <ImpactCell
              impact={node?.riskScore || 0}
              rating={node?.rating || ScoreRating.Critical}
              isActive
            />
            <TitleCell
              title={node?.title || ""}
              tags={node?.tags}
              options={{ fontSize: 14 }}
            />
            <RiskFactorsCell riskFactors={node?.riskFactors} />
            <PublishedCell date={node?.firstDetectedAt || "Never"} />
          </TableRow>
        );
      })}
    </GenericTable>
  );
}
