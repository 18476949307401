import { useSort } from "~/pages/inventory/hooks/useSort";
import {
  OrderDirection,
  ScoreStateFilter,
  useGetVulnerabilityScoresQuery,
  VulnerabilityScoreFilter,
  VulnerabilityScoreOrder,
  VulnerabilityScoreOrderField,
  VulnerabilityScoreType,
} from "~/operations";
import { AssetContextualLinkType } from "~/pages/space/vulnerabilities/components/AffectedAssets";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";
import { mapDetectedByToolName } from "~/components/vulnerabilities/DetectedBy/lib";

type UseVulnerabilityAffectedAssetsParams = {
  vulnerabilityId: string;
  vulnerabilityScoreType: "CVE" | "Advisory" | "Policy";
  scope: SpaceOrWorkspaceScope;
};

const getVulnerabilityScoreType = (scope: string): VulnerabilityScoreType => {
  switch (scope) {
    case "CVE":
      return VulnerabilityScoreType.Cve;
    case "Advisory":
      return VulnerabilityScoreType.Advisory;
    default:
      return VulnerabilityScoreType.Cve;
  }
};

const getVulnerabilityEmptyStateMessage = (vulnerabilityScoreType: string) => {
  switch (vulnerabilityScoreType) {
    case "CVE":
      return "There are currently no affected assets for this CVE";
    case "Advisory":
    case "Policy":
    default:
      return `There are currently no affected assets for this ${vulnerabilityScoreType.toLowerCase()}`;
  }
};

export function useVulnerabilityAffectedAssets({
  vulnerabilityId,
  vulnerabilityScoreType,
  scope,
}: UseVulnerabilityAffectedAssetsParams) {
  const { handleSortClick, orderBy } = useSort<VulnerabilityScoreOrder>({
    defaultSort: {
      field: VulnerabilityScoreOrderField.CvssScore,
      direction: OrderDirection.Desc,
    },
    validFields: ["CVSS_SCORE", "MRN", "LAST_UPDATED"],
  });

  const filter: VulnerabilityScoreFilter = {
    queryTerms: [vulnerabilityId],
    scoreType: getVulnerabilityScoreType(vulnerabilityScoreType),
    state: ScoreStateFilter.Open,
  };

  const { data, error, loading, fetchMore } = useGetVulnerabilityScoresQuery({
    variables: {
      entityMrn: scope.mrn,
      first: 10,
      orderBy,
      filter,
    },
    skip: Boolean(!vulnerabilityId),
  });

  const vulnerabilityScoresUnion = data?.vulnerabilityScores;
  const vulnerabilityScores =
    vulnerabilityScoresUnion?.__typename === "VulnerabilityScoresConnection"
      ? vulnerabilityScoresUnion
      : undefined;

  const assets = vulnerabilityScores?.edges?.flatMap(({ node }) => {
    if (!node?.asset) return [];

    const asset = {
      id: node.asset.id,
      mrn: node.asset.mrn,
      score: node.riskScore || 0,
      lastUpdated: node.updatedAt,
      riskFactors: node.riskFactors,
      title: node.asset.name,
      tags: node.asset.tags ? [...node.asset.tags] : [],
      iconId: node.asset.icon,
      rating: node.rating,
    };

    // Manually add source tags to the front of the tags array
    if (node.detectionSources) {
      node.detectionSources.map((source) =>
        asset.tags.unshift({
          key: "source",
          value: mapDetectedByToolName(source.name),
          __typename: "Tag",
        }),
      );
    }

    return asset;
  });

  function getUrlContextType(): (typeof AssetContextualLinkType)[keyof typeof AssetContextualLinkType] {
    const vulnType = getVulnerabilityScoreType(vulnerabilityScoreType);

    switch (vulnType) {
      case VulnerabilityScoreType.Cve:
        return AssetContextualLinkType.Cve;
      case VulnerabilityScoreType.Advisory:
        return AssetContextualLinkType.Advisory;
    }
  }

  return {
    assets,
    orderBy,
    handleSortClick,
    filteredTotalCount: vulnerabilityScores?.filteredTotalCount || 0,
    fetchMore,
    pageInfo: vulnerabilityScores?.pageInfo,
    error,
    loading,
    urlContextType: getUrlContextType(),
    emptyStateMessage: getVulnerabilityEmptyStateMessage(
      vulnerabilityScoreType,
    ),
  };
}
