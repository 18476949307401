import { Fragment } from "react";
import {
  alpha,
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import { useAppNavDrawer } from "~/providers/app-nav-drawer";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  HelpOutlineIcon,
} from "~/components/icons";
import { AppNavDrawerListItem } from "./list-item";
import { AppNavDrawerTooltip } from "./tooltip";

export type AppNavDrawerProps = {};

export function AppNavDrawer({}: AppNavDrawerProps) {
  const navDrawer = useAppNavDrawer();

  const handleDrawerToggle = () => {
    navDrawer.setOpen(!navDrawer.open);
  };

  return (
    <Drawer
      variant="permanent"
      open={navDrawer.open}
      sx={{
        flexShrink: 0,
        whiteSpace: "nowrap",
        boxSizing: "border-box",
        width: navDrawer.width,
        transition: (theme) =>
          theme.transitions.create("width", navDrawer.transition),
        overflowX: "hidden",
        "& .MuiDrawer-paper": {
          width: navDrawer.width,
          transition: (theme) =>
            theme.transitions.create("width", navDrawer.transition),
          overflowX: "hidden",
          pb: 6,
        },
      }}
      PaperProps={{ elevation: 0, sx: { borderColor: "rgba(0, 0, 0, 0.2)" } }}
    >
      <Toolbar />

      {navDrawer.tree.length > 0 && (
        <Fragment>
          <List id="app-nav-drawer-list" sx={{ pt: 2, pb: 1 }}>
            {navDrawer.tree.map((item, index) => (
              <Fragment key={index}>
                {item.beforeDivider && <Divider sx={{ mx: 2, my: 1 }} />}
                <AppNavDrawerListItem item={item} />
              </Fragment>
            ))}
          </List>
          <Divider sx={{ mx: 2 }} />
        </Fragment>
      )}

      <List sx={{ py: 2 }}>
        <ListItem sx={{ display: "block", py: 0 }}>
          <AppNavDrawerTooltip title="Get Support">
            <ListItemButton
              href="https://mondoo.com/support"
              sx={[
                {
                  px: 1,
                  py: 0.5,
                  borderRadius: 1,
                  color: "text.secondary",
                },
                {
                  "&.Mui-selected, &.Mui-selected:hover": {
                    backgroundColor: (theme) =>
                      alpha(theme.palette.primary.main, 0.5),
                    color: "text.primary",
                  },
                },
              ]}
            >
              <ListItemIcon
                sx={{
                  minWidth: "24px",
                  justifyContent: "center",
                  color: "text.secondary",
                }}
              >
                <HelpOutlineIcon fontSize="inherit" />
              </ListItemIcon>
              <ListItemText
                primary="Get Support"
                primaryTypographyProps={{
                  sx: {
                    opacity: navDrawer.open ? 1 : 0,
                    transition: (theme) =>
                      theme.transitions.create("opacity", navDrawer.transition),
                    ml: 1,
                    fontSize: 14,
                  },
                }}
              />
            </ListItemButton>
          </AppNavDrawerTooltip>
        </ListItem>
      </List>

      <Box
        sx={[
          {
            opacity: navDrawer.open ? 1 : 0,
            transition: (theme) =>
              theme.transitions.create("opacity", navDrawer.transition),
            mt: "auto",
            mb: 2,
          },
          {
            ul: {
              m: 0,
              mx: 3,
              p: 0,
              whiteSpace: "normal",
              display: "block",
              listStyle: "none",
              minWidth: "159px",
            },
          },
          {
            li: {
              pr: 0.4,
              fontSize: 10,
              color: "text.secondary",
              display: "inline-block",
            },
          },
          {
            "li:not(:last-of-type)::after": {
              content: "'·'",
              pl: 0.4,
              display: "inline-block",
            },
          },
        ]}
      >
        <ul>
          <li>
            <a
              href="https://mondoo.com/about-us"
              tabIndex={navDrawer.open ? 0 : -1}
            >
              About Mondoo
            </a>
          </li>
          <li>
            <a
              href="https://status.mondoo.com/"
              tabIndex={navDrawer.open ? 0 : -1}
            >
              Mondoo Status
            </a>
          </li>
          {/* https://github.com/mondoohq/mondoo-com/issues/277 */}
          {/* <li>
            <a href="#" tabIndex={navDrawer.open ? 0 : -1}>
              Imprint
            </a>
          </li> */}
          <li>
            <a
              href="https://mondoo.com/privacy-policy"
              tabIndex={navDrawer.open ? 0 : -1}
            >
              Privacy Policy
            </a>
          </li>
        </ul>
        <Typography color="text.secondary" fontSize={10} mx={3} my={1}>
          &copy; Mondoo, Inc.
        </Typography>
      </Box>

      <List
        sx={{
          backgroundColor: "primary.darker",
          borderTop: "1px solid rgba(0, 0, 0, 0.2)",
          position: "fixed",
          left: 0,
          bottom: 0,
          width: navDrawer.width,
          transition: (theme) =>
            theme.transitions.create("width", navDrawer.transition),
          zIndex: 2,
        }}
        disablePadding
      >
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            onClick={handleDrawerToggle}
            sx={{
              minHeight: 48,
              px: 2.5,
            }}
          >
            <ListItemIcon sx={{ minWidth: 0, color: "common.white" }}>
              {navDrawer.open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </ListItemIcon>
            <ListItemText
              primary={"Collapse"}
              sx={{
                opacity: navDrawer.open ? 1 : 0,
                transition: (theme) =>
                  theme.transitions.create("opacity", navDrawer.transition),
                ml: 3,
                color: "common.white",
              }}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
}
