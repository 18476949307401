import { alpha, Box, TableCell, Typography } from "@mui/material";
import { ImpactUpdated } from "../impact/Updated/impact-updated";
import { ScoreRating } from "~/operations";
import { Flex } from "../Flex";
import { ScoreRatingColorMap } from "~/pages/reporting/reports/lib/ScoreRatingColorMap";

export const RiskRatingCell = ({
  rating,
  showValue = false,
  ...props
}: {
  rating: { rating: ScoreRating; value: number };
  showValue?: boolean;
  "data-testid"?: string;
}) => {
  const color = ScoreRatingColorMap[rating.rating];

  return (
    <TableCell data-testid={props["data-testid"]}>
      <Flex gap={1}>
        {showValue && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 32,
              width: 35,
              borderRadius: "16px",
              padding: 1,
              background: (theme) => alpha(theme.palette[color].main, 0.06),
            }}
          >
            <Typography
              sx={{
                color: (theme) => theme.palette[color].main,
                fontWeight: 700,
                textAlign: "center",
              }}
            >
              {rating.value}
            </Typography>
          </Box>
        )}
        <ImpactUpdated
          impact={{ rating: rating.rating, value: rating.value }}
          hideTooltip
        />
      </Flex>
    </TableCell>
  );
};
