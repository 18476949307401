import { OpenDropdownButton } from "../OpenDropdownButton";
import { Box } from "@mui/material";
import { BaseAutocomplete } from "~/pages/space/Workspaces/components/QueryBuilder/AutocompleteDropdown/BaseAutocomplete";
import { MenuItemsGroup } from "~/pages/space/Workspaces/components/QueryBuilder/AutocompleteDropdown/MenuItemsGroup";
import { KeyboardEvent, useEffect, useRef, useState } from "react";
import {
  WorkspaceSelectionConditionIntField,
  WorkspaceSelectionConditionKeyValueField,
  WorkspaceSelectionConditionRatingField,
  WorkspaceSelectionConditionStringField,
} from "~/operations";
import { Path, useFormContext } from "react-hook-form";
import { CreateWorkspaceFormInput } from "~/pages/space/Workspaces/components/CreateWorkspace/CreateWorkspacePage";
import { BaseMenuList } from "~/pages/space/Workspaces/components/QueryBuilder/AutocompleteDropdown/BaseMenuList";
import { get, last } from "lodash";

type GroupItemValue = {
  label: string;
  value:
    | WorkspaceSelectionConditionStringField
    | WorkspaceSelectionConditionIntField
    | WorkspaceSelectionConditionRatingField
    | WorkspaceSelectionConditionKeyValueField;
};

export type KeysDropdownAutocomplete = {
  name: `selections.${number}.conditions.${number}.formKey`;
  groups: Array<{ label: string; values: Array<GroupItemValue> }>;
  onChange: (
    name: Path<CreateWorkspaceFormInput>,
    value:
      | WorkspaceSelectionConditionStringField
      | WorkspaceSelectionConditionIntField
      | WorkspaceSelectionConditionRatingField,
  ) => void;
};

export const KeysDropdownAutocomplete = ({
  name,
  groups,
  onChange,
}: KeysDropdownAutocomplete) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const { register, trigger } = useFormContext<CreateWorkspaceFormInput>();

  const lastItemLabel = [...get(last([...groups]), "values", [])].pop()?.value;

  const handleKeyDown = (
    event: KeyboardEvent<HTMLLIElement>,
    label: string,
  ): void => {
    // Check if `Tab` is pressed on the last item
    if (event.key === "Tab") {
      if (label === lastItemLabel && !event.shiftKey) {
        // Close dropdown if Tab is pressed on the last item
        setIsOpen(false);
      }
    }
  };

  useEffect(() => {
    register(name, {
      required: "This condition is incomplete. Please add a selection.",
    });
    trigger(name);
  }, [register]);

  const onItemClick = (value: unknown) => {
    onChange(
      name,
      value as
        | WorkspaceSelectionConditionStringField
        | WorkspaceSelectionConditionIntField
        | WorkspaceSelectionConditionRatingField,
    );
    setIsOpen(false);
  };

  return (
    <Box>
      <OpenDropdownButton
        isOpen={isOpen}
        anchorRef={anchorRef}
        onClick={() => setIsOpen((open) => !open)}
        data-testid={`${isOpen ? "close" : "open"}-keys-dropdown`}
      />
      <Box sx={{ maxWidth: "400px", position: "relative" }}>
        <BaseAutocomplete
          showKeyboardHint
          open={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
          anchorRef={anchorRef}
          renderContent={() => (
            <BaseMenuList>
              {groups.map((group) => (
                <MenuItemsGroup
                  groupTitle={group.label}
                  onClick={onItemClick}
                  items={group.values}
                  key={group.label}
                  onKeyDown={handleKeyDown}
                />
              ))}
            </BaseMenuList>
          )}
        />
      </Box>
    </Box>
  );
};
