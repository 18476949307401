import React from "react";
import {
  Checkbox,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { DataTable } from "~/components/data-table";
import { useChecksTable } from "../../hooks";
import { SelectedEntity } from "~/components/exceptions/types";
import { Header } from "~/types/table";
import { useSearchParams } from "react-router-dom";
import { Space } from "~/lib/types";
import { ChecksTableRow } from "./ChecksTableRow";
import { Asset, CheckScoresEdge } from "../../asset/types";
import { CheckScoreOrder } from "~/operations";
import { EmptyState } from "~/components/empty-state/empty-state";

type ChecksTableProps = {
  hasApplyExceptionMutationPermission: boolean;
  selectedChecks: SelectedEntity[];
  checkHandle: ReturnType<typeof useChecksTable>["checkHandle"];
  queryEdges: CheckScoresEdge[];
  sort: CheckScoreOrder;
  space: Space;
  asset: Asset;
};

export function ChecksTable({
  hasApplyExceptionMutationPermission,
  selectedChecks,
  checkHandle,
  queryEdges,
  sort,
  asset,
  space,
}: ChecksTableProps) {
  const [searchParams] = useSearchParams();
  const checkMrn = searchParams.get("checkMrn");

  const focusedQueryEdge = queryEdges.find(
    (edge) => edge.node?.mrn === checkMrn,
  );

  React.useLayoutEffect(() => {
    if (focusedQueryEdge) {
      const { mrn } = focusedQueryEdge.node || {};
      if (mrn) {
        document.getElementById(mrn)?.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [focusedQueryEdge]);

  if (queryEdges.length === 0) {
    return <EmptyState contentType="" />;
  }

  return (
    <DataTable
      selectable={hasApplyExceptionMutationPermission}
      selection={selectedChecks}
    >
      <TableHead>
        <TableRow>
          {hasApplyExceptionMutationPermission && (
            <TableCell>
              <Checkbox
                onChange={(_, checked) => checkHandle.checkAll(checked)}
                checked={selectedChecks.length === queryEdges.length}
                indeterminate={
                  selectedChecks.length > 0 &&
                  selectedChecks.length < queryEdges.length
                }
              />
            </TableCell>
          )}
          {tableHeaders.map((header) => (
            <TableCell
              key={header.id}
              sortDirection={
                sort.field === header.id
                  ? sort.direction === "ASC"
                    ? "asc"
                    : "desc"
                  : undefined
              }
              {...(header.options?.width && { width: header.options.width })}
            >
              <TableSortLabel
                onClick={() => checkHandle.sortClick(header)}
                direction={sort.direction === "ASC" ? "asc" : "desc"}
                active={sort.field === header.id}
              >
                {header.label}
              </TableSortLabel>
            </TableCell>
          ))}
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {queryEdges.map((edge) => {
          return (
            <ChecksTableRow
              key={edge.node?.mrn}
              asset={asset}
              space={space}
              queryEdge={edge}
              isFocused={edge === focusedQueryEdge || queryEdges.length === 1}
              isSelectable={hasApplyExceptionMutationPermission}
              isChecked={Boolean(
                selectedChecks.find(
                  (exception) => exception.mrn === edge.node?.mrn,
                ),
              )}
              handleCheck={checkHandle.check}
              handleClose={checkHandle.close}
            />
          );
        })}
      </TableBody>
    </DataTable>
  );
}

const tableHeaders: Header[] = [
  {
    id: "SCORE",
    label: "Result",
    options: {
      width: 120,
    },
  },
  {
    id: "TITLE",
    label: "Check",
  },
  {
    id: "RISK_FACTORS",
    label: "Risk factors",
    options: {
      width: 140,
    },
  },
];
