import React from "react";
import { ImpactUpdated } from "~/components/impact/Updated/impact-updated";
import { ScoreRating } from "~/operations";

type CheckScoreImpactProps = {
  value: number;
  isActive: boolean;
  isRiskScore?: boolean;
  isCVSS?: boolean;
  rating: ScoreRating;
};

export const ScoreImpact = ({
  value,
  isActive,
  isRiskScore = false,
  isCVSS = false,
  rating,
}: CheckScoreImpactProps) => {
  return (
    <>
      <ImpactUpdated
        impact={{ value, rating }}
        showText={false}
        isRiskScore={isRiskScore}
        isActive={isActive}
        hideTooltip={!isActive}
        isCVSS={isCVSS}
        centerDots
      />
    </>
  );
};
