import { useSearchParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useAssetOutlet } from "~/pages/inventory/asset";
import { IamActions } from "~/lib/iam";
import { KeyValue, Space } from "~/lib/types";
import { AssetAnnotations } from "~/components/asset-annotations";
import { AssetPlatformOverview } from "~/components/asset-platform-overview";
import { LoadingFailedPage } from "~/components/loading";
import {
  LoadAssetDocument,
  TestIamActionsQuery,
  useSetAssetAnnotationsMutation,
} from "~/operations";
import { ConfigurationAssetOverview } from "./asset-configuration-asset-overview";
import { Format } from "~/lib/date";
import { Asset } from "~/pages/inventory/asset/types";

type AssetOverviewGroup = Asset["overviewGroups"][0];
type AssetOverviewItem = AssetOverviewGroup["items"][0];
type AssetOverviewItemValue = AssetOverviewItem["values"][0];

type Props = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
  isCicd?: boolean;
};

export function AssetConfigurationSection({
  space,
  availablePermissions,
  isCicd = false,
}: Props) {
  const { asset, assetIntegration } = useAssetOutlet();
  const [searchParams, _setSearchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const assetMrn = isCicd ? searchParams.get("jobId") : asset.mrn;

  if (!assetMrn || !asset) {
    return <LoadingFailedPage what="asset" />;
  }

  const hasSetAssetAnnotationsPermission = availablePermissions?.includes(
    IamActions.ASSETS_SETASSETANNOTATIONS,
  );

  const [setAssetAnnotations] = useSetAssetAnnotationsMutation({
    refetchQueries: [LoadAssetDocument],
  });

  const onAnnotationsSubmit = async (annotations: KeyValue[]) => {
    try {
      await setAssetAnnotations({
        variables: {
          input: { spaceMrn: space.mrn, mrn: assetMrn, annotations },
        },
      });
    } catch (error) {
      enqueueSnackbar("Failed to set asset annotations", { variant: "error" });
    }
  };

  return (
    <Box sx={{ mt: 3, boxShadow: (theme) => theme.shadows[1] }}>
      <Box
        sx={{
          py: 2,
          px: 3,
          color: "text.secondary",
          backgroundColor: "background.light",
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
        }}
      >
        <Typography>Configuration</Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: "background.paper",
          px: 3,
          pb: 3,
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4,
        }}
      >
        <Grid container columnSpacing={3}>
          <Grid item xs={12} sm={6}>
            <AssetPlatformOverview asset={asset} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ConfigurationAssetOverview
              asset={asset}
              integration={assetIntegration}
              space={space}
            />
            <AssetAnnotations
              asset={asset}
              readOnly={!hasSetAssetAnnotationsPermission}
              onSubmit={onAnnotationsSubmit}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export const configurationTableSx = {
  "th, td": {
    pt: 0,
    pb: 1,
    borderBottom: "none",
    wordBreak: "break-word",
    lineHeight: "24px",
    verticalAlign: "top",
  },
  "tr:first-of-type td": {
    pt: 1,
  },
  "& .key": {
    color: "text.secondary",
  },
  "& .width-50": {
    width: "50%",
  },
};

export const extractItemKey = (item: AssetOverviewItem) => {
  const val = item.values[0];
  switch (val.__typename) {
    case "AssetOverviewDictValue":
      return val.dictValue?.at(0)?.key || item.name;
    default:
      return item.name;
  }
};
export const extractItemValue = (item: AssetOverviewItem) => {
  return extractVal(item.values[0]);
};

export const extractVal = (val: AssetOverviewItemValue): string => {
  switch (val.__typename) {
    case "AssetOverviewNullValue":
      return "no data";
    case "AssetOverviewBoolValue":
      return val.boolValue ? "Yes" : "No";
    case "AssetOverviewArrayValue":
      return val.arrayValue?.map((v) => extractVal(v)).join(", ") || "";
    case "AssetOverviewDictValue":
      return val.dictValue?.at(0)?.value || "";
    case "AssetOverviewStringValue":
      return val.stringValue;
    case "AssetOverviewTimeValue":
      return Format(val.timeValue);
    case "AssetOverviewReferenceValue":
      return val.displayValue;
    case "AssetOverviewError":
      // if the value starts with "could not retrieve integration", then we want to clean up the error message
      if (val.value.msg.startsWith("could not retrieve integration")) {
        return "integration not found";
      }
      return val.value.msg;
    case "AssetOverviewURLValue":
      return val.displayValue;
  }
};
