import { Score } from "~/lib/types";

// score:
// 80  ..  100   A (100 A+ 95 A 85 A- 80)
// 60  ..   79   B ( 79 B+ 75 B 65 B- 60)
// 30  ..   59   C ( 59 C+ 50 C 40 C- 30)
// 10  ..   29   D ( 29 D+ 25 D 15 D- 10)
//  0  ..   10   F

export type Rating = "U" | "X" | "A" | "B" | "C" | "D" | "F";
export type Status =
  | "critical"
  | "high"
  | "medium"
  | "low"
  | "excellent"
  | "good"
  | "unknown"
  | "error"
  | "none";

export const MajorScore = (score: Score): Rating => {
  if (score.type === 0) return "U";
  if (score.type === 4) return "X";
  if (score.type === 8) return "U";
  if (score.type === 16) return "U";
  if (score.value >= 80) return "A";
  if (score.value >= 60) return "B";
  if (score.value >= 30) return "C";
  if (score.value >= 10) return "D";
  return "F";
};

const rating2statusMap: { [rating in Rating]: Status } = {
  A: "excellent",
  B: "low",
  C: "medium",
  D: "high",
  F: "critical",
  U: "unknown",
  X: "error",
};

export const ScoreStatus = (score: Score): Status => {
  const major = MajorScore(score);
  return rating2statusMap[major];
};
