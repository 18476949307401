import { Route, Routes } from "react-router-dom";
import { Space } from "~/lib/types";
import { TestIamActionsQuery } from "~/operations";
import { Reports } from "~/pages/reporting";
import { AssetReport } from "~/pages/reporting/reports/AssetReport/AssetReport";

type Props = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function ReportingRoutes({ space, availablePermissions }: Props) {
  return (
    <Routes>
      <Route index element={<Reports {...{ space, availablePermissions }} />} />
    </Routes>
  );
}
