import {
  Link as RouterLink,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Box, Breadcrumbs, Grid, Link, Typography } from "@mui/material";
import { Loading, LoadingFailedPage } from "~/components/loading";
import { Space } from "~/lib/types";
import {
  AggregateScoreType,
  CaseStatus,
  GetCasesCountDocument,
  ScoreRating,
  TestIamActionsQuery,
  useGetAggregateScoresQuery,
  useGetCasesCountQuery,
  useGetTicketingIntegrationsQuery,
  useLoadAdvisoryQuery,
} from "~/operations";
import { HomeIcon } from "~/components/icons";
import {
  CveRiskFactors,
  Sources,
  SourcesProps,
} from "~/components/vulnerabilities";
import { AdvisoryContentDetails } from "~/components/vulnerabilities/advisory-content-details";
import { useInventory } from "./inventory/hooks/useInventory";
import { FlagOutlined, Radar } from "@mui/icons-material";
import { SectionHeading, Stats } from "~/components/DetailsPage/components";
import { StatsProps } from "~/components/DetailsPage/components/Stats/Stats";
import { Header } from "~/components/DetailsPage/components/Header";
import { AdvisoryRemediation } from "~/components/vulnerabilities/advisory-remediation";
import { useFindingRiskFactors } from "~/pages/space/security/components/RiskFactors/hooks/useFindingRiskFactors";
import { useCases } from "~/components/cases/hooks";
import { CasesSummary } from "~/components/cases/components/CasesSummary";
import { useExceptionsSelection } from "~/components/exceptions/use-exceptions-selection";
import { CreateCaseButton } from "~/components/cases/components/CreateCaseButton";
import { CreateCasesIntegrationModal } from "~/components/cases/components/CreateCasesIntegrationModal";
import { CreateCaseModal } from "~/components/cases/components/CreateCaseModal";
import { ScrollToTop } from "~/lib/scroll-to-top";
import { CreateCaseMenuButton } from "~/components/cases/components/CreateCaseMenu";
import { VulnerabilityAffectedAssetsAdapter } from "~/pages/space/vulnerabilities/components/AffectedAssets";
import { ScoreBlock } from "./space/security/components/Check/ScoreBlock";
import { ShowMoreSummary } from "~/components/ShowMore/ShowMoreSummary";
import { ScopeType, SpaceOrWorkspaceScope } from "~/hooks/useScope";
import { ExceptionsToolbar } from "~/components/exceptions/exceptions-toolbar";
import { ChangeEvent } from "react";
import { DetailPageTopSection } from "~/components/DetailPageLayouts/DetailPageTopSection";

export type SpaceAdvisoryPageProps = {
  space: Space;
  scope: SpaceOrWorkspaceScope;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export const SpaceAdvisoryPage = ({
  space,
  scope,
  availablePermissions,
}: SpaceAdvisoryPageProps) => {
  const { id = "" } = useParams();
  const [searchParams] = useSearchParams();
  const { state } = useLocation();
  const isMvdSource = state?.source === "mvd";
  const { assetsTotalCount } = useInventory({ scope });

  const { data, loading, error } = useLoadAdvisoryQuery({
    variables: { id },
  });

  const { data: aggScoreData, loading: aggScoreLoading } =
    useGetAggregateScoresQuery({
      variables: {
        entityMrn: scope.mrn || "",
        filter: {
          findingMrn: data?.advisory?.mrn,
        },
      },
      skip: !data?.advisory?.mrn || !scope.mrn,
    });

  const {
    riskFactorsWithDocs,
    riskFactors,
    loading: riskFactorsLoading,
  } = useFindingRiskFactors({
    spaceMrn: space.mrn,
    findingMrn: String(data?.advisory?.mrn || ""),
    scoreType: AggregateScoreType.Advisory,
  });

  /*
    const {
      isRemovingException,
      isSettingException,
      handleSetExceptionModalClose,
      handleRemoveExceptionModalClose,
      handleRemoveExceptionForScopes,
      handleSetExceptionForScopes,
      handleSetScopeForMultipleScopes,
      loading: exceptionsLoading,
    } = useExceptions({
      onSetException: () => {
        setSelectedChecks([]);
      },
      onRemoveException: () => {
        setSelectedChecks([]);
      },
      // TBD when backend is ready
      scopeMrns: [],
      controlMrns: [],
      multipleScopes: true,
      refetchQueries: [],
    });*/

  const {
    handleCreateCaseIntegrationModalOpen,
    handleCreateCaseIntegrationModalClose,
    handleCreateCaseModalOpen,
    handleCreateCaseModalClose,
    isCreateCaseModalOpen,
    isCreateCaseIntegrationModalOpen,
    handleCreateCase,
    hasCreateCasePermissions,
    hasIntegrationCreatePermission,
    hasListCasePermissions,
    loading: createCaseLoading,
  } = useCases({
    createCaseRefetchQueries: [GetCasesCountDocument],
    availablePermissions,
  });

  const { data: casesData } = useGetCasesCountQuery({
    variables: {
      input: {
        scopeMrn: space.mrn,
        findingMrns: [data?.advisory?.mrn as string],
        statuses: [CaseStatus.Open],
      },
    },
    fetchPolicy: "cache-and-network",
    skip:
      !data?.advisory?.mrn ||
      !hasListCasePermissions ||
      scope.type !== ScopeType.Space,
  });

  const { data: ticketingIntegrationsData } = useGetTicketingIntegrationsQuery({
    variables: {
      input: {
        spaceMrn: space.mrn,
      },
    },
    fetchPolicy: "cache-and-network",
    skip: !hasCreateCasePermissions || scope.type !== ScopeType.Space,
  });

  const {
    handleNodeClick,
    handleCancelClick,
    selectedEntities: selectedAssets,
    setSelectedEntities: setSelectedAssets,
  } = useExceptionsSelection();

  if (loading || aggScoreLoading || riskFactorsLoading) {
    return <Loading what="Advisory" />;
  }

  const advisory = data?.advisory;
  const aggScore =
    aggScoreData?.aggregateScores?.__typename === "AggregateScoresConnection"
      ? aggScoreData.aggregateScores.edges?.at(0)?.node
      : undefined;

  // When no aggregateScore exists, we fallback cvssScore of the Advisory.
  let fallbackCvssScore = undefined;
  if (!aggScore) {
    if (advisory?.cvssScore.value) {
      // If the Advisory has no cvssScore - the scoreblock will fallback further to UNKNOWN
      fallbackCvssScore = advisory?.cvssScore;
    }
  }

  if (error || !advisory) {
    return <LoadingFailedPage what="Advisory" />;
  }

  const sourceLinks: SourcesProps["links"] = (advisory?.externalUrls || [])
    .flatMap((link) => link ?? [])
    .map((reference) => ({
      href: reference.url,
      text: reference.title || "Advisory Source Link",
      icon: reference.iconIDEnum,
    }));

  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?${scope.params}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    isMvdSource ? (
      <Link
        key="/space/vulns"
        component={RouterLink}
        to={`/space/vulns?${scope.params}&risktype=ADVISORY`}
        display="flex"
      >
        Mondoo Vulnerability Database
      </Link>
    ) : (
      <Link
        key="/space/vulns"
        component={RouterLink}
        to={`/space/vulnerabilities/advisories?${scope.params}`}
        display="flex"
      >
        Advisories
      </Link>
    ),
    <Typography key={"/space/vulns/advisory/:advisory"}>
      {advisory.title}
    </Typography>,
  ];

  const totalScanned = assetsTotalCount;
  const totalAffected = aggScore?.blastRadius?.affected || 0;

  const stats: StatsProps["stats"] = [
    {
      label: "Scanned",
      value: totalScanned < 0 ? "---" : totalScanned.toString(),
      icon: <Radar fontSize="inherit" />,
    },
    {
      label: "Affected",
      value: totalAffected < 0 ? "---" : totalAffected.toString(),
      icon: <FlagOutlined fontSize="inherit" />,
      onClick: () => {
        document
          .querySelector(`#affected-assets`)
          ?.scrollIntoView({ behavior: "smooth" });
      },
    },
    // {
    //   label: "Updated",
    //   count: -1, // TODO
    //   icon: <ZoomOutMap fontSize="inherit" />,
    // },
  ];

  const hasTicketingIntegration =
    (ticketingIntegrationsData?.ticketingIntegrations?.integrations?.length ||
      0) > 0;

  document.title = `${advisory.title} · Advisories · Mondoo`;

  return (
    <Box>
      <ScrollToTop />
      <DetailPageTopSection
        content={{
          breadcrumbs,
          header: {
            id: "space-advisory-header",
            title: advisory.id,
            created: String(advisory.publishedAt),
            lastModified: String(advisory.modifiedAt),
            riskFactors,
            ...(scope.type === ScopeType.Space
              ? {
                  rightSection: hasTicketingIntegration ? (
                    hasCreateCasePermissions && (
                      <CreateCaseMenuButton
                        onAllAssetsItemClick={() => {
                          setSelectedAssets([
                            {
                              mrn: advisory?.mrn,
                              scopeMrn: space.mrn,
                              groupId: "ALL_ASSETS",
                            },
                          ]);
                          handleCreateCaseModalOpen();
                        }}
                      />
                    )
                  ) : (
                    <CreateCaseButton
                      onClick={handleCreateCaseIntegrationModalOpen}
                    />
                  ),
                }
              : {}),
          },
          summary: advisory.description,
          stats,
          right: (
            <ScoreBlock
              hasScore={Boolean(aggScore)}
              mainScore={aggScore?.riskScore}
              cvssScore={aggScore?.cvss || fallbackCvssScore}
              epssScore={aggScore?.epss}
              blastRadius={aggScore?.blastRadius}
              riskFactors={aggScore?.riskFactors}
              rating={aggScore?.rating || ScoreRating.None}
              hasError={
                Boolean(aggScore) && aggScore?.__typename !== "AggregateScore"
              }
            />
          ),
          left: {
            lower: <Sources id="advisory-sources" links={sourceLinks} />,
          },
        }}
      />

      <Box id="risk-factors" className="section">
        <SectionHeading heading="Risk assessment" />
        <Box className="section-content">
          <CveRiskFactors
            id="cve-risk-factors"
            cvssScore={advisory.cvssScore}
            riskFactors={riskFactorsWithDocs}
          />
        </Box>
      </Box>

      <AdvisoryContentDetails advisoryId={advisory.id} scope={scope} />

      <AdvisoryRemediation advisoryId={advisory.id} scopeMrn={scope.mrn} />

      <VulnerabilityAffectedAssetsAdapter
        scope={scope}
        vulnerabilityId={advisory.id}
        vulnerabilityScoreType="Advisory"
        selectionProps={
          scope.type === ScopeType.Space
            ? {
                selectedEntities: selectedAssets,
                hasSelectPermissions: hasCreateCasePermissions,
                onCheckboxClick: handleNodeClick,
                getIsRowSelected: (rowAssetMrn) => {
                  return Boolean(
                    selectedAssets.find(
                      (asset) => asset.scopeMrn === rowAssetMrn,
                    ),
                  );
                },
                onCheckAll: (checked, edges) => {
                  if (checked) {
                    setSelectedAssets(
                      (edges || []).map((edge) => ({
                        groupId: "",
                        scopeMrn: edge?.mrn || "",
                        mrn: advisory?.mrn,
                        exception: null,
                      })),
                    );
                  } else {
                    setSelectedAssets([]);
                  }
                },
                onCheckboxChange: async (
                  e: ChangeEvent<HTMLInputElement>,
                  checked: boolean,
                  asset,
                ) => {
                  const nextSelected = selectedAssets.filter(
                    (s) => s.scopeMrn !== asset?.mrn,
                  );
                  if (checked) {
                    nextSelected.push({
                      groupId: "",
                      mrn: advisory?.mrn,
                      scopeMrn: asset?.mrn || "",
                      exception: null,
                    });
                  }

                  setSelectedAssets(nextSelected);
                },
                renderToolbar: (totalCount) => {
                  return (
                    selectedAssets.filter((a) => a.groupId !== "ALL_ASSETS")
                      .length > 0 && (
                      <ExceptionsToolbar
                        target="asset"
                        onCreateCaseClick={
                          hasTicketingIntegration
                            ? hasCreateCasePermissions
                              ? handleCreateCaseModalOpen
                              : undefined
                            : handleCreateCaseIntegrationModalOpen
                        }
                        onCancel={handleCancelClick}
                        selectedEntities={selectedAssets}
                        totalCount={totalCount}
                      />
                    )
                  );
                },
              }
            : undefined
        }
      />
      {scope.type === ScopeType.Space && (
        <>
          <CreateCasesIntegrationModal
            open={isCreateCaseIntegrationModalOpen}
            onClose={handleCreateCaseIntegrationModalClose}
            space={space}
            redirectTo={`${location.pathname}?${searchParams}`}
            hasIntegrationCreatePermission={hasIntegrationCreatePermission}
          />
          <CreateCaseModal
            open={isCreateCaseModalOpen}
            target="asset"
            loading={loading || createCaseLoading}
            onClose={() => {
              handleCreateCaseModalClose();
              setSelectedAssets([]);
            }}
            onSave={handleCreateCase}
            selectedAssets={selectedAssets}
            space={space}
            hasCreateCasePermissions={hasCreateCasePermissions}
          />
        </>
      )}
    </Box>
  );
};
