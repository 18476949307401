import { Link as RouterLink, useSearchParams } from "react-router-dom";
import { Button } from "@mui/material";
import { ArrowForwardIcon } from "~/components/icons";

export function ShowAwsAssetsButton({
  integrationMrn,
  show = false,
}: {
  integrationMrn: string;
  show?: boolean;
}) {
  const [searchParams] = useSearchParams();

  const getAssetsUrl = (integrationMrn: string) => {
    const queryterms = JSON.stringify({
      "mondoo.com/integration-mrn": integrationMrn,
    });
    searchParams.set("queryTerms", queryterms);
    return `/space/inventory?${searchParams}`;
  };

  if (!show) {
    return null;
  }

  return (
    <Button
      color="secondary"
      component={RouterLink}
      to={getAssetsUrl(integrationMrn)}
      endIcon={<ArrowForwardIcon />}
    >
      Show Assets
    </Button>
  );
}
