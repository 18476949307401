import { Grid2, TableCell, TableRow, useTheme } from "@mui/material";
import { Fragment } from "react/jsx-runtime";
import {
  ImpactCell,
  RiskFactorsCell,
  PublishedCell,
  TitleCell,
} from "~/components/Cells";
import { GenericTable } from "~/components/GenericTable/GenericTable";
import { ScoreRating } from "~/operations";
import { SectionHeading } from "../../components";
import { NoItemsSection } from "../components";
import { DynamicIcon } from "~/components/icons/DynamicIcon";
import { PackageIcon } from "~/components/icons";
import { useAssetReport } from "./hooks/useAssetReport";

export function VulnerablePackages() {
  const { packageScores } = useAssetReport();
  const hasVulnerablePackages = Boolean(packageScores?.length);
  return (
    <Fragment>
      <SectionHeading heading="Vulnerable Packages" disableUpperPadding />
      <Grid2 size={12} sx={{ my: 3 }}>
        {!hasVulnerablePackages ? (
          <NoItemsSection target="vulnerable packages" />
        ) : (
          <GenericTable
            space={undefined}
            items={0}
            tableName="ASSET_REPORT_VULNERABLE_PACKAGES"
            validSortFields={[]}
            printView
          >
            {packageScores?.map((item) => {
              const { node } = item;

              return (
                <TableRow key={node?.id}>
                  <ImpactCell
                    impact={node?.riskScore || 0}
                    rating={node?.rating || ScoreRating.Critical}
                    isActive
                  />
                  <TitleCell
                    title={node?.packageName || ""}
                    icon={
                      <DynamicIcon
                        iconId={node?.iconIDEnum}
                        defaultIcon={PackageIcon}
                      />
                    }
                  />

                  <TableCell>{node?.installedVersion}</TableCell>
                  <TableCell>{node?.availableVersion}</TableCell>
                  <RiskFactorsCell riskFactors={node?.riskFactors} />
                  <PublishedCell date={node?.firstFound || "Never"} />
                </TableRow>
              );
            })}
          </GenericTable>
        )}
      </Grid2>
    </Fragment>
  );
}
