import { Grid, Typography } from "@mui/material";
import { AccessTimeIcon, EditIcon } from "~/components/icons";
import { FormatDate } from "~/lib/date";
import { Flex } from "~/components/Flex";
import { RiskFactorStats } from "~/operations";
import { RiskFactorsIcons } from "~/pages/space/security/components/RiskFactors/RiskFactorsIcons";
import { ReactNode } from "react";

export type HeaderProps = {
  id: string;
  icon?: ReactNode;
  title: string;
  created?: string | null;
  lastModified?: string | null;
  riskFactors?: (RiskFactorStats | null)[] | null | undefined;
  rightSection?: ReactNode;
  tags?: ReactNode;
};

export const Header = ({
  id,
  icon,
  title,
  lastModified,
  created,
  riskFactors,
  rightSection,
  tags,
}: HeaderProps) => {
  return (
    <Grid id={id} container spacing={2} sx={{ mb: 3, flexWrap: "nowrap" }}>
      <Grid
        item
        xs={12}
        sm={10}
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <Flex alignItems="center" gap={2}>
          {icon}
          <Typography
            id="title"
            variant="h4"
            fontWeight={700}
            textTransform="uppercase"
          >
            {title}{" "}
            <RiskFactorsIcons
              riskFactors={riskFactors}
              shouldLimit={false}
              showBackgroundColor
            />
          </Typography>
        </Flex>
        <Flex mt={icon ? "12px" : 0}>
          {created && (
            <Typography
              id="published-at"
              variant="caption"
              color="text.secondary"
              fontWeight={700}
              sx={{ mr: 3, display: "inline-flex", alignItems: "center" }}
            >
              <AccessTimeIcon fontSize="inherit" sx={{ mr: 0.5 }} />
              Created {FormatDate(created)}
            </Typography>
          )}
          {lastModified && (
            <Typography
              id="modified-at"
              variant="caption"
              color="text.secondary"
              fontWeight={700}
              sx={{ display: "inline-flex", alignItems: "center" }}
            >
              <EditIcon fontSize="inherit" sx={{ mr: 0.5 }} />
              Last modified {FormatDate(lastModified)}
            </Typography>
          )}
          {tags}
        </Flex>
      </Grid>
      <Flex
        sx={{
          flex: "1 0 auto",
          alignSelf: "center",
          justifyContent: "flex-end",
        }}
      >
        {rightSection}
      </Flex>
    </Grid>
  );
};
