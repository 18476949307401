import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  gql,
  HttpLink,
  from,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PrintAsset } from "~/components/print/print-asset";
import { AssetReport } from "~/pages/reporting/reports/AssetReport/AssetReport";
import { MondooThemeProvider } from "~/providers/mondoo-theme";
import { selectedApiEndpoint } from "~/providers/viewer";
import { lightTheme } from "~/themes";
import { createMondooTheme } from "~/themes/createTheme";

export function ReportingApp() {
  const [bearer, setBearer] = useState<null | string>(null);

  useEffect(() => {
    const fetchHeaders = async () => {
      try {
        // this is taken from https://stackoverflow.com/a/25490531
        setBearer(
          document.cookie.match("(^|;)\\s*apiToken\\s*=\\s*([^;]+)")?.pop() ||
            "",
        );
      } catch (error) {
        console.error("Error fetching headers:", error);
      }
    };

    fetchHeaders();
  }, []);

  const httpLink = new HttpLink({
    uri: selectedApiEndpoint() + "/query",
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        Authorization: bearer != null ? bearer : "",
      },
    };
  });

  const client = new ApolloClient({
    link: from([authLink, httpLink]),
    cache: new InMemoryCache(),
  });

  const theme = createMondooTheme(lightTheme);

  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <MondooThemeProvider defaultTheme={theme}>
          <Routes>
            <Route path="generated-report">
              <Route path="asset" element={<AssetReport />} />
              <Route
                path="*"
                element={<div>There is no asset avialable</div>}
              />
            </Route>
            <Route path="*" element={<div>fallback</div>} />
          </Routes>
        </MondooThemeProvider>
      </BrowserRouter>
    </ApolloProvider>
  );
}
