import { Box, Typography, Grid, Button, styled } from "@mui/material";
import { Space } from "~/lib/types";
import { useColorMode } from "~/providers/color-mode";
import SelectFrameworks from "./images/select-frameworks.svg";
import SelectFrameworksLight from "./images/select-frameworks-light.svg";
import ScanInfra from "./images/scan-infra.svg";
import ScanInfraLight from "./images/scan-infra-light.svg";
import ExportReports from "./images/export-reports.svg";
import ExportReportsLight from "./images/export-reports-light.svg";

import {
  BsiIcon,
  CISIcon,
  CsaIcon,
  HIPAAIcon,
  ISOIcon,
  NistIcon,
  PCIIcon,
  SOCIcon,
  VdaIcon,
} from "../icons";
import { motion } from "framer-motion";

type ComplianceEntrySplashProps = {
  space: Space;
  setIsAdding: (isAdding: boolean) => void;
};

const icons = [
  { id: "SOCIcon", mark: <SOCIcon sx={{ fontSize: 48 }} /> },
  { id: "BsiIcon", mark: <BsiIcon sx={{ fontSize: 48 }} /> },
  { id: "CISIcon", mark: <CISIcon sx={{ fontSize: 48 }} /> },
  { id: "CsaIcon", mark: <CsaIcon sx={{ fontSize: 48 }} /> },
  { id: "ISOIcon", mark: <ISOIcon sx={{ fontSize: 48 }} /> },
  { id: "HIPAAIcon", mark: <HIPAAIcon sx={{ fontSize: 48 }} /> },
  { id: "NistIcon", mark: <NistIcon sx={{ fontSize: 48 }} /> },
  { id: "PCIIcon", mark: <PCIIcon sx={{ fontSize: 48 }} /> },
  { id: "VdaIcon", mark: <VdaIcon sx={{ fontSize: 48 }} /> },
];

export function ComplianceEntrySplash({
  space,
  setIsAdding,
}: ComplianceEntrySplashProps) {
  const { mode } = useColorMode();
  return (
    <Box
      id="compliance-splash-screen"
      m="auto"
      mt={0}
      mb={5}
      component={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      sx={{ width: 1 }}
    >
      <Typography
        variant="h5"
        color="text.secondary"
        fontWeight={700}
        lineHeight="48px"
        textAlign="center"
        mb={2}
      >
        Streamline your compliance process with the Mondoo Compliance Hub
      </Typography>
      <Grid
        container
        columnSpacing={3}
        justifyContent="center"
        alignItems="center"
      >
        {icons.map((icon) => (
          <Grid item key={icon.id}>
            {icon.mark}
          </Grid>
        ))}
      </Grid>

      <Grid
        container
        justifyContent="center"
        alignItems="flex-start"
        columnSpacing={{ sm: 3 }}
        rowSpacing={{ xs: 8, sm: 2 }}
        py={10}
      >
        <Grid
          item
          xs={12}
          sm={4}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            component="img"
            src={mode === "light" ? SelectFrameworksLight : SelectFrameworks}
            width="auto"
            maxHeight={144}
            mb={2}
          />
          <StyledText>
            Select compliance frameworks to enable automatic evidence gathering.
          </StyledText>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            component="img"
            src={mode === "light" ? ScanInfraLight : ScanInfra}
            width="auto"
            maxHeight={144}
            mb={2}
          />
          <StyledText>
            Set up your integrations to scan your infrastructure.
          </StyledText>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            component="img"
            src={mode === "light" ? ExportReportsLight : ExportReports}
            width="auto"
            maxHeight={144}
            mb={2}
          />
          <StyledText>
            Export reports to ace your audit. Never manually gather evidence
            again.
          </StyledText>
        </Grid>
      </Grid>
      <Button
        data-name="framework-add-first-integration"
        onClick={() => setIsAdding(true)}
        sx={{
          display: "block",
          ml: "auto",
          mr: "auto",
          px: 2,
          background: (theme) => theme.palette.primary.gradient,
          color: "common.white",
        }}
      >
        SELECT COMPLIANCE FRAMEWORK
      </Button>
    </Box>
  );
}

const StyledText = styled(Typography)`
  color: ${(p) => p.theme.palette.text.secondary};
  font-weight: 600;
  line-height: 24px;
  text-align: center;
`;
