import { ScoreRating, ScoreResultType, ScoreState } from "~/operations";
import { useTheme } from "@mui/material";
import { ImpactData } from "../types";
import { getCheckColor } from "~/components/print/CheckResultIcon";

type UseCheckResultByTextProps = {
  scoreState: ScoreState;
  rating: ScoreRating;
  scoreResult: ScoreResultType;
};

export function useCheckResultByText({
  scoreState,
  rating,
  scoreResult,
}: UseCheckResultByTextProps) {
  const theme = useTheme();
  const isActive =
    scoreState === ScoreState.Disabled || ScoreState.Snoozed ? false : true;

  const color = getCheckColor({ theme, scoreState, result: scoreResult });

  function getImpactData(impact: ScoreRating): ImpactData {
    let normalizedRating = impact.toLowerCase();

    if (normalizedRating === "none") {
      return {
        rating: 1,
      };
    } else if (normalizedRating === "low") {
      return {
        rating: 2,
      };
    } else if (normalizedRating === "medium") {
      return {
        rating: 3,
      };
    } else if (normalizedRating === "high") {
      return {
        rating: 4,
      };
    } else {
      return {
        rating: 5,
      };
    }
  }

  return {
    isActive,
    label: rating,
    impactNumber: getImpactData(rating),
    result: scoreResult,
    color,
  };
}
