import {
  Box,
  Breadcrumbs,
  Button,
  Grid2 as Grid,
  Link,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { AddButton } from "~/components/add-button";
import { HomeIcon, NavDrawerFleetIcon, RadarIcon } from "~/components/icons";
import {
  INITIAL_PAGE_RANGE,
  Pagination,
  PaginationRange,
} from "~/components/pagination";
import { useSearch } from "~/components/search/useSearch";
import { FilterBar } from "~/pages/compliance/filter-bar";
import { Space } from "~/lib/types";
import { TestIamActionsQuery, useGetWorkspacesQuery } from "~/operations";
import { Flex } from "~/components/Flex";
import EmptyWorkspaces from "~/images/empty-workspaces.png";
import { WorkspaceCard } from "~/pages/space/Workspaces/components";

export type WorkspacesEntryPageProps = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function WorkspacesEntryPage({
  space,
  availablePermissions,
}: WorkspacesEntryPageProps) {
  const { searchFilters, handleFilterQuery } = useSearch();
  const [pageItems, setPageItems] =
    useState<PaginationRange>(INITIAL_PAGE_RANGE);

  const { data, loading, fetchMore } = useGetWorkspacesQuery({
    variables: {
      input: {
        scopeMrn: space.mrn,
        query: searchFilters.join(" "),
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const workspaces = data?.workspaces.edges || [];
  const totalWorkspaces = data?.workspaces.totalCount || 0;
  const hasWorkspaces = totalWorkspaces > 0;

  // TODO: update IamActions
  const hasCreateWorkspacePermission = true;

  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?spaceId=${space.id}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Typography key="/space/workspaces">Workspaces</Typography>,
  ];

  document.title = `Workspaces · ${space.name} · Mondoo`;

  return (
    <Box>
      <Breadcrumbs sx={{ mb: 3, overflowWrap: "anywhere" }} separator="›">
        {breadcrumbs}
      </Breadcrumbs>
      {/* Heading */}
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 4 }}>
        <Grid size={12}>
          <Typography
            variant="h4"
            fontWeight={700}
            textTransform="uppercase"
            sx={{ mb: 3 }}
          >
            Workspaces
          </Typography>
        </Grid>
        {hasCreateWorkspacePermission && (
          <Grid size={12}>
            <AddButton
              id="space"
              href={`/space/workspaces/new?spaceId=${space.id}`}
            />
          </Grid>
        )}
      </Box>
      {/* TODO: Query.workspaces does not support search/filter yet */}
      {!loading && hasWorkspaces && (
        <Grid size={{ xs: 12 }}>
          <FilterBar
            searchId="workspaces-search"
            placeholder="workspaces"
            searchFilters={searchFilters}
            handleFilterQuery={handleFilterQuery}
          />
        </Grid>
      )}
      {!loading && !hasWorkspaces && (
        <Flex
          className="empty-workspaces"
          sx={{ flexDirection: "column", justifyContent: "center", gap: 6 }}
        >
          <Box
            component="img"
            src={EmptyWorkspaces}
            width={380}
            sx={{ mx: "auto", mt: 10 }}
          />
          <Flex sx={{ flexDirection: "column", justifyContent: "center" }}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 700,
                textAlign: "center",
                textTransform: "uppercase",
              }}
            >
              Create your first workspace!
            </Typography>
            <Typography color="text.secondary" sx={{ textAlign: "center" }}>
              Structure projects, monitor a specific set of assets, or do
              something entirely different -- it's up to you!
              <br /> A workspace dynamically pulls in all assets in this space
              that match criteria you define.
            </Typography>
            <Button
              component={RouterLink}
              to={`/space/workspaces/new?spaceId=${space.id}`}
              sx={{
                mx: "auto",
                mt: 3,
                px: 2,
                background: (theme) => theme.palette.primary.gradient,
                color: "common.white",
              }}
            >
              Create Workspace
            </Button>
          </Flex>
        </Flex>
      )}
      {!loading && hasWorkspaces && (
        <>
          <Grid className="workspaces-list" container spacing={3} mb={3}>
            {workspaces.slice(pageItems.from, pageItems.to).map((edge) => {
              const assetGroups =
                edge.node.assetGroupStats
                  .flatMap((ag) => (ag.count > 0 ? ag : []))
                  .map((ag) => ({
                    href: `/space/inventory?spaceId=${space.id}&workspaceId=${edge.node.mrn}&groupType=${ag.groupType}`,
                    ...ag,
                  }))
                  .sort((a, b) => b.count - a.count) || [];
              const assetCount = assetGroups.reduce(
                (total, group) => total + group.count,
                0,
              );
              const priorityFindingsCount = edge.node.priorityFindings;
              return (
                <Grid
                  size={{ xs: 12, sm: 6, md: 4 }}
                  key={edge.node.mrn}
                  className="workspaces-list-item"
                >
                  <WorkspaceCard
                    title={edge.node.name}
                    href={`/space/overview?spaceId=${space.id}&workspaceId=${edge.node.mrn}`}
                    description={edge.node.description || ""}
                    assetGroups={assetGroups}
                    remainingAssetGroupsHref={`/space/inventory?spaceId=${space.id}&workspaceId=${edge.node.mrn}`}
                    statItems={[
                      {
                        icon: NavDrawerFleetIcon,
                        label: "Asset count",
                        value: assetCount,
                      },
                      {
                        icon: RadarIcon,
                        label: "Priority findings",
                        value: priorityFindingsCount,
                      },
                    ]}
                  />
                </Grid>
              );
            })}
          </Grid>
          <Pagination
            fetchMore={fetchMore}
            pageInfo={data?.workspaces.pageInfo}
            totalCount={totalWorkspaces}
            setPageItems={setPageItems}
            customPageSizes={[12, 24, 48]}
          />
        </>
      )}
    </Box>
  );
}
