import { TableCell } from "@mui/material";
import { CommonCellProps } from "~/pages/inventory/components/Vulnerabilities/types";
import { ImpactUpdated } from "~/components/impact/Updated/impact-updated";

export const ImpactCell = ({ cve, ...props }: CommonCellProps) => {
  return (
    <TableCell width="10%" data-testid={props["data-testid"]}>
      <ImpactUpdated
        impact={{
          value: cve.riskScore || 0,
          rating: cve.rating,
        }}
        isRiskScore
      />
    </TableCell>
  );
};
