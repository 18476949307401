import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ScoreRating, useLoadAdvisoryCvesQuery } from "~/operations";
import { DataTable } from "../data-table";
import { cvssVersion } from "../cvss";
import { FormatDate, FormatRelativeDate } from "~/lib/date";
import { SectionHeading } from "~/components/DetailsPage/components";
import { EmptySection } from "~/components/vulnerabilities/empty-section";
import { DeprecatedRiskFactorsCell } from "./DeprecatedRiskFactorsCell";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";
import { ImpactUpdated } from "~/components/impact/Updated/impact-updated";

export type AdvisoryContentDetailsProps = {
  id?: string;
  advisoryId: string;
  scope: SpaceOrWorkspaceScope;
};

export function AdvisoryContentDetails({
  id,
  advisoryId,
  scope,
}: AdvisoryContentDetailsProps) {
  const { data } = useLoadAdvisoryCvesQuery({
    variables: { advisoryId, scopeMrn: scope.mrn },
    skip: !scope.mrn,
  });

  const cves = data?.advisory?.cves || [];
  const sortedCves = [...cves].sort((a, b) => {
    if (
      b.vulnerabilityRiskFactors.knownExploited !==
      a.vulnerabilityRiskFactors.knownExploited
    ) {
      return b.vulnerabilityRiskFactors.knownExploited ? 1 : 0;
    }
    return (a.riskScore || 0) - (b.riskScore || 0);
  });

  return (
    <Box id="content-details">
      <SectionHeading heading="Content details" />
      {cves.length > 0 ? (
        <Box id={id}>
          <DataTable>
            <TableHead>
              <TableRow>
                <TableCell>Risk</TableCell>
                <TableCell>Vulnerability</TableCell>
                <TableCell>Risk factors</TableCell>
                <TableCell>Published</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedCves.map((cve) => (
                <TableRow
                  key={cve.id}
                  component={RouterLink}
                  to={`/space/vulns/CVE/${cve.id}?${scope.params}`}
                >
                  <TableCell
                    sx={{
                      ".impact": {
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                      },
                      ".impact-label": {
                        m: 0,
                      },
                      ".impact-rating": {
                        display: "flex",
                        alignItems: "center",
                        height: (theme) => theme.spacing(2.5),
                      },
                    }}
                  >
                    <ImpactUpdated
                      impact={{
                        value: cve?.riskScore || 0,
                        rating: cve?.rating || ScoreRating.None,
                      }}
                      isRiskScore
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      maxWidth: "1px",
                      width: "100%",
                      fontSize: "14px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                      }}
                    >
                      <Box
                        sx={{
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        <Typography
                          component="span"
                          fontSize="inherit"
                          fontWeight="bold"
                        >
                          {cve.id}:
                        </Typography>{" "}
                        <Typography component="span" fontSize="inherit">
                          {cve.summary}
                        </Typography>
                      </Box>
                      <Box>
                        <Box
                          sx={{
                            display: "inline-block",
                            backgroundColor: "background.light",
                            color: "text.secondary",
                            p: 0.5,
                            my: -0.5,
                            borderRadius: 1,
                            fontSize: "12px",
                          }}
                        >
                          CVSS{cvssVersion(cve.cvssScore.vector)}{" "}
                          <strong>
                            {(cve.cvssScore.value / 10).toFixed(1)}
                          </strong>
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                  <DeprecatedRiskFactorsCell
                    vulnerabilityRiskFactors={cve.vulnerabilityRiskFactors}
                  />
                  <TableCell>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                      }}
                    >
                      <Box
                        sx={{
                          fontSize: "14px",
                          fontWeight: "normal",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {cve.publishedAt && FormatRelativeDate(cve.publishedAt)}
                      </Box>
                      <Box
                        sx={{
                          fontSize: "12px",
                          fontWeight: "normal",
                          whiteSpace: "nowrap",
                          color: "text.secondary",
                        }}
                      >
                        {FormatDate(cve.publishedAt)}
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </DataTable>
        </Box>
      ) : (
        <EmptySection
          id="advisory-content-details"
          text="No vulnerabilities found"
        />
      )}
    </Box>
  );
}
