import { Box, TableCell, Typography } from "@mui/material";
import { Flex } from "../Flex";
import { Fragment, ReactNode } from "react";
import { FirewatchChip } from "../FirewatchRow/ChipText";
import { Tag } from "~/operations";

type SecurityScoreCellProps = {
  title: string;
  detailText?: string;
  icon?: ReactNode;
  tags?: (Tag | null)[] | null | undefined;
  "data-testid"?: string;
  options?: {
    fontSize?: number;
  };
};

export function TitleCell({
  title,
  icon,
  tags,
  ...props
}: SecurityScoreCellProps) {
  const _tags = tags?.filter((x): x is Tag => x !== null);
  return (
    <TableCell data-testid={props["data-testid"]}>
      <Flex gap={1.25}>
        {icon && icon}
        <Typography
          sx={{
            fontSize: props.options?.fontSize ? props.options?.fontSize : 14,
            fontWeight: 700,
            lineHeight: "16px",
          }}
        >
          {title}
          {props.detailText && (
            <Fragment>
              {" "}
              <Typography
                component="span"
                sx={{ fontSize: 14, fontWeight: 400 }}
              >
                {props.detailText}
              </Typography>
            </Fragment>
          )}
        </Typography>
      </Flex>
      <Box display="flex" gap={1} mt={1}>
        {_tags?.map((tag) => <FirewatchChip key={tag.key} tag={tag} />)}
      </Box>
    </TableCell>
  );
}
