import { useEffect, useState } from "react";
import { CheckboxProps } from "@mui/material";
import { useSnackbar } from "notistack";
import { Space } from "~/lib/types";
import {
  ComplianceControl,
  ComplianceFramework,
  DocumentMetadata,
  DocumentMetadataConnection,
  ListDocumentsDocument,
  useDeleteDocumentMutation,
  useListDocumentsLazyQuery,
  useListDocumentsQuery,
  useRequestDocumentDownloadUrlMutation,
} from "~/operations";
import { pluralize } from "~/lib/pluralize";
import { getError } from "~/lib/handle-error";
import { downloadFromUrl } from "~/lib/download-from-url";
import { NetworkStatus } from "@apollo/client";

export interface UseReportExports {
  networkPolling: boolean;
  selectedReports: ComplianceFramework["mrn"][] | ComplianceControl["mrn"][];
  handle: {
    selectionChange: (checked: CheckboxProps["checked"], mrn: string) => void;
    selectAll: CheckboxProps["onChange"];
    downloadSingle: (report: DocumentMetadata) => void;
    delete: () => void;
    deleteSingle: (documentMrn: DocumentMetadata["mrn"]) => void;
    cancel: () => void;
    fetchMore: ReturnType<typeof useListDocumentsQuery>["fetchMore"];
  };
  documentList: {
    data: DocumentMetadataConnection | undefined;
    reports: DocumentMetadata[];
    error: ReturnType<typeof useListDocumentsQuery>["error"];
    loading: ReturnType<typeof useListDocumentsQuery>["loading"];
  };
}

type UseReportProps = {
  space: Space;
};

export const useReports = ({ space }: UseReportProps): UseReportExports => {
  const [shouldFetchReports, setShouldFetchReports] = useState<boolean>(true);
  const [selectedReports, setSelectedReports] = useState<string[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  const [
    listDocumentsLazyQuery,
    { data, error, loading, fetchMore, networkStatus },
  ] = useListDocumentsLazyQuery({
    variables: {
      scopeMrn: space.mrn,
      first: 50,
      after: null,
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    pollInterval: 30000,
  });

  const [deleteDocumentMutation] = useDeleteDocumentMutation({
    refetchQueries: [ListDocumentsDocument],
  });

  const [requestDocumentDownloadUrlMutation] =
    useRequestDocumentDownloadUrlMutation();

  useEffect(() => {
    if (shouldFetchReports) {
      listDocumentsLazyQuery();
      setShouldFetchReports(false);
    }

    return () => {
      setShouldFetchReports(true);
    };
  }, []);

  const handleSelectionChange = (
    checked: CheckboxProps["checked"],
    mrn: string,
  ) => {
    let nextSelection: typeof selectedReports = [];
    if (checked) {
      nextSelection = [...selectedReports, mrn];
    } else {
      nextSelection = selectedReports.filter((x) => x !== mrn);
    }

    setSelectedReports(nextSelection);
  };

  const handleSelectAll: CheckboxProps["onChange"] = (_e, checked) => {
    let nextSelection: typeof selectedReports = [];
    if (checked) {
      nextSelection =
        data?.listDocuments?.edges?.map((edge) => edge.node.mrn) || [];
    }

    setSelectedReports(nextSelection);
  };

  const handleDownloadSingle = async (report: DocumentMetadata) => {
    try {
      const request = await requestDocumentDownloadUrlMutation({
        variables: { documentMrn: report.mrn },
      });
      let url = request.data?.requestDocumentDownloadUrl?.downloadUrl;
      if (url) {
        downloadFromUrl(url, report.name);
      } else {
        throw new Error("No download URL was returned");
      }
    } catch (e) {
      const errorMessage = getError(e);
      enqueueSnackbar(
        `Something went wrong while attempting to download: ${errorMessage}`,
        { variant: "error" },
      );
    }
  };

  const handleDelete = () => {
    try {
      selectedReports.forEach(async (documentMrn) => {
        await deleteDocumentMutation({
          variables: {
            documentMrn,
          },
        });
      });
      enqueueSnackbar(
        `Successfully deleted ${selectedReports.length} ${pluralize(
          "report",
          selectedReports.length,
        )}`,
        { variant: "success" },
      );
      setSelectedReports([]);
    } catch (e) {
      const errorMessage = getError(e);
      enqueueSnackbar(
        `Something went wrong while attempting to delete ${pluralize(
          "report",
          selectedReports.length,
        )}: ${errorMessage}`,
        { variant: "error" },
      );
    }
  };

  const handleDeleteSingle = async (documentMrn: DocumentMetadata["mrn"]) => {
    try {
      await deleteDocumentMutation({
        variables: {
          documentMrn,
        },
      });
      enqueueSnackbar(`Successfully deleted report`, { variant: "success" });
      //Remove the deleted report from the selected reports in case it
      // was selected previously
      const nextSelection = selectedReports.filter((x) => x !== documentMrn);
      setSelectedReports(nextSelection);
    } catch (e) {
      const errorMessage = getError(e);
      enqueueSnackbar(
        `Something went wrong while attempting to delete your report: ${errorMessage}`,
        { variant: "error" },
      );
    }
  };

  const handleCancel = () => {
    setSelectedReports([]);
  };

  return {
    networkPolling: networkStatus === NetworkStatus.poll,
    selectedReports,
    handle: {
      selectionChange: handleSelectionChange,
      selectAll: handleSelectAll,
      downloadSingle: handleDownloadSingle,
      delete: handleDelete,
      deleteSingle: handleDeleteSingle,
      cancel: handleCancel,
      fetchMore: fetchMore,
    },
    documentList: {
      data: data?.listDocuments,
      reports: data?.listDocuments?.edges?.map((edge) => edge.node) || [],
      error,
      loading,
    },
  };
};
