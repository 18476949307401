import { TableCell } from "@mui/material";
import { SoftwareDetailsCommonCellProps } from "./types";
import { ImpactUpdated } from "~/components/impact/Updated/impact-updated";

type ImpactCellProps = {
  hideTooltip?: boolean;
};

export const ImpactCell = ({
  advisory,
  cve,
  hideTooltip = false,
  ...props
}: SoftwareDetailsCommonCellProps & ImpactCellProps) => {
  const target = advisory ?? cve;
  return (
    <TableCell data-testid={props["data-testid"]}>
      <ImpactUpdated
        isCVSS
        impact={{
          value: target.cvssScore.value,
          rating: target?.cvssScore.rating,
        }}
      />
    </TableCell>
  );
};
