import {
  OrderDirection,
  useGetVulnerabilityScoresQuery,
  VulnerabilityScoreOrderField,
  VulnerabilityScoreType,
} from "~/operations";
import { TableContainer, Table, TableBody, TableRow } from "@mui/material";
import { LoadingPage, LoadingFailedPage } from "~/components/loading";
import { ImpactCell } from "~/pages/inventory/components/Advisories/AdvisoriesTable/ImpactCell";
import { AdvisoryCell } from "~/pages/inventory/components/Advisories/AdvisoriesTable/AdvisoryCell";
import { PublishCell } from "~/pages/inventory/components/Advisories/AdvisoriesTable/PublishCell";
import { Header } from "~/types/table";
import { TableHead } from "~/pages/inventory/components/TableHead";
import { RiskFactorsCell } from "../Cells";

export type PrintAdvisoriesProps = {
  assetMrn: string;
};

export function PrintAdvisories(props: PrintAdvisoriesProps) {
  const { assetMrn } = props;

  const { data, error, loading } = useGetVulnerabilityScoresQuery({
    variables: {
      entityMrn: assetMrn,
      first: 100,
      orderBy: {
        direction: OrderDirection.Desc,
        field: VulnerabilityScoreOrderField.CvssScore,
      },
      filter: {
        scoreType: VulnerabilityScoreType.Advisory,
      },
    },
  });

  if (loading) {
    return <LoadingPage what="packages" />;
  }

  const advisorySummaries =
    data?.vulnerabilityScores?.__typename === "VulnerabilityScoresConnection"
      ? data?.vulnerabilityScores
      : { totalCount: 0, edges: [] };

  const advisories =
    advisorySummaries?.edges?.flatMap((e) => e.node ?? []) || [];

  if (error || !advisories) {
    return <LoadingFailedPage what="packages" />;
  }

  const tableHeaders: Header[] = [
    {
      id: "IMPACT",
      label: "Impact",
      options: { width: "10%" },
      sortable: false,
    },
    {
      id: "ADVISORY",
      label: "Advisory",
      options: { width: "50%" },
      sortable: false,
    },
    {
      id: "RISK_FACTORS",
      label: "Risk factors",
      sortable: false,
    },
    {
      id: "PUBLISHED",
      label: "Published",
      sortable: false,
      options: {
        width: "15%",
      },
    },
  ];

  return (
    <TableContainer>
      <Table>
        <TableHead tableHeaders={tableHeaders} />
        <TableBody>
          {advisories.map((advisory) => {
            return (
              <TableRow key={advisory.id}>
                <ImpactCell advisory={advisory} hideTooltip />
                <AdvisoryCell advisory={advisory} />
                <RiskFactorsCell riskFactors={advisory.riskFactors} />
                <PublishCell advisory={advisory} />
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
