import {
  Box,
  Breadcrumbs,
  Grid2 as Grid,
  Link,
  Typography,
} from "@mui/material";
import { Flex } from "~/components/Flex";
import {
  Link as RouterLink,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { HomeIcon } from "~/components/icons";
import { Space } from "~/lib/types";
import {
  GetWorkspacesDocument,
  TestIamActionsQuery,
  useCreateWorkspaceMutation,
  WorkspaceSelectionConditionInput,
  WorkspaceSelectionConditionIntField,
  WorkspaceSelectionConditionKeyValueField,
  WorkspaceSelectionConditionKeyValueOperator,
  WorkspaceSelectionConditionNumericOperator,
  WorkspaceSelectionConditionOperator,
  WorkspaceSelectionConditionRatingField,
  WorkspaceSelectionConditionRatingOperator,
  WorkspaceSelectionConditionStringField,
  WorkspaceSelectionConditionStringOperator,
  WorkspaceSelectionInput,
  WorkspaceSelectionIntConditionInput,
  WorkspaceSelectionKeyValueConditionInput,
  WorkspaceSelectionRatingConditionInput,
  WorkspaceSelectionStringConditionInput,
} from "~/operations";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { ApolloError } from "@apollo/client";
import { useSnackbar } from "notistack";
import { useViewer } from "~/providers/viewer";
import { IamActions } from "~/lib/iam";
import { LoadingButton } from "~/components/loading-button";
import { CreateWorkspaceHeader } from "~/pages/space/Workspaces/components/CreateWorkspace/CreateWorkspaceHeader/CreateWorkspaceHeader";
import { CreateWorkspaceStepOne } from "~/pages/space/Workspaces/components/CreateWorkspace/CreateWorkspaceSteps/CreateWorkspaceStepOne";
import { CreateWorkspaceStepTwo } from "~/pages/space/Workspaces/components/CreateWorkspace/CreateWorkspaceSteps/CreateWorkspaceStepTwo";
import {
  formatGenericWorkspaceErrors,
  mapFormValuesToQueryBuilderAPI,
} from "../../utils";
import { TooltipWrapper } from "~/components/TooltipWrapper";

export type CreateWorkspacePageProps = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export type ConditionType = {
  operator: WorkspaceSelectionConditionOperator;
  stringCondition?: WorkspaceSelectionStringConditionInput;
  intCondition?: WorkspaceSelectionIntConditionInput;
  ratingCondition?: WorkspaceSelectionRatingConditionInput;
  selectionCondition?: WorkspaceSelectionConditionInput;
  keyValueCondition?: WorkspaceSelectionKeyValueConditionInput;
  // formKey and formValues fields are temporary form fields that will be used later when submitting form to case values
  // either to intCondition or stringCondition objects
  formKey:
    | WorkspaceSelectionConditionStringField
    | WorkspaceSelectionConditionIntField
    | WorkspaceSelectionConditionRatingField
    | WorkspaceSelectionConditionKeyValueField
    | "";
};

export type SelectionType = {
  conditions: Array<ConditionType>;
};

export type CreateWorkspaceFormInput = {
  name: string;
  description: string;
  selections: Array<SelectionType>;
};

export const initialCondition: ConditionType = {
  formKey: "",
  operator: WorkspaceSelectionConditionOperator.And,
  stringCondition: {
    field: WorkspaceSelectionConditionStringField.Unknown,
    operator: WorkspaceSelectionConditionStringOperator.Equal,
    values: [],
  },
  intCondition: {
    field: WorkspaceSelectionConditionIntField.Unknown,
    operator: WorkspaceSelectionConditionNumericOperator.Equal,
    values: [],
  },
  ratingCondition: {
    field: WorkspaceSelectionConditionRatingField.Unknown,
    operator: WorkspaceSelectionConditionRatingOperator.Equal,
    values: [],
  },
  keyValueCondition: {
    field: WorkspaceSelectionConditionKeyValueField.Unknown,
    operator: WorkspaceSelectionConditionKeyValueOperator.Contains,
    values: [],
  },
};

export const CreateWorkspacePage = ({
  availablePermissions,
}: CreateWorkspacePageProps) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { refetch } = useViewer();
  const spaceId = searchParams.get("spaceId");
  const spaceMrn = spaceId
    ? `//captain.api.mondoo.app/spaces/${spaceId}`
    : undefined;
  const [isReseted, setReset] = useState(false);

  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?spaceId=${spaceId}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Link
      key="/space/workspaces"
      component={RouterLink}
      to={`/space/workspaces?spaceId=${spaceId}`}
      display="flex"
    >
      Workspaces
    </Link>,
    <Typography key="/spaces/workspace/new">Create New</Typography>,
  ];

  const hasCreateWorkspacePermission = availablePermissions.includes(
    IamActions.ACTION_MONDOO_POLICY_EXTENDEDHUB_CREATEWORKSPACE,
  );

  const [createWorkspace, { loading: isCreateWorkspaceLoading }] =
    useCreateWorkspaceMutation({ refetchQueries: [GetWorkspacesDocument] });

  const formMethods = useForm<CreateWorkspaceFormInput>({
    mode: "onBlur",
    defaultValues: {
      name: "",
      description: "",
    },
  });

  const {
    reset,
    formState: { isValid, errors },
    handleSubmit,
    trigger,
  } = formMethods;

  useEffect(() => {
    reset({
      name: "",
      description: "",
      selections: [
        {
          conditions: [initialCondition],
        },
      ],
    });
    // We need one rerender to get errors array populated
    setReset(true);
  }, [trigger, reset]);

  useEffect(() => {
    isReseted && trigger();
  }, [trigger, isReseted]);

  const handleFormSubmit: SubmitHandler<CreateWorkspaceFormInput> = async (
    values,
  ) => {
    try {
      const ownerMrn = spaceMrn || "";
      const name = values.name;
      const description = values.description || "";

      const selections: Array<WorkspaceSelectionInput> =
        mapFormValuesToQueryBuilderAPI(values.selections);

      const createSpaceResult = await createWorkspace({
        variables: {
          input: {
            ownerMrn,
            name,
            description,
            selections: {
              // Let's not enable it yet
              selections: selections,
            },
          },
        },
      });
      const createdWorkspace = createSpaceResult.data?.createWorkspace;

      if (!createdWorkspace) throw "Failed to create workspace";

      enqueueSnackbar(`Successfully created workspace: ${name}`, {
        variant: "success",
      });
      // we have to wait for viewer and settings to load again
      // before we can navigate to the new space
      await refetch();
      navigate(
        `/space/overview?spaceId=${spaceId}&workspaceId=${createdWorkspace.mrn}`,
      );
    } catch (err) {
      if (err instanceof ApolloError) {
        err.graphQLErrors.map((e) => {
          enqueueSnackbar(e.message, { variant: "error" });
        });
      } else {
        enqueueSnackbar("Failed to create workspace", { variant: "error" });
      }
    }
  };

  return (
    <Box>
      <Breadcrumbs sx={{ mb: 3, overflowWrap: "anywhere" }} separator="›">
        {breadcrumbs}
      </Breadcrumbs>
      <CreateWorkspaceHeader spaceId={spaceId || ""} />
      <FormProvider {...formMethods}>
        <Flex
          component="form"
          gap={6}
          flexDirection="column"
          onSubmit={handleSubmit(handleFormSubmit)}
        >
          <CreateWorkspaceStepOne />
          <CreateWorkspaceStepTwo />
          <Grid size={{ xs: 12 }} sx={{ textAlign: "center" }}>
            <TooltipWrapper
              hideTooltip={Object.keys(errors).length === 0}
              title={formatGenericWorkspaceErrors(errors).map((e) => (
                <Typography variant="body2" color="error" key={e}>
                  {e}
                </Typography>
              ))}
            >
              <span>
                <LoadingButton
                  type="submit"
                  buttonText="Create Workspace"
                  variant="contained"
                  color="primary"
                  loading={isCreateWorkspaceLoading}
                  disabled={!isValid || !hasCreateWorkspacePermission}
                />
              </span>
            </TooltipWrapper>
          </Grid>
        </Flex>
      </FormProvider>
    </Box>
  );
};
