import { TableCell } from "@mui/material";
import { RiskFactorStats } from "~/operations";
import { RiskFactorsIcons } from "~/pages/space/security/components/RiskFactors/RiskFactorsIcons";

export type RiskFactorsCellProps = {
  riskFactors: (RiskFactorStats | null)[] | null | undefined;
  "data-testid"?: string;
};

export const RiskFactorsCell = ({
  riskFactors,
  ...props
}: RiskFactorsCellProps) => {
  return (
    <TableCell data-testid={props["data-testid"]}>
      <RiskFactorsIcons riskFactors={riskFactors} printView />
    </TableCell>
  );
};
