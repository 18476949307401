import {
  Link as RouterLink,
  Outlet,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  capitalize,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { Space } from "~/lib/types";
import { HomeIcon } from "~/components/icons";
import {
  TabListItem,
  TabNavigation,
  useRouteMatch,
} from "~/components/tab-nav";
import { TestIamActionsQuery } from "~/operations";
import { TargetType } from "./space/Workspaces/components/FormFields/types";
import { WorkspacesSelection } from "~/pages/space/Workspaces/types";

export interface SpaceSettingsProps {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
}

export interface SpaceSettingsState {
  nameErrMsg: string;
  isDeleting: boolean;
  isDeletingAssets: boolean;
}

type SpaceSettingsOutletContextType = {
  targetType: TargetType;
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
  workspace?: {
    id: string;
    mrn: string;
    name: string;
    description?: string | null;
    selections: WorkspacesSelection;
  };
};

export function SpaceSettings({
  space,
  availablePermissions,
}: SpaceSettingsProps) {
  const [searchParams] = useSearchParams();
  const spaceId = searchParams.get("spaceId");
  const workspaceMrn = searchParams.get("workspaceId");
  const isWorkspace = !!workspaceMrn;
  const targetType: TargetType = isWorkspace ? "workspace" : "space";

  const scopeParams = new URLSearchParams();
  if (spaceId) scopeParams.append("spaceId", spaceId);
  if (workspaceMrn) scopeParams.append("workspaceId", workspaceMrn);

  const href = (tab: string) => {
    return `/space/settings/${tab}?${scopeParams}`;
  };

  // TODO: start moving stuff like this into a useScope hook or similar.
  // From anywhere within scoped views, what is the current org, space,
  // and/or workspace the viewer is viewing from?
  const context: SpaceSettingsOutletContextType = {
    targetType: !workspaceMrn ? "space" : "workspace",
    space,
    availablePermissions,
  };

  const tabList: TabListItem[] = [
    { label: "General settings", to: href("general"), route: "/general" },
    {
      label: "Security model",
      to: href("security-model"),
      route: "/security-model",
    },
    { label: "Cases", to: href("cases"), route: "/cases" },
    { label: "Members", to: href("members"), route: "/members" },
    { label: "Audit Log", to: href("auditlog"), route: "/auditlog" },
    {
      label: "Service accounts",
      to: href("serviceaccounts"),
      route: "/serviceaccounts",
    },
    {
      label: "Registration tokens",
      to: href("registration-tokens"),
      route: "/registration-tokens",
    },
    {
      label: "API tokens",
      to: href("api-tokens"),
      route: "/api-tokens",
    },
  ].flatMap((tab) => (tab ? [tab] : []));

  const currentTab = useRouteMatch(
    tabList.map((x) => x.route),
    "general",
  );

  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?spaceId=${space.id}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Typography key={1}>Settings</Typography>,
  ];

  document.title = `Settings · Mondoo`;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs sx={{ mb: 3, overflowWrap: "anywhere" }} separator="›">
          {breadcrumbs}
        </Breadcrumbs>
        {/* Heading */}
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 4 }}>
          <Typography variant="h4" component="h2" fontWeight={700}>
            {capitalize(targetType)} Settings
          </Typography>
        </Box>
        {!isWorkspace && (
          <TabNavigation
            {...{ id: "space-settings-tabs", tabList, currentTab }}
          />
        )}
      </Grid>
      <Grid item xs>
        <Outlet context={context} />
      </Grid>
    </Grid>
  );
}

export function useSpaceSettingsOutletContext() {
  return useOutletContext<SpaceSettingsOutletContextType>();
}
