import { Fragment } from "react";
import { Controller } from "react-hook-form";
import { Link as RouterLink } from "react-router-dom";
import { LoadingButton } from "~/components/loading-button";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  IconButtonProps,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { CheckIcon, CloseIcon, PDFIcon } from "~/components/icons";

import { UseExportButton, UseExportButtonProps } from "./use-export-button";
import { Space } from "~/lib/types";
import { DocumentType } from "~/operations";

type ExportButtonProps = UseExportButtonProps & IconButtonProps;

export function ExportButton({
  space,
  documentType,
  title,
  controlMrn,
  frameworkMrn,
  assetMrn,
  ...props
}: ExportButtonProps) {
  const theme = useTheme();
  const { exportDialog, finishedDialog, form } = UseExportButton(
    documentType === DocumentType.AssetReport
      ? {
          documentType: DocumentType.AssetReport,
          assetMrn: assetMrn,
          title,
          space,
        }
      : documentType === DocumentType.ControlReport
        ? {
            documentType: DocumentType.ControlReport,
            controlMrn: controlMrn,
            frameworkMrn: frameworkMrn,
            title,
            space,
          }
        : {
            documentType: DocumentType.FrameworkReport,
            frameworkMrn: frameworkMrn,
            title,
            space,
          },
  );

  return (
    <Fragment>
      <Tooltip title="Generate Report" placement="top" arrow>
        <IconButton onClick={exportDialog.handleOpen} {...props}>
          <PDFIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
      <Dialog
        open={exportDialog.exportDialogOpen}
        maxWidth="md"
        PaperProps={{
          // @ts-ignore https://github.com/mui/material-ui/pull/32404#issuecomment-1105228783
          component: "form",
          onSubmit: form.handleSubmit(form.onSubmit),
        }}
        onClose={exportDialog.handleClose}
      >
        <DialogTitle
          component="div"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              textTransform: "uppercase",
              fontWeight: "700",
              display: "flex",
              alignItems: "center",
            }}
          >
            Generate{" "}
            {documentType === DocumentType.FrameworkReport
              ? "Framework"
              : documentType === DocumentType.ControlReport
                ? "Control"
                : "Asset"}{" "}
            Report
          </Typography>
          <IconButton onClick={exportDialog.handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container rowGap={3}>
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: "700", mb: 1 }}>
                Report name
              </Typography>
              <Controller
                name="reportName"
                control={form.control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={form.formState.isSubmitting}
                    fullWidth
                    sx={{
                      background: theme.palette.code.background,
                      borderRadius: 1,
                      color: "text.primary",
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: "700", mb: 1 }}>
                REPORT AUTOMATICALLY INCLUDES
              </Typography>
              <Grid container rowGap={2}>
                <LineItem
                  text="Metadata"
                  caption=" Control names, report date, compiled by name, Mondoo organization and
          space ID"
                />
                <LineItem text="Check summaries" />
                <LineItem text="Data query evidence" />
                <LineItem text="Exceptions" />
              </Grid>
            </Grid>
            {/* Purposely commented out, we will uncomment when we can provide options */}
            {/* <Grid item xs={12}>
              <Typography sx={{ fontWeight: "700", mb: 1 }}>
                OPTIONS TO INCLUDE{" "}
                <Button
                  variant="text"
                  color="primary"
                  sx={{ ml: 0.5, textTransform: "none" }}
                  onClick={form.handleSelectAllOptions}
                >
                  {form.allSelected ? "Deselect All" : "Select All"}
                </Button>
              </Typography>
              <Grid container>
                <Grid item xs={12}>
                  <FormGroup sx={{ ml: 1.125 }}>
                    <Grid container>
                      {form.formValuesArray.map((item) => (
                        <Grid item xs={12} key={item.name}>
                          <Controller
                            name={item.name}
                            control={form.control}
                            render={({ field }) => (
                              <FormControlLabel
                                {...field}
                                control={
                                  <Checkbox
                                    disabled={form.formState.isSubmitting}
                                    checked={field.value as boolean}
                                  />
                                }
                                label={item.label}
                                sx={{
                                  ...(form.shouldIndent.includes(item.name) && {
                                    ml: 4.5,
                                  }),
                                }}
                              />
                            )}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </FormGroup>
                </Grid>
              </Grid>
            </Grid> */}
          </Grid>
        </DialogContent>

        <DialogActions sx={{ justifyContent: "center", pb: 5 }}>
          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            disabled={form.formState.isSubmitting}
            loading={form.formState.isSubmitting}
            buttonText="Generate Report"
          />
        </DialogActions>
      </Dialog>
      <FinishedDialog
        space={space}
        open={finishedDialog.finishedDialogOpen}
        handleClose={finishedDialog.handleFinishedClose}
      />
    </Fragment>
  );
}

const LineItem = ({ text, caption }: { text: string; caption?: string }) => {
  return (
    <Grid container item xs={12}>
      <Grid
        item
        xs="auto"
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ px: 1.125 }}
      >
        <CheckIcon sx={{ color: "text.secondary" }} />
      </Grid>
      <Grid
        item
        xs
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography sx={{ mb: -0.5 }}>{text}</Typography>
        {caption && (
          <Typography variant="caption" color="text.secondary">
            {caption}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

type FinishedDialogProps = {
  space: Space;
  open: boolean;
  handleClose: () => void;
};

function FinishedDialog({ space, open, handleClose }: FinishedDialogProps) {
  return (
    <Dialog open={open} maxWidth="md" onClose={handleClose}>
      <DialogTitle
        component="div"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          pt: 5,
          px: 5,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            textTransform: "uppercase",
            fontWeight: "700",
            display: "flex",
            alignItems: "center",
          }}
        >
          You're all set!
        </Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ pb: 5, px: 5 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography sx={{ my: 3 }}>
            When your report is ready, you can download it from the Reporting
            page.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            component={RouterLink}
            to={`/space/compliance/reports?spaceId=${space.id}`}
          >
            View Reports
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
