import { useEffect, useRef, useState } from "react";
import {
  Box,
  capitalize,
  ChipProps,
  ClickAwayListener,
  ClickAwayListenerProps,
  Collapse,
  IconButton,
  IconButtonProps,
  InputAdornment,
  MenuItem,
  Select,
  SelectProps,
  TextField,
  TextFieldProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Chip } from "~/components/chip";
import {
  CloseIcon,
  SearchIcon,
  SubdirectoryArrowLeftIcon,
} from "~/components/icons";
import { isFeatureEnabled } from "~/login/features";

enum SearchScope {
  Organization = "ORGANIZATION",
  Space = "SPACE",
  Workspace = "WORKSPACE",
}

function isValidScope(value: any): value is SearchScope {
  return Object.values(SearchScope).includes(value);
}

export type AppSearchProps = {};

export function AppSearch({}: AppSearchProps) {
  const navigate = useNavigate();

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down(796));

  const [isFocused, setIsFocused] = useState(false);
  const [showTextCommands, setShowTextCommands] = useState(false);

  const defaultScope = SearchScope.Organization;
  const [scope, setScope] = useState<SearchScope>(defaultScope);
  const [query, setQuery] = useState<string>("");

  const inputRef = useRef<HTMLInputElement>(null);

  const [searchParams] = useSearchParams();
  const orgId = searchParams.get("organizationId");
  const spaceId = searchParams.get("spaceId");
  const workspaceMrn = searchParams.get("workspaceId");

  const params = new URLSearchParams({
    ...(spaceId ? { spaceId } : {}),
    ...(workspaceMrn ? { workspaceId: workspaceMrn } : {}),
  });

  useEffect(() => {
    setScope(
      workspaceMrn
        ? SearchScope.Workspace
        : spaceId
          ? SearchScope.Space
          : orgId
            ? SearchScope.Organization
            : defaultScope,
    );
  }, [orgId, spaceId, workspaceMrn]);

  if (
    (!orgId && !spaceId && !workspaceMrn) ||
    (workspaceMrn && !isFeatureEnabled("Workspaces 1.5"))
  ) {
    return <></>;
  }

  const blurInput = () => {
    setIsFocused(false);
    inputRef.current?.blur();
  };

  const handleScopeChange: SelectProps<SearchScope>["onChange"] = (event) => {
    const value = event.target.value;
    if (isValidScope(value)) {
      setScope(value);
    }
  };

  const handleChange: TextFieldProps["onChange"] = (e) => {
    setQuery(e.target.value);
  };

  const handleKeyUp: TextFieldProps["onKeyUp"] = (e) => {
    if (e.key === "Enter") {
      const href =
        scope === SearchScope.Organization
          ? `/organization/search?organizationId=${orgId}`
          : `/space/search?${params.toString()}`;
      navigate(`${href}&q=${encodeURIComponent(query)}`);
      blurInput();
    }
    if (e.key === "Escape") {
      blurInput();
    }
  };

  const handleFocus: TextFieldProps["onFocus"] = () => {
    setIsFocused(true);
  };

  const handleClickAway: ClickAwayListenerProps["onClickAway"] = () => {
    blurInput();
  };

  const handleClearClick: IconButtonProps["onClick"] = () => {
    if (query.length > 0) {
      setQuery("");
    } else {
      blurInput();
    }
  };

  const startAdornment = (
    <InputAdornment position="start">
      <SearchIcon />
    </InputAdornment>
  );

  const endAdornment = isFocused ? (
    <InputAdornment position="end">
      <IconButton size="small" onClick={handleClearClick} sx={{ mr: 0.5 }}>
        <CloseIcon fontSize="inherit" />
      </IconButton>
      <Select
        value={scope}
        renderValue={(v) => `In ${v}`}
        onChange={handleScopeChange}
        size="small"
        variant="standard"
        disableUnderline
        MenuProps={{
          disablePortal: true,
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
          transformOrigin: { horizontal: "right", vertical: "top" },
          sx: {
            ".MuiMenu-paper": { borderTopRightRadius: 0, mt: "1px" },
            ".MuiMenuItem-root": { fontSize: "12px" },
          },
        }}
        disabled={!spaceId}
        sx={{
          "&.MuiInputBase-root": {
            backgroundColor: "background.paper",
            borderLeft: "1px solid",
            borderLeftColor: "divider",
          },
          ".MuiSelect-select": {
            display: "flex",
            height: "36px",
            fontSize: "12px",
            fontWeight: 700,
            textTransform: "uppercase",
            lineHeight: "36px",
            p: 0,
            pl: 1,
            pr: 4,
          },
        }}
      >
        <MenuItem value={SearchScope.Organization}>
          Search in Organization
        </MenuItem>
        <MenuItem value={SearchScope.Space}>Search in Space</MenuItem>
        <MenuItem value={SearchScope.Workspace}>Search in Workspace</MenuItem>
      </Select>
    </InputAdornment>
  ) : undefined;

  const maxWidth = "748px";
  const fullWidth = "calc(100vw - 48px)";
  const focusedWidth = isSmall ? fullWidth : maxWidth;
  const blurredWidth = "224px";

  return (
    <Box
      id="app-search"
      sx={{
        width: blurredWidth,
        height: "36px",
        ml: 1,
        mr: 3,
        position: "relative",
      }}
    >
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box
          id="app-search-container"
          sx={{
            width: isFocused ? focusedWidth : blurredWidth,
            position: "absolute",
            right: 0,
            top: "-2px",
            transition: (theme) =>
              theme.transitions.create(["width", "filter"]),
            zIndex: (theme) => theme.zIndex.modal,
            border: "1px solid",
            borderColor: (theme) =>
              isFocused
                ? theme.palette.primary.main
                : theme.palette.background.default,
            borderRadius: 1,
            overflow: "hidden",
            boxShadow: (theme) => (isFocused ? theme.shadows[8] : "none"),
          }}
        >
          <Box id="app-search-query" sx={{ position: "relative" }}>
            <TextField
              id="app-search-input"
              inputRef={inputRef}
              placeholder={`Search in ${capitalize(scope.toLowerCase())}`}
              variant="filled"
              size="small"
              hiddenLabel
              fullWidth
              InputProps={{
                startAdornment,
                endAdornment,
                disableUnderline: true,
                sx: {
                  "&.MuiInputBase-root": {
                    backgroundColor: "background.default",
                    borderRadius: 0,
                    fontSize: "14px",
                    border: "none",
                    pr: 0,
                  },
                },
              }}
              value={query}
              onFocus={handleFocus}
              onChange={handleChange}
              onKeyUp={handleKeyUp}
            />
          </Box>

          <Collapse in={isFocused}>
            <Box
              id="app-search-dropdown"
              sx={{
                backgroundColor: "background.paper",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box id="search-dropdown-footer">
                <Box
                  id="search-dropdown-footer-toolbar"
                  sx={{
                    p: 2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexWrap: "nowrap",
                    borderTop: "1px solid",
                    borderColor: "divider",
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <Box sx={{ display: "flex", alignItems: "center", mr: 2 }}>
                      <KeyInputChip
                        icon={<SubdirectoryArrowLeftIcon fontSize={"small"} />}
                        label="Enter"
                      />
                      <Typography color="text.secondary" fontSize={12}>
                        select
                      </Typography>
                    </Box>

                    <Box sx={{ display: "flex", alignItems: "center", mr: 2 }}>
                      <KeyInputChip label="Esc" />
                      <Typography
                        color="text.secondary"
                        fontSize={12}
                        whiteSpace="nowrap"
                      >
                        close search
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Collapse>
        </Box>
      </ClickAwayListener>
    </Box>
  );
}

type KeyInputChipProps = {} & ChipProps;

function KeyInputChip(props: KeyInputChipProps) {
  return (
    <Chip
      {...props}
      sx={{
        height: "24px",
        width: props.label ? undefined : "24px",
        mr: 1,
        ".MuiChip-label": {
          pl: props.label ? 1 : 0,
          pr: props.label ? 1 : undefined,
        },
        ".MuiChip-icon": {
          fontSize: "16px",
        },
      }}
    />
  );
}
