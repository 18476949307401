import { Box, IconButton, Tooltip } from "@mui/material";
import { ReactNode, useState } from "react";
import { CopyIcon } from "~/components/icons";
import { copyToClipboard } from "~/lib/clipboard";

export type CopyTextProps = {
  children: ReactNode;
  value?: string | null;
};

export function CopyText({ children, value }: CopyTextProps) {
  const textToCopy =
    value || (typeof children === "string" ? children : undefined);

  const [isHovered, setIsHovered] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  if (!textToCopy) return children;

  const onMouseEnter = () => setIsHovered(true);
  const onMouseLeave = () => setIsHovered(false);

  const onClick = async () => {
    await copyToClipboard(textToCopy);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  return (
    <Box
      className={`copy-text ${isHovered ? "hovered" : "not-hovered-not"}`}
      data-testid="copy-text"
      component="span"
      sx={{ fontSize: "inherit" }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Box className="copy-text-content" component="span">
        {children}
      </Box>
      <Tooltip
        className="copy-text-tooltip"
        title={isCopied ? "Copied!" : "Copy to clipboard"}
        arrow
        placement="top"
      >
        <IconButton
          className="copy-text-button"
          onClick={onClick}
          sx={{
            fontSize: "inherit",
            pointerEvents: isHovered ? undefined : "none",
            opacity: isHovered ? 1 : 0,
            transition: (theme) => theme.transitions.create("opacity"),
            my: -1,
          }}
        >
          <CopyIcon className="copy-text-icon" fontSize="inherit" />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
