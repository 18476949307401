import React from "react";
import { Box, Breadcrumbs, Grid2, Typography } from "@mui/material";
import { SectionHeading, Stats } from "../DetailsPage/components";
import { ShowMoreSummary } from "../ShowMore/ShowMoreSummary";
import { StatsProps } from "../DetailsPage/components/Stats/Stats";
import { Header } from "../DetailsPage/components/Header";
import { HeaderProps } from "../DetailsPage/components/Header/Header";

type DetailPageTopSectionProps = {
  content: {
    breadcrumbs: React.ReactNode | JSX.Element[];
    header: HeaderProps;
    variantTabs?: React.ReactNode;
    casesSummary?: React.ReactNode;
    summary?: string | null | undefined;
    stats: StatsProps["stats"];
    left?: {
      upper?: React.ReactNode;
      lower?: React.ReactNode;
    };
    right: React.ReactNode;
  };
};

export function DetailPageTopSection({ content }: DetailPageTopSectionProps) {
  return (
    <Grid2 size={{ xs: 12 }}>
      {/* if the breadcrumbs passed are an array of JSX Elements, show this content */}
      {Array.isArray(content.breadcrumbs) && (
        <Breadcrumbs
          className="detail-content-breadcrumbs"
          sx={{ mb: 3, overflowWrap: "anywhere" }}
          separator="›"
        >
          {content.breadcrumbs}
        </Breadcrumbs>
      )}
      {/* if the breadcrumbs passed are a single ReactNode, show this content */}
      {React.isValidElement(content.breadcrumbs) && content.breadcrumbs}

      <Header
        id="detail-content-header"
        icon={content.header.icon}
        title={content.header.title}
        created={content.header.created}
        lastModified={content.header.lastModified}
        riskFactors={content.header?.riskFactors}
        rightSection={content.header.rightSection}
      />
      <Box id="detail-content-description">
        {content.variantTabs && content.variantTabs}
        <SectionHeading heading="Description" />
        {content.casesSummary && content.casesSummary}
        <Grid2
          container
          spacing={3}
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Grid2 size={{ xs: 12, sm: 12, md: 8 }}>
            <Typography textTransform="uppercase" fontWeight={700} mb={1}>
              Summary
            </Typography>
            <Stats id="detail-content-stats" stats={content.stats} />
            {content.summary && (
              <ShowMoreSummary
                id="detail-content-summary"
                text={content.summary}
              />
            )}
            <Grid2
              container
              size={12}
              spacing={3}
              className="detail-content-left"
            >
              {content.left?.upper && content.left.upper}
              <Grid2
                size={{ xs: 12, md: 6 }}
                className="detail-content-left-lower"
              >
                {content.left?.lower && content.left.lower}
              </Grid2>
            </Grid2>
          </Grid2>
          <Grid2
            className="detail-content-right"
            size={{ xs: 12, sm: "auto", md: 4 }}
          >
            {content.right}
          </Grid2>
        </Grid2>
      </Box>
    </Grid2>
  );
}
