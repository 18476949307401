import { createSvgIcon } from "@mui/material";

export const NmapIcon = createSvgIcon(
  <svg
    viewBox="0 0 168 90"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    style={{ fillRule: "evenodd", clipRule: "evenodd" }}
  >
    <g id="g3032" transform="matrix(0.952382,0,0,0.952382,3.99998,-4.76032)">
      <g id="background" transform="matrix(1,0,0,1,-3.65728e-08,7.24836)">
        <path
          id="path238"
          d="M83.172,6.276C108.39,4.837 132.761,19.858 150.985,33.392C157.477,38.213 171.247,47.44 167.014,54.989C161.553,61.287 151.193,58.133 134.092,66.315C111.396,77.173 109.902,83.631 83.796,83.816C56.791,84.007 56.177,76.591 32.252,65.931C13.367,57.516 2.909,61.292 0.673,54.701C-2.373,46.047 9.774,38.068 16.415,33.392C46.541,12.177 59.68,6.254 83.172,6.276Z"
          style={{ fill: "white", stroke: "white", strokeWidth: 1 }}
        />
      </g>
      <g id="round" transform="matrix(1,0,0,1,-3.65728e-08,7.24836)">
        <ellipse
          id="ellipse262"
          cx="83.796"
          cy="42.511"
          rx="30.715"
          ry="30.043"
          style={{ fill: "url(#_Radial1)" }}
        />
        <ellipse
          id="ellipse264"
          cx="83.796"
          cy="42.799"
          rx="28.507"
          ry="27.79"
          style={{
            fill: "none",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.3",
            strokeWidth: "4.61px",
          }}
        />
        <g id="radius">
          <ellipse
            id="ellipse267"
            cx="83.796"
            cy="42.511"
            rx="30.715"
            ry="30.043"
            style={{ fill: "none", stroke: "rgb(0,32,64)", strokeWidth: 1 }}
          />
          <ellipse
            id="ellipse269"
            cx="83.841"
            cy="42.892"
            rx="29.806"
            ry="29.079"
            style={{ fill: "none", stroke: "rgb(0,32,64)", strokeWidth: 1 }}
          />
          <ellipse
            id="ellipse271"
            cx="83.876"
            cy="43.215"
            rx="28.831"
            ry="28.031"
            style={{ fill: "none", stroke: "rgb(0,32,64)", strokeWidth: 1 }}
          />
          <ellipse
            id="ellipse273"
            cx="83.94"
            cy="43.214"
            rx="13.806"
            ry="13.822"
            style={{ fill: "none", stroke: "rgb(0,32,64)", strokeWidth: 1 }}
          />
          <ellipse
            id="ellipse275"
            cx="83.812"
            cy="43.215"
            rx="16.813"
            ry="16.765"
            style={{ fill: "none", stroke: "rgb(0,32,64)", strokeWidth: 1 }}
          />
          <ellipse
            id="ellipse277"
            cx="83.748"
            cy="43.022"
            rx="19.692"
            ry="19.645"
            style={{ fill: "none", stroke: "rgb(0,32,64)", strokeWidth: 1 }}
          />
          <ellipse
            id="ellipse279"
            cx="83.939"
            cy="43.007"
            rx="22.38"
            ry="21.932"
            style={{ fill: "none", stroke: "rgb(0,32,64)", strokeWidth: 1 }}
          />
          <ellipse
            id="ellipse281"
            cx="83.796"
            cy="42.895"
            rx="24.764"
            ry="24.188"
            style={{ fill: "none", stroke: "rgb(0,32,64)", strokeWidth: 1 }}
          />
          <ellipse
            id="ellipse283"
            cx="83.748"
            cy="43.039"
            rx="26.444"
            ry="25.676"
            style={{ fill: "none", stroke: "rgb(0,32,64)", strokeWidth: 1 }}
          />
          <ellipse
            id="ellipse285"
            cx="83.796"
            cy="42.991"
            rx="27.835"
            ry="27.164"
            style={{ fill: "none", stroke: "rgb(0,32,64)", strokeWidth: 1 }}
          />
        </g>
        <g id="spokes">
          <path
            id="line288"
            d="M75.509,34.448L63.735,19.666"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line290"
            d="M74.549,35.216L60.856,22.162"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line292"
            d="M76.597,33.36L65.591,18.195"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line294"
            d="M78.005,32.72L67.383,16.979"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line296"
            d="M79.924,31.76L69.558,15.699"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line298"
            d="M74.229,36.368L59,24.53"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line300"
            d="M73.589,37.392L57.144,27.345"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line302"
            d="M73.078,38.671L55.544,30.353"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line304"
            d="M72.63,39.823L54.393,33.296"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line306"
            d="M72.182,40.911L53.625,36.56"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line308"
            d="M72.054,42.255L53.177,39.503"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line310"
            d="M72.182,43.407L53.049,42.767"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line312"
            d="M72.246,44.686L53.177,45.902"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line314"
            d="M72.374,45.646L53.817,49.038"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line316"
            d="M72.694,46.862L54.713,52.237"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line318"
            d="M73.334,48.014L55.8,55.053"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line320"
            d="M73.781,49.166L57.4,57.996"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line322"
            d="M74.357,49.997L59.32,60.748"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line324"
            d="M75.253,51.149L61.176,62.988"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line326"
            d="M76.149,51.853L63.607,65.035"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line328"
            d="M76.917,52.621L66.039,67.147"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line330"
            d="M78.005,53.389L68.854,68.682"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line332"
            d="M79.093,53.901L71.67,70.026"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line334"
            d="M80.244,54.413L74.485,71.242"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line336"
            d="M81.588,54.989L77.557,71.818"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line338"
            d="M82.868,55.181L80.564,72.394"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line340"
            d="M83.828,55.372L83.796,72.554"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line342"
            d="M84.916,55.437L87.027,72.33"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line344"
            d="M86.003,55.181L90.163,71.882"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line346"
            d="M87.155,54.413L93.106,70.922"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line348"
            d="M88.243,53.773L96.05,70.026"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line350"
            d="M89.459,53.261L98.93,68.618"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line352"
            d="M90.547,52.557L101.617,67.083"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line354"
            d="M91.698,51.917L104.176,65.099"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line356"
            d="M92.53,51.021L106.352,62.923"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line358"
            d="M93.17,50.126L108.4,60.556"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line360"
            d="M94.194,49.229L109.872,57.932"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line362"
            d="M94.514,48.078L111.471,55.181"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line364"
            d="M94.962,46.862L112.687,52.557"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line366"
            d="M95.282,45.774L113.647,49.102"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line368"
            d="M95.474,44.814L114.287,45.774"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line370"
            d="M95.602,43.151L114.479,42.895"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line372"
            d="M95.538,42.127L114.351,39.759"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line374"
            d="M95.282,41.039L113.903,36.56"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line376"
            d="M95.218,39.887L113.071,33.424"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line378"
            d="M94.578,38.671L111.855,30.353"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line380"
            d="M94.194,37.392L110.511,27.601"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line382"
            d="M93.426,36.496L108.72,24.914"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line384"
            d="M92.53,35.408L106.608,22.482"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line386"
            d="M91.571,34.256L104.56,20.434"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line388"
            d="M90.419,33.552L102.385,18.579"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line390"
            d="M89.715,32.656L99.889,16.851"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line392"
            d="M87.731,31.888L97.01,15.315"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line394"
            d="M86.452,31.057L93.874,14.035"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line396"
            d="M85.044,30.8L90.483,13.267"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line398"
            d="M83.7,30.609L83.636,12.5"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line400"
            d="M83.252,30.673L79.797,12.563"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line402"
            d="M72.886,14.355L81.012,31.121"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line404"
            d="M76.533,13.267L82.1,30.992"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
          <path
            id="line406"
            d="M84.34,30.737L86.963,12.692"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "rgb(0,32,64)",
              strokeWidth: 1,
            }}
          />
        </g>
      </g>
      <g id="masks" transform="matrix(1,0,0,1,-3.65728e-08,7.24836)">
        <path
          id="mask_1_"
          d="M57.112,25.713C58.033,24.318 58.217,22.578 59.318,21.274C60.493,19.884 62.008,18.102 63.464,17.002C65.354,15.575 68.136,14.449 70.339,13.641C71.943,13.053 73.536,12.802 75.223,12.379C79.168,11.389 82.988,10.959 87.124,11.124C90.653,11.264 94.318,11.896 97.488,13.434C99.311,14.319 100.898,15.589 102.51,16.662C103.977,17.637 104.99,18.451 106.129,19.693C107.314,20.986 109.466,23.425 109.372,25.276C102.717,23.074 96.257,21.138 89.209,20.916C82.26,20.698 72.854,21.154 67.814,22.138C64.787,22.729 60.496,24.274 57.016,25.713"
          style={{ fill: "white", fillRule: "nonzero" }}
        />
        <path
          id="mask"
          d="M71.19,70.122C75.733,71.034 80.23,71.526 84.564,71.402C89.571,71.258 92.259,70.858 98.018,69.514C95.337,72.594 93.602,73.098 90.138,73.719C87.287,74.23 84.318,73.664 81.407,73.68C79.015,73.694 76.04,73.957 73.845,72.963C72.755,72.47 72.646,71.738 71.941,71.018"
          style={{ fill: "white", fillRule: "nonzero" }}
        />
      </g>
      <g id="gradients" transform="matrix(1,0,0,1,-3.65728e-08,7.24836)">
        <path
          id="path419"
          d="M83.156,6.485C108.375,5.045 132.761,19.858 150.985,33.392C157.477,38.213 171.247,47.44 167.014,54.989C161.553,61.287 151.193,58.133 134.092,66.315C111.396,77.173 109.902,83.631 83.796,83.816C56.791,84.007 56.177,76.591 32.252,65.931C13.367,57.516 2.909,61.292 0.673,54.701C-2.373,46.047 9.774,38.068 16.415,33.392C46.541,12.177 59.664,6.462 83.156,6.485Z"
          style={{ fill: "url(#_Linear2)" }}
        />
        <path
          id="path434"
          d="M83.404,20.424C100.015,19.655 112.529,22.921 146.09,47.022C148.427,48.7 156.642,52.064 155.016,52.877C150.985,54.893 147.391,52.835 138.219,55.372C120.731,60.21 102.154,71.446 83.766,71.395C65.189,71.344 57.171,64.395 28.605,55.468C22.462,53.549 14.207,54.605 12.671,53.165C10.632,51.253 19.282,48.939 22.143,46.951C60.853,20.057 66.858,20.409 83.404,20.424Z"
          style={{ fill: "url(#_Linear3)" }}
        />
        <g id="g436" opacity="0.5">
          <path
            id="path438"
            d="M93.072,30.038C92.521,27.172 90.208,25.982 88.145,26.292"
            style={{ fill: "white" }}
          />
          <g id="g440">
            <path
              id="path442"
              d="M93.072,30.038C92.521,27.172 90.208,25.982 88.145,26.292"
              style={{ fill: "none", fillRule: "nonzero" }}
            />
            <path
              id="path444"
              d="M96.781,29.044C96.045,25.659 92.962,24.465 89.769,24.863C88.118,25.069 86.093,25.47 84.798,26.608C83.537,27.716 85.912,27.797 86.522,27.721C87.257,27.629 85.846,27.693 86.589,27.712C86.054,27.698 86.72,27.761 86.81,27.782C87.118,27.853 87.451,28.066 87.769,28.283C88.695,28.914 89.133,29.973 89.364,31.032C89.598,32.11 92.82,31.257 93.36,31.112C93.987,30.944 97.022,30.154 96.781,29.044Z"
              style={{ fill: "white", fillRule: "nonzero" }}
            />
          </g>
        </g>
      </g>
      <g id="target" transform="matrix(1,0,0,1,-3.65728e-08,7.24836)">
        <g opacity="0.58">
          <path
            id="path462"
            d="M95.602,43.007C95.602,49.759 90.359,55.232 83.89,55.232C77.424,55.232 72.182,49.759 72.182,43.007C72.182,36.254 77.424,30.78 83.89,30.78C90.359,30.78 95.602,36.254 95.602,43.007Z"
            style={{ fill: "url(#_Radial4)", fillRule: "nonzero" }}
          />
          <path
            id="line464"
            d="M83.812,26.449L83.796,59.548"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "white",
              strokeWidth: 1,
            }}
          />
          <path
            id="line466"
            d="M68.023,43.214L100.049,43.151"
            style={{
              fill: "none",
              fillRule: "nonzero",
              stroke: "white",
              strokeOpacity: "0.75",
              strokeWidth: "0.61px",
            }}
          />
          <ellipse
            id="ellipse468"
            cx="83.876"
            cy="43.119"
            rx="5.263"
            ry="5.503"
            style={{
              fill: "none",
              stroke: "white",
              strokeOpacity: "0.76",
              strokeWidth: "0.61px",
            }}
          />
          <ellipse
            id="ellipse470"
            cx="83.844"
            cy="43.055"
            rx="11.662"
            ry="11.934"
            style={{
              fill: "none",
              stroke: "white",
              strokeOpacity: "0.79",
              strokeWidth: "0.61px",
            }}
          />
          <ellipse
            id="ellipse472"
            cx="83.764"
            cy="43.055"
            rx="1.856"
            ry="1.791"
            style={{ fill: "white", stroke: "white", strokeWidth: 1 }}
          />
        </g>
      </g>
      <g id="shape" transform="matrix(1,0,0,1,-3.65728e-08,7.24836)">
        <path
          id="path475"
          d="M83.172,6.276C108.39,4.837 132.761,19.858 150.985,33.392C157.477,38.213 171.247,47.44 167.014,54.989C161.553,61.287 151.193,58.133 134.092,66.315C111.396,77.173 109.902,83.631 83.796,83.816C56.791,84.007 56.177,76.591 32.252,65.931C13.367,57.516 2.909,61.292 0.673,54.701C-2.373,46.047 9.774,38.068 16.415,33.392C46.541,12.177 59.68,6.254 83.172,6.276Z"
          style={{
            fill: "none",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path477"
          d="M82.836,7.956C106.896,6.592 130.233,20.706 147.621,33.528C153.813,38.095 168.27,46.973 164.231,54.125C159.019,60.091 148.007,57.413 131.692,65.163C110.038,75.45 108.415,81.497 83.508,81.672C57.744,81.854 56.806,74.783 33.98,64.683C15.963,56.711 5.635,59.744 3.361,53.549C0.577,45.966 12.895,37.958 19.231,33.528C47.973,13.429 60.423,7.935 82.836,7.956Z"
          style={{
            fill: "none",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path479"
          d="M83.124,8.82C106.494,7.476 129.094,20.919 145.984,33.55C151.999,38.05 168.048,48.175 162.503,54.029C157.32,59.5 146.359,57.08 130.511,64.716C109.478,74.851 107.901,80.424 83.708,80.596C58.682,80.775 57.771,74.194 35.599,64.244C18.098,56.389 7.298,59.363 5.089,53.261C2.385,45.791 15.118,37.915 21.273,33.55C49.191,13.749 61.354,8.799 83.124,8.82Z"
          style={{
            fill: "none",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path481"
          d="M83.067,7.134C107.844,5.727 131.564,20.303 149.468,33.527C155.846,38.237 169.613,47.153 165.455,54.529C160.089,60.684 150.025,57.964 133.109,65.596C110.601,75.751 109.344,82.517 83.695,82.697C57.164,82.883 56.248,75.433 33.056,65.221C14.686,57.132 4.243,60.088 2.03,54.247C-1.004,46.236 10.759,38.543 17.646,33.625C45.982,13.388 59.987,7.112 83.067,7.134Z"
          style={{
            fill: "none",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path483"
          d="M83.316,18.946C101.412,18.939 114.031,21.49 151.184,48.386C153.711,50.216 160.103,52.781 158.664,54.125C154.386,58.118 152.74,54.203 142.796,56.954C123.836,62.199 103.689,74.656 83.753,74.6C63.613,74.544 55.912,66.926 24.957,57.196C20.748,55.873 11.205,57.167 9.216,54.221C7.52,51.708 13.842,50.253 16.944,48.098C58.912,18.941 65.378,18.954 83.316,18.946Z"
          style={{
            fill: "none",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.7",
            strokeWidth: "0.23px",
          }}
        />
        <path
          id="path485"
          d="M83.268,19.426C100.921,19.395 114.175,21.922 149.839,48.165C152.301,49.976 158.778,52.533 157.896,53.837C155.688,57.1 150.828,54.149 141.866,56.716C123.437,61.995 103.281,73.706 83.757,73.854C64.118,74.004 55.965,66.444 25.629,56.908C21.504,55.612 12,56.716 9.984,54.317C8.073,52.042 15.244,49.994 18.283,47.883C59.412,19.308 65.887,19.457 83.268,19.426Z"
          style={{
            fill: "none",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.7",
            strokeWidth: "0.23px",
          }}
        />
        <path
          id="path487"
          d="M83.364,19.882C100.526,19.886 113.887,22.162 148.521,47.944C150.924,49.733 157.704,52.589 156.84,53.741C154.911,56.312 149.192,54.09 140.619,56.428C122.67,61.324 102.906,73.173 83.76,73.12C64.417,73.066 56.222,65.965 26.493,56.62C22.45,55.35 12.863,56.14 10.848,54.221C8.739,52.213 16.617,49.737 19.596,47.667C59.902,19.665 66.136,19.879 83.364,19.882Z"
          style={{
            fill: "none",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.7",
            strokeWidth: "0.23px",
          }}
        />
        <path
          id="path489"
          d="M83.388,20.362C100.444,20.316 112.735,22.306 147.229,47.82C149.589,49.566 157.48,52.708 155.88,53.645C151.945,55.948 148.868,53.638 139.467,56.14C121.555,60.908 102.526,72.544 83.763,72.492C64.807,72.44 56.203,65.394 27.069,56.236C23.107,54.991 14.111,55.756 11.808,53.933C9.571,52.162 17.963,49.577 20.882,47.549C60.382,20.106 66.505,20.408 83.388,20.362Z"
          style={{
            fill: "none",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.7",
            strokeWidth: "0.23px",
          }}
        />
        <path
          id="path491"
          d="M83.424,20.806C100.037,20.721 112.735,23.074 146.09,47.598C148.408,49.302 156.642,52.64 155.016,53.453C150.985,55.468 147.391,53.411 138.219,55.948C120.731,60.785 102.154,72.022 83.766,71.971C65.189,71.92 57.171,64.971 28.605,56.044C22.462,54.125 14.119,55.269 12.671,53.741C10.944,51.917 19.282,49.514 22.143,47.527C60.853,20.633 66.878,20.891 83.424,20.806Z"
          style={{
            fill: "none",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.7",
            strokeWidth: "0.23px",
          }}
        />
      </g>
      <g id="spokes_1_" transform="matrix(1,0,0,1,-3.65728e-08,7.24836)">
        <path
          id="path494"
          d="M109.328,66.603C113.135,68.836 115.951,70.922 114.223,76.969"
          style={{
            fill: "none",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path496"
          d="M118.734,74.665C120.462,69.674 116.588,64.779 113.935,64.779"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path498"
          d="M122.957,72.17C124.781,67.85 120.558,63.435 118.35,62.859"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path500"
          d="M127.469,69.674C128.525,65.067 124.877,61.899 122.957,61.228"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path502"
          d="M131.596,67.467C132.46,63.051 129.292,60.268 126.989,59.596"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path504"
          d="M135.339,65.547C136.107,60.748 133.996,58.156 131.98,57.676"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path506"
          d="M138.507,64.203C140.715,59.5 138.027,56.716 136.971,56.14"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path508"
          d="M141.866,63.051C144.842,57.58 142.826,55.468 141.674,55.181"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path510"
          d="M146.474,54.509C149.353,58.156 146.216,62.091 145.898,62.091"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path512"
          d="M150.505,54.413C153.385,57.676 150.462,60.844 150.409,60.844"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path514"
          d="M153.96,53.933C156.072,57.388 153.66,60.172 153.289,60.172"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path516"
          d="M155.016,53.453C158.184,54.893 159.24,57.58 159.144,59.02"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path518"
          d="M155.208,52.973C155.208,53.185 160.871,54.125 167.686,53.357"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path520"
          d="M166.438,47.502C165.191,49.229 160.901,51.821 154.056,51.821"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path522"
          d="M162.119,42.319C162.119,46.987 156.84,48.558 152.233,50.765"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path524"
          d="M157.608,38.191C157.608,44.611 152.521,47.022 148.969,49.134"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path526"
          d="M152.809,34.832C152.809,41.517 148.681,44.622 145.226,46.926"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path528"
          d="M148.681,31.664C148.681,38.455 144.938,41.743 141.003,43.95"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path530"
          d="M144.458,28.689C144.458,35.48 140.715,38.383 136.875,40.975"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path532"
          d="M139.467,25.329C139.467,32.757 136.875,36.56 133.42,38.767"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path534"
          d="M134.476,22.066C136.011,29.841 131.692,34.736 129.868,36.272"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path536"
          d="M128.908,18.802C131.98,25.041 127.565,30.705 125.549,33.68"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path538"
          d="M124.877,16.883C126.893,24.753 123.149,29.745 120.846,31.088"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path540"
          d="M119.79,14.291C122.19,21.778 118.35,26.193 116.239,28.593"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path542"
          d="M115.567,12.467C117.102,18.323 114.127,24.849 111.727,26.385"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path544"
          d="M51.833,12.051C50.617,20.37 54.393,25.489 55.736,26.449"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path546"
          d="M47.866,13.843C45.626,22.098 50.297,27.793 51.257,28.753"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path548"
          d="M43.258,16.083C40.635,24.21 45.562,29.905 46.906,31.184"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path550"
          d="M38.267,18.898C36.412,26.641 40.955,32.528 42.427,34.064"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path552"
          d="M33.212,22.034C32.7,29.777 36.476,35.664 38.267,36.752"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path554"
          d="M28.093,25.297C26.749,32.016 32.06,37.967 34.428,39.055"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path556"
          d="M23.294,28.561C22.398,35.408 28.093,39.759 31.1,41.295"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path558"
          d="M18.942,31.504C18.942,38.437 23.678,42.703 27.069,44.046"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path560"
          d="M14.783,34.64C14.335,41.423 19.582,45.966 22.846,46.99"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path562"
          d="M10.88,37.2C10.368,44.302 15.871,47.374 18.494,49.357"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path564"
          d="M5.313,42.127C5.313,46.866 10.816,48.781 15.295,50.701"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path566"
          d="M1.025,47.694C1.025,49.958 6.336,51.213 13.183,51.789"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path568"
          d="M0.193,53.261C0.193,53.261 5.697,54.221 12.351,53.261"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path570"
          d="M8.192,59.212C8.192,56.188 10.496,54.221 12.671,53.741"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path572"
          d="M13.631,54.157C11.84,57.484 14.089,60.556 14.655,60.556"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path574"
          d="M16.895,54.605C14.527,57.996 17.038,61.004 17.215,61.004"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path576"
          d="M21.054,54.732C18.367,58.636 21.426,62.091 21.886,62.091"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path578"
          d="M25.341,55.245C22.782,59.34 25.484,63.179 25.661,63.179"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path580"
          d="M29.309,64.715C27.069,59.852 29.767,56.524 30.333,56.524"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path582"
          d="M32.892,66.187C30.78,61.58 33.922,58.06 35.196,58.06"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path584"
          d="M35.964,67.658C35.964,63.308 38.075,60.172 40.251,59.788"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path586"
          d="M40.187,69.77C39.291,64.971 42.555,61.964 44.602,61.515"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path588"
          d="M44.282,71.882C43.386,66.507 46.778,63.883 48.954,63.115"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path590"
          d="M48.442,74.25C48.058,68.874 51.193,65.483 53.689,64.971"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
        <path
          id="path592"
          d="M52.537,76.553C51.577,70.474 55.544,67.275 58.168,66.699"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "rgb(0,76,120)",
            strokeOpacity: "0.8",
            strokeWidth: 1,
          }}
        />
      </g>
      <g id="tear" transform="matrix(1,0,0,1,-3.65728e-08,7.24836)">
        <g id="path610" transform="matrix(1.05,-0,-0,1.05,-4.19997,-2.25003)">
          <use
            xlinkHref="#_Image5"
            x="138.597"
            y="46.938"
            width="12px"
            height="7px"
          />
        </g>
        <g id="g612" opacity="0.5">
          <g id="g614">
            <path
              id="path616"
              d="M146.234,51.478C146.105,52.15 146.969,52.15 147.417,52.15"
              style={{ fill: "none", fillRule: "nonzero" }}
            />
            <path
              id="path618"
              d="M145.721,51.566C145.602,52.338 146.533,52.37 147.083,52.37C147.335,52.371 147.604,52.315 147.817,52.174C147.976,52.069 147.96,51.929 147.752,51.929C147.384,51.928 146.661,51.95 146.747,51.39C146.789,51.116 145.761,51.305 145.721,51.566Z"
              style={{ fill: "rgb(38,34,97)", fillRule: "nonzero" }}
            />
          </g>
        </g>
        <path
          id="path620"
          d="M147.45,50.118C147.958,50.471 148.447,50.668 148.998,50.966C149.443,51.209 149.898,51.436 150.345,51.676C150.695,51.862 151.341,52.254 150.949,52.713C150.695,53.012 150.078,52.965 149.717,53.015C149.145,53.095 148.441,53.064 147.87,53.015C147.171,52.955 146.44,53.121 145.728,53.121C145.215,53.121 144.697,53.142 144.189,53.141C144.006,53.141 143.775,53.183 143.65,53.022C143.52,52.854 143.611,52.668 143.713,52.529C143.835,52.363 143.95,52.141 144.097,52.011C144.228,51.895 144.4,51.815 144.532,51.714C144.852,51.466 144.964,51.205 144.68,50.886C144.397,50.567 144.19,50.342 144.174,49.892C144.156,49.317 144.522,49.2 145.011,49.074C145.744,48.886 145.682,49.012 146.295,49.363C146.589,49.531 147.311,50.022 147.45,50.118Z"
          style={{
            fill: "none",
            stroke: "rgb(27,117,188)",
            strokeOpacity: "0.5",
            strokeWidth: "0.15px",
          }}
        />
        <path
          id="path622"
          d="M147.216,50.685C147.549,50.916 147.87,51.045 148.232,51.241C148.524,51.4 148.822,51.55 149.116,51.706C149.345,51.829 149.769,52.086 149.512,52.387C149.345,52.583 148.941,52.552 148.704,52.585C148.328,52.638 147.866,52.617 147.492,52.585C147.033,52.545 146.553,52.654 146.086,52.654C145.75,52.654 145.41,52.668 145.077,52.668C144.957,52.668 144.805,52.695 144.723,52.589C144.637,52.48 144.698,52.357 144.764,52.266C144.844,52.157 144.919,52.012 145.016,51.926C145.102,51.85 145.215,51.798 145.302,51.731C145.511,51.569 145.585,51.397 145.399,51.188C145.213,50.979 145.077,50.832 145.067,50.536C145.056,50.159 145.295,50.082 145.616,49.999C146.096,49.876 146.056,49.958 146.459,50.189C146.652,50.299 147.125,50.621 147.216,50.685Z"
          style={{
            fill: "none",
            stroke: "rgb(27,117,188)",
            strokeOpacity: "0.5",
            strokeWidth: "0.15px",
          }}
        />
      </g>
      <g id="tear_copy" transform="matrix(1,0,0,1,-3.65728e-08,7.24836)">
        <g id="path640" transform="matrix(1.05,-0,-0,1.05,-4.19997,-2.25003)">
          <use
            xlinkHref="#_Image6"
            x="16.667"
            y="47.419"
            width="12px"
            height="7px"
          />
        </g>
        <g id="g642" opacity="0.5">
          <g id="g644">
            <path
              id="path646"
              d="M20.717,51.83C20.827,52.505 19.964,52.482 19.516,52.47"
              style={{ fill: "none", fillRule: "nonzero" }}
            />
            <path
              id="path648"
              d="M20.198,51.969C20.211,52.071 20.208,52.151 20.164,52.245C20.166,52.242 20.12,52.3 20.143,52.282C20.185,52.25 20.255,52.242 20.304,52.232C20.173,52.26 20.047,52.255 19.85,52.25C19.601,52.243 19.325,52.308 19.116,52.446C18.953,52.553 18.977,52.686 19.181,52.691C19.824,52.708 21.358,52.653 21.236,51.691C21.207,51.467 20.161,51.682 20.198,51.969Z"
              style={{ fill: "rgb(38,34,97)", fillRule: "nonzero" }}
            />
          </g>
        </g>
        <path
          id="path650"
          d="M19.536,50.439C19.02,50.778 18.526,50.962 17.967,51.246C17.515,51.477 17.055,51.692 16.601,51.92C16.247,52.097 15.591,52.472 15.97,52.941C16.217,53.246 16.835,53.215 17.194,53.275C17.764,53.37 18.469,53.358 19.041,53.323C19.741,53.282 20.467,53.467 21.179,53.485C21.692,53.499 22.209,53.534 22.717,53.546C22.9,53.551 23.129,53.599 23.259,53.441C23.394,53.277 23.307,53.089 23.209,52.947C23.092,52.778 22.983,52.553 22.839,52.419C22.711,52.3 22.541,52.216 22.412,52.11C22.098,51.854 21.993,51.59 22.285,51.279C22.576,50.968 22.79,50.748 22.816,50.299C22.85,49.724 22.488,49.597 22.002,49.459C21.274,49.252 21.333,49.379 20.711,49.714C20.412,49.874 19.678,50.346 19.536,50.439Z"
          style={{
            fill: "none",
            stroke: "rgb(27,117,188)",
            strokeOpacity: "0.5",
            strokeWidth: "0.15px",
          }}
        />
        <path
          id="path652"
          d="M19.755,51.012C19.417,51.234 19.092,51.355 18.725,51.541C18.429,51.692 18.127,51.834 17.829,51.983C17.597,52.099 17.167,52.345 17.415,52.653C17.578,52.854 17.982,52.833 18.218,52.872C18.592,52.934 19.055,52.926 19.43,52.904C19.89,52.876 20.367,52.998 20.833,53.01C21.17,53.019 21.509,53.042 21.842,53.05C21.962,53.053 22.113,53.085 22.198,52.981C22.286,52.874 22.229,52.749 22.165,52.656C22.088,52.545 22.016,52.398 21.922,52.31C21.838,52.232 21.726,52.176 21.642,52.108C21.436,51.94 21.367,51.766 21.559,51.562C21.749,51.358 21.89,51.214 21.907,50.92C21.929,50.542 21.692,50.459 21.373,50.368C20.896,50.232 20.934,50.316 20.525,50.535C20.329,50.64 19.848,50.95 19.755,51.012Z"
          style={{
            fill: "none",
            stroke: "rgb(27,117,188)",
            strokeOpacity: "0.5",
            strokeWidth: "0.15px",
          }}
        />
      </g>
    </g>
    <g id="text5528"></g>
    <g id="text2742"></g>
    <defs>
      <radialGradient
        id="_Radial1"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(30.3809,0,0,30.3809,83.7958,42.5106)"
      >
        <stop
          offset={0}
          style={{ stopColor: "rgb(27,97,126)", stopOpacity: 1 }}
        />
        <stop
          offset="0.53"
          style={{ stopColor: "rgb(27,97,126)", stopOpacity: 1 }}
        />
        <stop
          offset="0.56"
          style={{ stopColor: "rgb(69,126,150)", stopOpacity: 1 }}
        />
        <stop
          offset="0.64"
          style={{ stopColor: "rgb(151,183,196)", stopOpacity: 1 }}
        />
        <stop
          offset="0.68"
          style={{ stopColor: "rgb(183,205,214)", stopOpacity: 1 }}
        />
        <stop
          offset="0.72"
          style={{ stopColor: "rgb(209,223,229)", stopOpacity: 1 }}
        />
        <stop
          offset="0.77"
          style={{ stopColor: "rgb(230,238,241)", stopOpacity: 1 }}
        />
        <stop
          offset="0.82"
          style={{ stopColor: "rgb(244,247,249)", stopOpacity: 1 }}
        />
        <stop
          offset="0.89"
          style={{ stopColor: "rgb(252,253,254)", stopOpacity: 1 }}
        />
        <stop offset={1} style={{ stopColor: "white", stopOpacity: 1 }} />
      </radialGradient>
      <linearGradient
        id="_Linear2"
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(4.74135e-15,77.4322,-77.4322,4.74135e-15,84.0002,6.38749)"
      >
        <stop
          offset={0}
          style={{ stopColor: "rgb(199,233,242)", stopOpacity: "0.6" }}
        />
        <stop
          offset="0.47"
          style={{ stopColor: "rgb(199,233,242)", stopOpacity: "0.6" }}
        />
        <stop
          offset="0.57"
          style={{ stopColor: "rgb(178,223,241)", stopOpacity: "0.6" }}
        />
        <stop
          offset={1}
          style={{ stopColor: "rgb(69,190,238)", stopOpacity: "0.6" }}
        />
      </linearGradient>
      <linearGradient
        id="_Linear3"
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(3.12713e-15,-51.0699,51.0699,3.12713e-15,83.7955,71.3951)"
      >
        <stop offset={0} style={{ stopColor: "white", stopOpacity: "0.4" }} />
        <stop
          offset="0.42"
          style={{ stopColor: "white", stopOpacity: "0.4" }}
        />
        <stop
          offset="0.49"
          style={{ stopColor: "rgb(222,241,248)", stopOpacity: "0.4" }}
        />
        <stop
          offset="0.67"
          style={{ stopColor: "rgb(156,215,236)", stopOpacity: "0.4" }}
        />
        <stop
          offset="0.82"
          style={{ stopColor: "rgb(101,199,229)", stopOpacity: "0.4" }}
        />
        <stop
          offset="0.93"
          style={{ stopColor: "rgb(52,190,224)", stopOpacity: "0.4" }}
        />
        <stop
          offset={1}
          style={{ stopColor: "rgb(4,187,223)", stopOpacity: "0.4" }}
        />
      </linearGradient>
      <radialGradient
        id="_Radial4"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(11.7684,0,0,12.1661,83.8915,44.5832)"
      >
        <stop
          offset={0}
          style={{ stopColor: "rgb(9,21,32)", stopOpacity: 1 }}
        />
        <stop
          offset="0.82"
          style={{ stopColor: "rgb(13,25,37)", stopOpacity: 1 }}
        />
        <stop
          offset="0.85"
          style={{ stopColor: "rgb(22,36,49)", stopOpacity: 1 }}
        />
        <stop
          offset="0.89"
          style={{ stopColor: "rgb(35,52,69)", stopOpacity: 1 }}
        />
        <stop
          offset="0.93"
          style={{ stopColor: "rgb(50,75,101)", stopOpacity: 1 }}
        />
        <stop
          offset="0.97"
          style={{ stopColor: "rgb(67,106,147)", stopOpacity: 1 }}
        />
        <stop
          offset={1}
          style={{ stopColor: "rgb(77,136,199)", stopOpacity: 1 }}
        />
      </radialGradient>
      <image
        id="_Image5"
        width="12px"
        height="7px"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAHCAYAAAA8sqwkAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABEUlEQVQYlXXLTUvCYAAH8P/zPFublr0QEwMNl89obYJhL2hjFQmdPPVp/Ah9oG4ReBh07FaxTtIhI8rp0tW29nTrEv7uPwIA6PYoAA5gHcADri4DzMEAgO2cnC8Xljq1zQofh+E+NV2ScecZvifmhV2uV0sHDZs2rRoLxpPyLPpqZtx5F7738S9k/Gg4CoK9ummwlbyClm0wu7qhDoZv2zAcK95qv8L3Jn9Bsk47+ZxaMms6yy1IyMkSKtoqLtwGM8ta4X7wUpfM4+K33npk6PYMRVHO3PahTAH8JDGiKEL4OUU4nWFtUYVV1tj1nV8EcCsBIGmSTG/6ngwCwQhNKSUxpSSmhAhKAJGJkRDiCUD6C/rxWKPt6onkAAAAAElFTkSuQmCC"
      />
      <image
        id="_Image6"
        width="12px"
        height="7px"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAHCAYAAAA8sqwkAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA/UlEQVQYlX3QzypEYRzG8e/vvO+c83bGFKVoFGMxhPI3C6WkRkmT3IGNi5hL4FpslZ2NnSRs3IOM+ePM5BznPT8LKSme9efZfIX/1myNAbNABjxxcertH3A8CksHIjJVnZyQ5/YrSTIwwIP9BUdjF+6pUt/ZXLa1mWlJ0pzLq+ssYQCA/YaVOGp4r3OH22tmdaEevKWezjCj3enS6/VT4PHr0GxVyy48PtnfKu1uLEr3PeclydDUo4CLHBIEzqiueLgXmq2lsguPGuvzWinHNitUPgqhEEMhhqyAbjLk5vbOq+qZ/KhRA+JAxBoTOGtMRWFElUhVS4VqP8/z80/+JGGQvdQb3gAAAABJRU5ErkJggg=="
      />
    </defs>
  </svg>,
  "Nmap",
);
