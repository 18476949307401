import { useGetMetricsQuery } from "~/operations";
import { Metrics, parseMetrics } from "~/utils/arrow";

type UseMetricsParams = {
  entityMrn: string;
  metricMrns: Array<Metrics>;
  skip?: boolean;
};

export function useMetrics({ entityMrn, metricMrns }: UseMetricsParams) {
  const { data, loading, error, refetch } = useGetMetricsQuery({
    variables: {
      entityMrn,
      metricMrns,
    },
    skip: !entityMrn,
  });

  return {
    isLoading: loading,
    error,
    refetch,
    ...parseMetrics(data?.metrics?.entries || []),
  };
}
