import { useSort } from "~/pages/inventory/hooks/useSort";
import {
  CheckScoreFilter,
  CheckScoreOrder,
  CheckScoreOrderField,
  OrderDirection,
  ScoreStateFilter,
  useGetCheckScoresQuery,
} from "~/operations";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";

type UseCheckAffectedAssetsParams = {
  checkMrn: string;
  scope: SpaceOrWorkspaceScope;
};

export function useCheckAffectedAssets({
  checkMrn,
  scope,
}: UseCheckAffectedAssetsParams) {
  const { handleSortClick, orderBy } = useSort<CheckScoreOrder>({
    defaultSort: {
      field: CheckScoreOrderField.Score,
      direction: OrderDirection.Desc,
    },
    validFields: ["ASSET_NAME", "LAST_UPDATED", "SCORE"],
  });

  const filter: CheckScoreFilter = {
    checkMrn,
    queryTerms: [],
    state: ScoreStateFilter.Open,
  };

  const { data, error, loading, fetchMore } = useGetCheckScoresQuery({
    variables: {
      entityMrn: scope.mrn,
      first: 10,
      orderBy,
      filter,
    },
    skip: Boolean(!checkMrn),
  });

  const checkScoresUnion = data?.checkScores;
  const checkScores =
    checkScoresUnion?.__typename === "CheckScoresConnection"
      ? checkScoresUnion
      : undefined;

  const assets = checkScores?.edges?.flatMap(({ node }) => {
    if (!node?.asset) return [];

    const asset = {
      id: node.asset.id,
      mrn: node.asset.mrn,
      score: node.riskScore || 0,
      lastUpdated: node.lastUpdated,
      riskFactors: node.riskFactors,
      title: node.asset.name,
      iconId: node.asset.icon,
      tags: node.tags ? [...node.tags] : [],
      rating: node.rating,
    };

    // Manually add the platform tag
    if (node.platform) {
      asset.tags.push({
        key: "Platform",
        value: node.platform.title,
        __typename: "Tag",
      });
    }

    return [asset];
  });

  return {
    assets,
    orderBy,
    handleSortClick,
    filteredTotalCount: checkScores?.filteredTotalCount || 0,
    fetchMore,
    pageInfo: checkScores?.pageInfo,
    error,
    loading,
  };
}
