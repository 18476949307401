import { ButtonProps, IconButton, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Flex } from "~/components/Flex";

type SelectionRowHeaderProps = {
  selectionIndex: number;
  showRemoveSelectionButton: boolean;
  onRemove: ButtonProps["onClick"];
};

export const SelectionRowHeader = ({
  selectionIndex,
  showRemoveSelectionButton,
  onRemove,
}: SelectionRowHeaderProps) => {
  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Typography fontWeight={700}>
        ASSET SELECTION {selectionIndex + 1}
      </Typography>
      {showRemoveSelectionButton && (
        <IconButton onClick={onRemove}>
          <Close />
        </IconButton>
      )}
    </Flex>
  );
};
