import { alpha, Box, TableCell, Typography, useTheme } from "@mui/material";
import { HighlightItemData } from "@mui/x-charts";
import { PieChart } from "@mui/x-charts/PieChart";
import { Fragment, useEffect, useLayoutEffect, useState } from "react";

type SecurityScoreCellProps = {
  value: number;
  totalChecks: number;
  "data-testid"?: string;
};

export function SecurityScoreCell({ ...props }: SecurityScoreCellProps) {
  let theme = useTheme();
  const percentage = Math.round((props.value / props.totalChecks) * 100);

  return (
    <TableCell
      data-testid={props["data-testid"]}
      width={180}
      sx={{ position: "relative" }}
    >
      <Typography
        sx={{
          position: "absolute",
          top: 28,
          left: 84,
          fontSize: 12,
          fontWeight: 700,
        }}
      >{`${percentage}%`}</Typography>
      <PieChart
        sx={{
          background: "transparent",
        }}
        series={[
          {
            data: [
              {
                id: "A",
                value: percentage,
                color: theme.palette.primary.main,
              },
              {
                id: "B",
                value: 100 - percentage,
                color: alpha("#000000", 0.13),
              },
            ],
            highlightScope: { fade: "global", highlight: "item" },
            cornerRadius: 3,
            cx: "90%",
            cy: 15,
          },
        ]}
        // highlightedItem={{ seriesId: "A", dataIndex: 0 }}
        height={40}
        tooltip={{ trigger: "none" }}
      />
    </TableCell>
  );
}
