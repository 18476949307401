import { Grid, Grid2 } from "@mui/material";
import { SectionHeading } from "~/components/DetailsPage/components";
import { SoftwareVersionsChart } from "./SoftwareVersionsChart";
import { SoftwareVersionsTable } from "./SoftwareVersionsTable";
import { AggregateScoresEdges } from "~/components/FirewatchPage";
import { RiskFactorStats, VersionDistribution } from "~/operations";
import { SoftwareVersionDistribution } from "../SoftwareVersionDistribution/SoftwareVersionDistribution";
import { SpaceOrWorkspaceScope, SpaceScope } from "~/hooks/useScope";

type SoftwareVersionsProps = {
  loading: boolean;
  edges: AggregateScoresEdges;
  riskFactors: (RiskFactorStats | null)[] | null | undefined;
  findingMrn: string;
  versionDistribution: VersionDistribution | null | undefined;
  showVersionDistribution: boolean;
  scope: SpaceOrWorkspaceScope;
  space: SpaceScope;
};

export const SoftwareVersions = ({
  loading,
  edges,
  riskFactors,
  findingMrn,
  versionDistribution,
  showVersionDistribution,
  scope,
  space,
}: SoftwareVersionsProps) => {
  return (
    <Grid2 id="software-versions" size={{ xs: 12 }}>
      <SectionHeading heading="Versions" />
      {showVersionDistribution &&
        (versionDistribution?.distribution || []).length > 1 && (
          <SoftwareVersionDistribution
            versionDistribution={versionDistribution}
            findingMrn={findingMrn}
            scope={scope}
            edges={edges}
            riskFactors={riskFactors}
          />
        )}
      <SoftwareVersionsChart
        scope={scope}
        edges={edges}
        riskFactors={riskFactors}
      />
      <SoftwareVersionsTable
        scope={scope}
        space={space}
        loading={loading}
        edges={edges}
      />
    </Grid2>
  );
};
