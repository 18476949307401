enum DetectedByTool {
  VEX_SOURCE_UNSPECIFIED = "Unspecified",
  VEX_SOURCE_MSDEFENDER = "MS Defender",
  VEX_SOURCE_MONDOO = "Mondoo",
  VEX_SOURCE_FALCON_SPOTLIGHT = "Falcon Spotlight",
  VEX_SOURCE_SENTINEL_ONE = "SentinelOne",
  VEX_SOURCE_CNSPEC = "cnspec",
}

export function mapDetectedByToolName(source: string): string {
  return DetectedByTool[source as keyof typeof DetectedByTool] || source;
}
