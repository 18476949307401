import {
  Grid2,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { Fragment } from "react/jsx-runtime";
import { spacing } from "../lib/SharedVariables";
import { FormatDate } from "~/lib/date";
import { useAssetReport } from "./hooks/useAssetReport";
import { PrintScoreBlock } from "../components";
import { ImpactUpdated } from "~/components/impact/Updated/impact-updated";

export function Header() {
  const {
    loading,
    error,
    functions,
    asset,
    assetRating,
    platformOverviewData,
    assetTags,
    securityScore,
  } = useAssetReport();

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  if (!asset) return <div>Error: No asset found</div>;
  const hasAssetTags = Boolean(assetTags && assetTags.length > 0);

  const summary = functions.generateText.header();

  const mappings = [
    {
      group: "Security findings",
      chart: (
        <ImpactUpdated
          showText={false}
          impact={{
            rating: securityScore.security.rating,
            value: securityScore.security.score || 0,
          }}
          isRiskScore
        />
      ),
      value: securityScore.security.score || 0,
    },
    {
      group: "Vulnerabilities",
      chart: (
        <ImpactUpdated
          showText={false}
          impact={{
            rating: securityScore.vulnerability.rating,
            value: securityScore.vulnerability.score || 0,
          }}
          isRiskScore
        />
      ),
      value: securityScore.vulnerability.score || 0,
    },
    // Can't get risk factors on an asset yet
    //   group: "Risk Factors",
    //   value: 5,
    // },
  ];

  return (
    <Fragment>
      <Grid2 container size="grow" spacing={0}>
        <Grid2 container size={12} columnSpacing={1.875}>
          {/* Do not yet have the data for an icon */}
          {/* <Grid2>
            <RedHatIcon sx={{ fontSize: 35 }} />
          </Grid2> */}
          <Grid2
            container
            size="grow"
            sx={{
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Grid2 size={12}>
              <Typography
                component="h1"
                sx={{
                  fontSize: (theme) => theme.spacing(4),
                  fontWeight: 700,
                  textTransform: "uppercase",
                }}
              >
                {asset.name}
              </Typography>
            </Grid2>
            <Grid2 size={12}>
              <Typography>
                Last updated {FormatDate(asset.updatedAt)}
              </Typography>
            </Grid2>
          </Grid2>
        </Grid2>
        <Grid2 size={12} sx={{ my: spacing[25] }}>
          <Typography variant="body2">{summary}</Typography>
        </Grid2>
        <Grid2 container columnSpacing={spacing[50]} size={12}>
          <Grid2 size={6}>
            <Typography component="h3" sx={{ lineHeight: 2 }}>
              Platform overview
            </Typography>
            <Table
              size="small"
              sx={{
                ".MuiTableRow-root:not(.detail-row):not(.loading-row):not(.empty-row):hover":
                  {
                    backgroundColor: "transparent",
                  },
              }}
            >
              <TableBody>
                {platformOverviewData.map((item) => (
                  <HalfTableRow
                    key={item.id}
                    itemkey={item.key}
                    value={item.value}
                  />
                ))}
              </TableBody>
            </Table>
          </Grid2>
          {hasAssetTags && (
            <Grid2 size={6}>
              <Typography component="h3" sx={{ lineHeight: 2 }}>
                Tags
              </Typography>

              <Table
                size="small"
                sx={{
                  tableLayout: "fixed",
                  ".MuiTableRow-root:not(.detail-row):not(.loading-row):not(.empty-row):hover":
                    {
                      backgroundColor: "transparent",
                    },
                }}
              >
                <TableBody>
                  {assetTags?.map((item) => (
                    <HalfTableRow
                      key={item.key}
                      itemkey={item.key}
                      value={item.value || ""}
                    />
                  ))}
                </TableBody>
              </Table>
            </Grid2>
          )}
        </Grid2>
      </Grid2>
      <Grid2 size="auto">
        <PrintScoreBlock
          {...{
            mappings,
            ratingValues: {
              rating: assetRating.rating,
              value: assetRating.value,
            },
          }}
        />
      </Grid2>
    </Fragment>
  );
}

const HalfTableRow = ({
  itemkey,
  value,
}: {
  itemkey: string;
  value: string;
}) => {
  return (
    <TableRow
      sx={{
        td: {
          p: 0,
          border: "none",
          fontSize: 12,
          fontWeight: 700,
          verticalAlign: "top",
        },
      }}
    >
      <TableCell width="50%" sx={{ wordBreak: "break-word" }}>
        {itemkey}
      </TableCell>
      <TableCell width="50%" sx={{ wordBreak: "break-word" }}>
        {value}
      </TableCell>
    </TableRow>
  );
};
